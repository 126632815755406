import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
// import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import ExportIcon from 'mdi-react/DownloadIcon';

const SubscribersList = ({ subscribersList /* handleDeleteSubscriber */ }) => (
  <Col lg={6}>
    <Card>
      <CardBody>
        <h4>List of email addresses of people who agreed to receive updates
          from Include about new products or special promotions.
        </h4>
        <hr />
        <ul className="subscribers-list">
          {subscribersList.map(item => (
            <li key={item.id}>
              <p><strong>{item.email}</strong>
                {/* <button className="delete-btn" type="button" onClick={() => removeSubscriber(item.id)}>
                  <DeleteForeverIcon />
          </button> */}
              </p>
            </li>
          ))}
        </ul>
        <a
          href={`${process.env.REACT_APP_BASE_URL}/newsletter/export`}
          className="export-btn"
        >Export to CSV <ExportIcon />
        </a>
      </CardBody>
    </Card>
  </Col>
);

SubscribersList.propTypes = {
  subscribersList: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object, PropTypes.number,
  ])).isRequired,
  // handleDeleteSubscriber: PropTypes.func.isRequired,
};


export default SubscribersList;
