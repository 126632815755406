export const SEARCH_ALL_REQUEST = 'SEARCH_ALL_REQUEST';
export const SEARCH_ALL_SUCCESS = 'SEARCH_ALL_SUCCESS';
export const SEARCH_ALL_FAILURE = 'SEARCH_ALL_FAILURE';

export function searchAllRequest() {
  return {
    type: SEARCH_ALL_REQUEST,
  };
}

export function searchAllSuccess(data) {
  return {
    type: SEARCH_ALL_SUCCESS,
    payload: data,
  };
}

export function searchAllFailure(error) {
  return {
    type: SEARCH_ALL_FAILURE,
    payload: error,
  };
}
