export const FETCH_PRODUCT_INFO_REQUEST = 'FETCH_PRODUCT_INFO_REQUEST';
export const FETCH_PRODUCT_INFO_SUCCESS = 'FETCH_PRODUCT_INFO_SUCCESS';
export const FETCH_PRODUCT_INFO_FAILURE = 'FETCH_PRODUCT_INFO_FAILURE';

export const FETCH_DEPLOYED_PRODUCT_INFO_SUCCESS = 'FETCH_DEPLOYED_PRODUCT_INFO_SUCCESS';
export const FETCH_USAGE_SUMMARY_SUCCESS = 'FETCH_USAGE_SUMMARY_SUCCESS';
export const GET_LAST_REPORT_SUCCESS = 'GET_LAST_REPORT_SUCCESS';
export const GET_LAST_REPORT_FAILURE = 'GET_LAST_REPORT_FAILURE';
export const GET_LAST_DISCOVERY_SUCCESS = 'GET_LAST_DISCOVERY_SUCCESS';
export const GET_LAST_DISCOVERY_FAILURE = 'GET_LAST_DISCOVERY_FAILURE';

export const DELETE_TEST_DATA_REQUEST = 'DELETE_TEST_DATA_REQUEST';
export const DELETE_TEST_DATA_SUCCESS = 'DELETE_TEST_DATA_SUCCESS';
export const DELETE_TEST_DATA_FAILURE = 'DELETE_TEST_DATA_FAILURE';
export const SET_CALIBRATION_DATE_REQUEST = 'SET_CALIBRATION_DATE_REQUEST';
export const SET_CALIBRATION_DATE_SUCCESS = 'SET_CALIBRATION_DATE_SUCCESS';
export const SET_CALIBRATION_DATE_FAILURE = 'SET_CALIBRATION_DATE_FAILURE';
export const SET_TYPE_OF_CALCULATION_REQUEST = 'SET_TYPE_OF_CALCULATION_REQUEST';
export const SET_TYPE_OF_CALCULATION_SUCCESS = 'SET_TYPE_OF_CALCULATION_SUCCESS';
export const SET_TYPE_OF_CALCULATION_FAILURE = 'SET_TYPE_OF_CALCULATION_FAILURE';

export const FETCH_FORCE_DISCOVERY_REQUEST = 'FETCH_FORCE_DISCOVERY_REQUEST';
export const FETCH_FORCE_DISCOVERY_SUCCESS = 'FETCH_FORCE_DISCOVERY_SUCCESS ';
export const FETCH_FORCE_DISCOVERY_FAILURE = 'FETCH_FORCE_DISCOVERY_FAILURE';

export function fetchForceDiscoveryRequest() {
  return {
    type: FETCH_FORCE_DISCOVERY_REQUEST,
  };
}

export function fetchForceDiscoveryFailure(error) {
  return {
    type: FETCH_FORCE_DISCOVERY_FAILURE,
    payload: error,
  };
}

export function fetchForceDiscoverySuccess(data) {
  return {
    type: FETCH_FORCE_DISCOVERY_SUCCESS,
    payload: data,
  };
}

export function fetchProductInfoRequest() {
  return {
    type: FETCH_PRODUCT_INFO_REQUEST,
  };
}

export function fetchProductInfoFailure(error) {
  return {
    type: FETCH_PRODUCT_INFO_FAILURE,
    payload: error,
  };
}

export function fetchDeployedProductInfoSuccess(data) {
  return {
    type: FETCH_DEPLOYED_PRODUCT_INFO_SUCCESS,
    payload: data,
  };
}

export function fetchUsageSummarySuccess(data) {
  return {
    type: FETCH_USAGE_SUMMARY_SUCCESS,
    payload: data,
  };
}

export function getLastReportSuccess(data) {
  return {
    type: GET_LAST_REPORT_SUCCESS,
    payload: data,
  };
}

export function getLastReportFailure(error) {
  return {
    type: GET_LAST_REPORT_FAILURE,
    payload: error,
  };
}

export function getLastDiscoverySuccess(data) {
  return {
    type: GET_LAST_DISCOVERY_SUCCESS,
    payload: data,
  };
}

export function getLastDiscoveryFailure(error) {
  return {
    type: GET_LAST_DISCOVERY_FAILURE,
    payload: error,
  };
}

export function deleteTestDataRequest() {
  return {
    type: DELETE_TEST_DATA_REQUEST,
  };
}

export function deleteTestDataSuccess(data) {
  return {
    type: DELETE_TEST_DATA_SUCCESS,
    payload: data,
  };
}

export function deleteTestDataFailure(error) {
  return {
    type: DELETE_TEST_DATA_FAILURE,
    payload: error,
  };
}

export function setLastCalibrationDateRequest() {
  return {
    type: SET_CALIBRATION_DATE_REQUEST,
  };
}

export function setLastCalibrationDateFailure(error) {
  return {
    type: SET_CALIBRATION_DATE_FAILURE,
    payload: error,
  };
}

export function setLastCalibrationDateSuccess(data) {
  return {
    type: SET_CALIBRATION_DATE_SUCCESS,
    payload: data,
  };
}

export function setTypeOfCalculationRequest() {
  return {
    type: SET_TYPE_OF_CALCULATION_REQUEST,
  };
}

export function setTypeOfCalculationFailure(error) {
  return {
    type: SET_TYPE_OF_CALCULATION_FAILURE,
    payload: error,
  };
}

export function setTypeOfCalculationSuccess(data) {
  return {
    type: SET_TYPE_OF_CALCULATION_SUCCESS,
    payload: data,
  };
}
