import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TableHeader from './TableHeaderCustomersList';

function createData(id, name, email, country, company) {
  return {
    id,
    name,
    email,
    country,
    company,
    details: id,
  };
}

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class CustomersList extends PureComponent {
  constructor(props) {
    super(props);
    const { customersList } = this.props;

    this.state = {
      order: 'desc',
      orderBy: 'id',
      data: customersList.map(item => createData(
        item.id,
        item.name,
        item.email,
        item.country.name,
        item.company.name ? item.company.name : '',
        item.id,
      )),
      page: 0,
      rowsPerPage: 10,
      originalRows: customersList.map(item => createData(
        item.id,
        item.name,
        item.email,
        item.country.name,
        item.company.name ? item.company.name : '',
        item.id,
      )),
      filteredRows: customersList.map(item => createData(
        item.id,
        item.name,
        item.email,
        item.country.name,
        item.company.name ? item.company.name : '',
        item.id,
      )),
    };
  }

  componentDidUpdate(prevProps) {
    const { customersList } = this.props;

    if (prevProps.customersList.length !== customersList.length) {
      this.updateProductsList();
    }
  }

  updateProductsList = () => {
    const { customersList } = this.props;
    this.setState({
      data: customersList.map(item => createData(
        item.id,
        item.name,
        item.email,
        item.country.name,
        item.company.name ? item.company.name : '',
        item.id,
      )),
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleFilterChange = ({ target }) => {
    const { originalRows } = this.state;
    let filteredRows = originalRows;

    if (target.value.length > 2) {
      const filterRowsByName = originalRows.filter(row => row.name.toLowerCase().includes(target.value.toLowerCase()));

      filteredRows = filterRowsByName;

      if (filterRowsByName.length === 0) {
        const filterRowsByEmail = originalRows.filter(
          row => row.email.toLowerCase().includes(target.value.toLowerCase()),
        );
        filteredRows = filterRowsByEmail;
      }
      if (filterRowsByName.length === 0) {
        const filterRowsByCountry = originalRows.filter(
          row => row.country.toLowerCase().includes(target.value.toLowerCase()),
        );
        filteredRows = filterRowsByCountry;
      }
      if (filterRowsByName.length === 0) {
        const filterRowsByCompany = originalRows.filter(
          row => row.company.toLowerCase().includes(target.value.toLowerCase()),
        );
        filteredRows = filterRowsByCompany;
      }
    }

    this.setState({ filteredRows });
  };

  render() {
    const {
      data, order, orderBy, rowsPerPage, page, filteredRows,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">List of companies</h5>
              <ButtonToolbar className="products-list__btn-toolbar-top">
                <form className="form" onSubmit={this.handleSubmit}>
                  <div className="form__form-group products-list__search">
                    <input
                      placeholder="Filter by email or name..."
                      onChange={this.handleFilterChange}
                    />
                    <MagnifyIcon />
                  </div>
                </form>
              </ButtonToolbar>
            </div>
            <div className="material-table__wrap">
              <Table className="material-table">
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={filteredRows.length}
                />
                <TableBody>
                  {filteredRows
                    .sort(getSorting(order, orderBy))
                    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                    .map(d => (
                      <TableRow
                        className="material-table__row"
                        role="checkbox"
                        // onClick={event => this.handleClick(event, d.id)}
                        tabIndex={-1}
                        key={d.id}
                      >
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {d.id}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {d.name}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {d.email}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >{d.country}
                        </TableCell>
                        <TableCell className="material-table__cell material-table__cell-right">
                          {d.company}
                        </TableCell>
                        <TableCell className="material-table__cell material-table__cell-right">
                          <Link
                            to={`/customers/${d.details}`}
                            link={`/customers/${d.details}`}
                            id="thisIsLink"
                          >details
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 20, 50, 100]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

CustomersList.propTypes = {
  customersList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ]))).isRequired,
};
