import {
  FETCH_DEPLOYED_PRODUCTS_REQUEST,
  FETCH_DEPLOYED_BENCHES_SUCCESS,
  FETCH_DEPLOYED_WASTE_SUCCESS,
  FETCH_DEPLOYED_AERYS_SUCCESS,
  FETCH_DEPLOYED_PRODUCTS_FAILURE,
  FETCH_MODEL_DATA_REQUEST,
  FETCH_MODEL_DATA_SUCCESS,
  FETCH_MODEL_DATA_FAILURE,
  UPDATE_MODEL_DATA_REQUEST,
  UPDATE_MODEL_DATA_SUCCESS,
  UPDATE_MODEL_DATA_FAILURE,
  FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_REQUEST,
  FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_SUCCESS,
  FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_FAILURE,
  FETCH_ALL_BENCHES_MODELS_REQUEST,
  FETCH_ALL_BENCHES_MODELS_SUCCESS,
  FETCH_ALL_BENCHES_MODELS_FAILURE,
  FETCH_ALL_AERYS_MODELS_REQUEST,
  FETCH_ALL_AERYS_MODELS_SUCCESS,
  FETCH_ALL_AERYS_MODELS_FAILURE,
  FETCH_CONTAINERS_MODEL_DATA_REQUEST,
  FETCH_CONTAINERS_MODEL_DATA_SUCCESS,
  FETCH_CONTAINERS_MODEL_DATA_FAILURE,
  FETCH_ALL_MODULES_REQUEST,
  FETCH_ALL_MODULES_SUCCESS,
  FETCH_ALL_MODULES_FAILURE,
  NO_SEARCH_RESULTS,
  FETCH_ALL_LOGS_REQUEST,
  FETCH_ALL_LOGS_SUCCESS,
  FETCH_ALL_LOGS_FAILURE,
  FETCH_MAINTENANCE_REQUEST,
  FETCH_MAINTENANCE_SUCCESS,
  FETCH_MAINTENANCE_FAILURE,
  FETCH_DEPLOYED_LIST_REQUEST,
  FETCH_DEPLOYED_LIST_FAILURE,
  FETCH_DEPLOYED_LIST_SUCCESS,
} from '../actions/productsActions';

const initialState = {
  error: null,
  loading: false,
  deployedBenches: [],
  deployedWaste: [],
  deployedAerys: [],
  modelInfo: {},
  modelInfoUpdated: {},
  wasteList: [],
  wasteListPagination: {
    perPage: 25,
    total: 50,
    currentPage: 0,
  },
  benchesList: [],
  benchesListPagination: {
    perPage: 25,
    total: 50,
    currentPage: 0,
  },
  containerModelInfo: {},
  modulesList: [],
  searchResultsMessage: '',
  allLogs: [],
  aerysList: [],
  aerysListPagination: {
    perPage: 25,
    total: 50,
    currentPage: 0,
  },
  maintenanceList: [],
  maintenanceListPagination: {
    perPage: 25,
    total: 50,
    currentPage: 0,
  },
  productsList: [],
};

function productsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEPLOYED_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        searchResultsMessage: '',
      };
    case FETCH_DEPLOYED_BENCHES_SUCCESS:
      return {
        ...state,
        deployedBenches: action.payload.data,
        benchesListPagination: {
          perPage: action.payload.per_page,
          total: action.payload.total,
          currentPage: action.payload.current_page,
        },
        loading: false,
      };
    case FETCH_DEPLOYED_WASTE_SUCCESS:
      return {
        ...state,
        deployedWaste: action.payload.data,
        wasteListPagination: {
          perPage: action.payload.per_page,
          total: action.payload.total,
          currentPage: action.payload.current_page,
        },
        loading: false,
      };
    case FETCH_DEPLOYED_AERYS_SUCCESS:
      return {
        ...state,
        deployedAerys: action.payload.data,
        aerysListPagination: {
          perPage: action.payload.per_page,
          total: action.payload.total,
          currentPage: action.payload.current_page,
        },
        loading: false,
      };
    case FETCH_DEPLOYED_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_MODEL_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MODEL_DATA_SUCCESS:
      return {
        ...state,
        modelInfo: action.payload,
        loading: false,
      };
    case FETCH_MODEL_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_MODEL_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MODEL_DATA_SUCCESS:
      return {
        ...state,
        modelInfoUpdated: action.payload,
        loading: false,
      };
    case UPDATE_MODEL_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_SUCCESS:
      return {
        ...state,
        wasteList: action.payload,
        loading: false,
      };
    case FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ALL_BENCHES_MODELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_BENCHES_MODELS_SUCCESS:
      return {
        ...state,
        benchesList: action.payload,
        loading: false,
      };
    case FETCH_ALL_BENCHES_MODELS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ALL_AERYS_MODELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_AERYS_MODELS_SUCCESS:
      return {
        ...state,
        aerysList: action.payload,
        loading: false,
      };
    case FETCH_ALL_AERYS_MODELS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_CONTAINERS_MODEL_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTAINERS_MODEL_DATA_SUCCESS:
      return {
        ...state,
        containerModelInfo: action.payload,
        loading: false,
      };
    case FETCH_CONTAINERS_MODEL_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ALL_MODULES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_MODULES_SUCCESS:
      return {
        ...state,
        modulesList: action.payload,
        loading: false,
      };
    case FETCH_ALL_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ALL_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_LOGS_SUCCESS:
      return {
        ...state,
        allLogs: action.payload,
        loading: false,
      };
    case FETCH_ALL_LOGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_MAINTENANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MAINTENANCE_SUCCESS:
      return {
        ...state,
        maintenanceList: action.payload.data,
        maintenanceListPagination: {
          perPage: action.payload.per_page,
          total: action.payload.total,
          currentPage: action.payload.current_page,
        },
        loading: false,
      };
    case FETCH_MAINTENANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NO_SEARCH_RESULTS:
      return {
        ...state,
        loading: false,
        searchResultsMessage: `Ups! No results found for "${action.payload}".`,
      };
    case FETCH_DEPLOYED_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DEPLOYED_LIST_SUCCESS:
      return {
        ...state,
        productsList: action.payload.map(item => ({ value: item.id, label: item.device_id })),
        loading: false,
      };
    case FETCH_DEPLOYED_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default productsReducer;
