export const FETCH_ALL_CUSTOMERS_DATA_REQUEST = 'FETCH_ALL_CUSTOMERS_DATA_REQUEST';
export const FETCH_ALL_CUSTOMERS_DATA_SUCCESS = 'FETCH_ALL_CUSTOMERS_DATA_SUCCESS';
export const FETCH_ALL_CUSTOMERS_DATA_FAILURE = 'FETCH_ALL_CUSTOMERS_DATA_FAILURE';
export const FETCH_SINGLE_CUSTOMER_DATA_REQUEST = 'FETCH_SINGLE_CUSTOMER_DATA_REQUEST';
export const FETCH_SINGLE_CUSTOMER_DATA_SUCCESS = 'FETCH_SINGLE_CUSTOMER_DATA_SUCCESS';
export const FETCH_SINGLE_CUSTOMER_DATA_FAILURE = 'FETCH_SINGLE_CUSTOMER_DATA_FAILURE';
export const DEACTIVATE_CUSTOMER_REQUEST = 'DEACTIVATE_CUSTOMER_REQUEST';
export const DEACTIVATE_CUSTOMER_SUCCESS = 'DEACTIVATE_CUSTOMER_SUCCESS';
export const DEACTIVATE_CUSTOMER_FAILURE = 'DEACTIVATE_CUSTOMER_FAILURE';
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';
export const SEND_ACTIVATION_LINK_REQUEST = 'SEND_ACTIVATION_LINK_REQUEST';
export const SEND_ACTIVATION_LINK_SUCCESS = 'SEND_ACTIVATION_LINK_SUCCESS';
export const SEND_ACTIVATION_LINK_FAILURE = 'SEND_ACTIVATION_LINK_FAILURE';
export const ASSIGN_PRODUCT_TO_CUSTOMER_REQUEST = 'ASSIGN_PRODUCT_TO_CUSTOMER_REQUEST';
export const ASSIGN_PRODUCT_TO_CUSTOMER_SUCCESS = 'ASSIGN_PRODUCT_TO_CUSTOMER_SUCCESS';
export const ASSIGN_PRODUCT_TO_CUSTOMER_FAILURE = 'ASSIGN_PRODUCT_TO_CUSTOMER_FAILURE';
export const REMOVE_PRODUCT_FROM_CUSTOMER_REQUEST = 'REMOVE_PRODUCT_FROM_CUSTOMER_REQUEST';
export const REMOVE_PRODUCT_FROM_CUSTOMER_SUCCESS = 'REMOVE_PRODUCT_FROM_CUSTOMER_SUCCESS';
export const REMOVE_PRODUCT_FROM_CUSTOMER_FAILURE = 'REMOVE_PRODUCT_FROM_CUSTOMER_FAILURE';
export const UPDATE_2FA_SUCCESS = 'UPDATE_2FA_SUCCESS';
export const UPDATE_2FA_REQUEST = 'UPDATE_2FA_REQUEST';
export const UPDATE_2FA_FAILURE = 'UPDATE_2FA_FAILURE';

export function fetchAllCustomersDataRequest() {
  return {
    type: FETCH_ALL_CUSTOMERS_DATA_REQUEST,
  };
}

export function fetchAllCustomersDataSuccess(data) {
  return {
    type: FETCH_ALL_CUSTOMERS_DATA_SUCCESS,
    payload: data,
  };
}

export function fetchAllCustomersDataFailure(error) {
  return {
    type: FETCH_ALL_CUSTOMERS_DATA_FAILURE,
    payload: error,
  };
}

export function fetchSingleCustomerDataRequest() {
  return {
    type: FETCH_SINGLE_CUSTOMER_DATA_REQUEST,
  };
}

export function fetchSingleCustomerDataSuccess(data) {
  return {
    type: FETCH_SINGLE_CUSTOMER_DATA_SUCCESS,
    payload: data,
  };
}

export function fetchSingleCustomerDataFailure(error) {
  return {
    type: FETCH_SINGLE_CUSTOMER_DATA_FAILURE,
    payload: error,
  };
}

export function deactivateCustomerRequest() {
  return {
    type: DEACTIVATE_CUSTOMER_REQUEST,
  };
}

export function deactivateCustomerSuccess(data) {
  return {
    type: DEACTIVATE_CUSTOMER_SUCCESS,
    payload: data,
  };
}

export function deactivateCustomerFailure(error) {
  return {
    type: DEACTIVATE_CUSTOMER_FAILURE,
    payload: error,
  };
}

export function deleteCustomerRequest() {
  return {
    type: DELETE_CUSTOMER_REQUEST,
  };
}

export function deleteCustomerSuccess(data) {
  return {
    type: DELETE_CUSTOMER_SUCCESS,
    payload: data,
  };
}

export function deleteCustomerFailure(error) {
  return {
    type: DELETE_CUSTOMER_FAILURE,
    payload: error,
  };
}

export function sendActivationLinkRequest() {
  return {
    type: SEND_ACTIVATION_LINK_REQUEST,
  };
}

export function sendActivationLinkSuccess(data) {
  return {
    type: SEND_ACTIVATION_LINK_SUCCESS,
    payload: data,
  };
}

export function sendActivationLinkFailure(error) {
  return {
    type: SEND_ACTIVATION_LINK_FAILURE,
    payload: error,
  };
}

export function assignProductToCustomerRequest() {
  return {
    type: ASSIGN_PRODUCT_TO_CUSTOMER_REQUEST,
  };
}

export function assignProductToCustomerSuccess(data) {
  return {
    type: ASSIGN_PRODUCT_TO_CUSTOMER_SUCCESS,
    payload: data,
  };
}

export function assignProductToCustomerFailure(error) {
  return {
    type: ASSIGN_PRODUCT_TO_CUSTOMER_FAILURE,
    payload: error,
  };
}

export function removeProductFromCustomerRequest() {
  return {
    type: REMOVE_PRODUCT_FROM_CUSTOMER_REQUEST,
  };
}

export function removeProductFromCustomerSuccess(data) {
  return {
    type: REMOVE_PRODUCT_FROM_CUSTOMER_SUCCESS,
    payload: data,
  };
}

export function removeProductFromCustomerFailure(error) {
  return {
    type: REMOVE_PRODUCT_FROM_CUSTOMER_FAILURE,
    payload: error,
  };
}

export function update2FAFailure(error) {
  return {
    type: UPDATE_2FA_FAILURE,
    payload: error,
  };
}

export function update2FARequest(data) {
  return {
    type: UPDATE_2FA_REQUEST,
    payload: data,
  };
}

export function update2FASuccess(data) {
  return {
    type: UPDATE_2FA_SUCCESS,
    payload: data,
  };
}
