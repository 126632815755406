import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
// Load Highcharts modules for export options of each graph
import Exporting from 'highcharts/modules/exporting';

import SensorInfoAggregates from './SensorInfoAggregates';

Exporting(Highcharts);

const SensorInfo = ({ sensorData }) => {
  const categories = [];
  const values = [];
  const color = '#FF5901';
  const interval = sensorData.showDate ? 30 : 10;
  const xAsisTitle = sensorData.showDate ? 'Date and time period' : 'Time period';

  if (sensorData.data.values.length > 0) {
    sensorData.data.values.forEach((item) => {
      if (sensorData.showDate) categories.push(`${item.date} at ${item.time}`);
      else categories.push(item.time);
      values.push(item.value);
    });
  }

  const sensorType = sensorData.name;
  let colorBorders = [];
  let sensorTypeNameFormatted = '';

  switch (sensorType) {
    case 'pm2.5':
      colorBorders = [
        { value: 10, color: '#50F0E6' }, { value: 20, color: '#50CCAA' }, { value: 25, color: '#F0E641' },
        { value: 50, color: '#FF5050' }, { value: 75, color: '#960032' }, { value: 800, color: '#7D2181' },
      ];
      sensorTypeNameFormatted = 'PM2.5';
      break;
    case 'pm10':
      colorBorders = [
        { value: 20, color: '#50F0E6' }, { value: 40, color: '#50CCAA' }, { value: 50, color: '#F0E641' },
        { value: 100, color: '#FF5050' }, { value: 150, color: '#960032' }, { value: 1200, color: '#7D2181' },
      ];
      sensorTypeNameFormatted = 'PM10';
      break;
    case 'NO2 concentration':
      colorBorders = [
        { value: 40, color: '#50F0E6' }, { value: 90, color: '#50CCAA' }, { value: 120, color: '#F0E641' },
        { value: 230, color: '#FF5050' }, { value: 340, color: '#960032' }, { value: 1000, color: '#7D2181' },
      ];
      sensorTypeNameFormatted = 'NO2 concentration';
      break;
    case 'O3 concentration':
      colorBorders = [
        { value: 50, color: '#50F0E6' }, { value: 100, color: '#50CCAA' }, { value: 130, color: '#F0E641' },
        { value: 240, color: '#FF5050' }, { value: 380, color: '#960032' }, { value: 800, color: '#7D2181' },
      ];
      sensorTypeNameFormatted = 'O3 concentration';
      break;
    case 'pm1':
      sensorTypeNameFormatted = 'PM1';
      break;
    case 'wind speed':
      sensorTypeNameFormatted = 'Wind speed';
      break;
    default:
      sensorTypeNameFormatted = sensorType;
      break;
  }

  const chartOptions = {
    chart: {
      type: sensorData.graph_type,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      tickInterval: interval,
      title: {
        text: xAsisTitle,
      },
    },
    series: [
      {
        name: sensorData.name,
        data: values,
        color,
      },
    ],
  };

  const chartOptionsForAirQuality = {
    chart: {
      type: sensorData.graph_type,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      tickInterval: interval,
      title: {
        text: xAsisTitle,
      },
    },
    series: [
      {
        name: sensorTypeNameFormatted,
        data: values,
        zones: colorBorders,
        maxPointWidth: 50,
        color,
      },
    ],
  };

  let chartOptionsDisplay = chartOptions;
  if (sensorData.sensor_group_slug === 'air-quality') chartOptionsDisplay = chartOptionsForAirQuality;

  return (
    <div className="main-chart-wrapper">
      <div className="chart-title">
        {(sensorType !== 'NO2 concentration' && sensorType !== 'O3 concentration')
        && sensorTypeNameFormatted}
        {sensorType === 'NO2 concentration' && <>NO<sub>2</sub> concentration</>}
        {sensorType === 'O3 concentration' && <>O<sub>3</sub> concentration</>}
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptionsDisplay}
        containerProps={{ className: 'chart-wrapper' }}
      />
      <div className="chart-info">
        {!isEmpty(sensorData.data.aggregates) ? (
          <SensorInfoAggregates sensorData={sensorData} />
        ) : (
          <div>
            <p>Not enough data to display aggregates.</p>
          </div>
        )}
      </div>
    </div>
  );
};

SensorInfo.propTypes = {
  sensorData: PropTypes.oneOfType([
    PropTypes.object, PropTypes.array,
  ]).isRequired,
};

export default SensorInfo;
