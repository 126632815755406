export const FETCH_DEPLOYED_PRODUCTS_REQUEST = 'FETCH_DEPLOYED_PRODUCTS_REQUEST';
export const FETCH_DEPLOYED_BENCHES_SUCCESS = 'FETCH_DEPLOYED_BENCHES_SUCCESS';
export const FETCH_DEPLOYED_WASTE_SUCCESS = 'FETCH_DEPLOYED_WASTE_SUCCESS';
export const FETCH_DEPLOYED_AERYS_SUCCESS = 'FETCH_DEPLOYED_AERYS_SUCCESS';
export const FETCH_DEPLOYED_PRODUCTS_FAILURE = 'FETCH_DEPLOYED_PRODUCTS_FAILURE';

export const FETCH_MODEL_DATA_REQUEST = 'FETCH_MODEL_DATA_REQUEST';
export const FETCH_MODEL_DATA_SUCCESS = 'FETCH_MODEL_DATA_SUCCESS';
export const FETCH_MODEL_DATA_FAILURE = 'FETCH_MODEL_DATA_FAILURE';
export const UPDATE_MODEL_DATA_REQUEST = 'UPDATE_MODEL_DATA_REQUEST';
export const UPDATE_MODEL_DATA_SUCCESS = 'UPDATE_MODEL_DATA_SUCCESS';
export const UPDATE_MODEL_DATA_FAILURE = 'UPDATE_MODEL_DATA_FAILURE';

export const FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_REQUEST = 'FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_REQUEST';
export const FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_SUCCESS = 'FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_SUCCESS';
export const FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_FAILURE = 'FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_FAILURE';

export const FETCH_ALL_BENCHES_MODELS_REQUEST = 'FETCH_ALL_BENCHES_MODELS_REQUEST';
export const FETCH_ALL_BENCHES_MODELS_SUCCESS = 'FETCH_ALL_BENCHES_MODELS_SUCCESS';
export const FETCH_ALL_BENCHES_MODELS_FAILURE = 'FETCH_ALL_BENCHES_MODELS_FAILURE';

export const FETCH_CONTAINERS_MODEL_DATA_REQUEST = 'FETCH_CONTAINERS_MODEL_DATA_REQUEST';
export const FETCH_CONTAINERS_MODEL_DATA_SUCCESS = 'FETCH_CONTAINERS_MODEL_DATA_SUCCESS';
export const FETCH_CONTAINERS_MODEL_DATA_FAILURE = 'FETCH_CONTAINERS_MODEL_DATA_FAILURE';

export const FETCH_ALL_AERYS_MODELS_REQUEST = 'FETCH_ALL_AERYS_MODELS_REQUEST';
export const FETCH_ALL_AERYS_MODELS_SUCCESS = 'FETCH_ALL_AERYS_MODELS_SUCCESS';
export const FETCH_ALL_AERYS_MODELS_FAILURE = 'FETCH_ALL_AERYS_MODELS_FAILURE';

export const FETCH_ALL_MODULES_REQUEST = 'FETCH_ALL_MODULES_REQUEST';
export const FETCH_ALL_MODULES_SUCCESS = 'FETCH_ALL_MODULES_SUCCESS';
export const FETCH_ALL_MODULES_FAILURE = 'FETCH_ALL_MODULES_FAILURE';

export const NO_SEARCH_RESULTS = 'NO_SEARCH_RESULTS';

export const FETCH_ALL_LOGS_REQUEST = 'FETCH_ALL_LOGS_REQUEST';
export const FETCH_ALL_LOGS_SUCCESS = 'FETCH_ALL_LOGS_SUCCESS';
export const FETCH_ALL_LOGS_FAILURE = 'FETCH_ALL_LOGS_FAILURE';

export const FETCH_MAINTENANCE_REQUEST = 'FETCH_MAINTENANCE_REQUEST';
export const FETCH_MAINTENANCE_SUCCESS = 'FETCH_MAINTENANCE_SUCCESS';
export const FETCH_MAINTENANCE_FAILURE = 'FETCH_MAINTENANCE_FAILURE';

export const FETCH_DEPLOYED_LIST_REQUEST = 'FETCH_DEPLOYED_LIST_REQUEST';
export const FETCH_DEPLOYED_LIST_SUCCESS = 'FETCH_DEPLOYED_LIST_SUCCESS';
export const FETCH_DEPLOYED_LIST_FAILURE = 'FETCH_DEPLOYED_LIST_FAILURE';


export function fetchMaintenanceRequest() {
  return {
    type: FETCH_MAINTENANCE_REQUEST,
  };
}

export function fetchMaintenanceSuccess(data) {
  return {
    type: FETCH_MAINTENANCE_SUCCESS,
    payload: data,
  };
}

export function fetchMaintenanceFailure(error) {
  return {
    type: FETCH_MAINTENANCE_FAILURE,
    payload: error,
  };
}

export function fetchDeployedProductsRequest() {
  return {
    type: FETCH_DEPLOYED_PRODUCTS_REQUEST,
  };
}

export function fetchDeployedBenchesSuccess(data) {
  return {
    type: FETCH_DEPLOYED_BENCHES_SUCCESS,
    payload: data,
  };
}

export function fetchDeployedWasteSuccess(data) {
  return {
    type: FETCH_DEPLOYED_WASTE_SUCCESS,
    payload: data,
  };
}

export function fetchDeployedAerysSuccess(data) {
  return {
    type: FETCH_DEPLOYED_AERYS_SUCCESS,
    payload: data,
  };
}

export function fetchDeployedProductsFailure(error) {
  return {
    type: FETCH_DEPLOYED_PRODUCTS_FAILURE,
    payload: error,
  };
}

export function fetchModelDataRequest() {
  return {
    type: FETCH_MODEL_DATA_REQUEST,
  };
}

export function fetchModelDataSuccess(model) {
  return {
    type: FETCH_MODEL_DATA_SUCCESS,
    payload: model,
  };
}

export function fetchModelDataFailure(error) {
  return {
    type: FETCH_MODEL_DATA_FAILURE,
    payload: error,
  };
}
export function updateModelDataRequest() {
  return {
    type: UPDATE_MODEL_DATA_REQUEST,
  };
}

export function updateModelDataSuccess(data) {
  return {
    type: UPDATE_MODEL_DATA_SUCCESS,
    payload: data,
  };
}

export function updateModelDataFailure(error) {
  return {
    type: UPDATE_MODEL_DATA_FAILURE,
    payload: error,
  };
}

export function fetchAllWasteContainersModelsDataRequest() {
  return {
    type: FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_REQUEST,
  };
}

export function fetchAllWasteContainersModelsDataSuccess(data) {
  return {
    type: FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_SUCCESS,
    payload: data,
  };
}

export function fetchAllWasteContainersModelsDataFailure(error) {
  return {
    type: FETCH_ALL_WASTE_CONTAINERS_MODELS_DATA_FAILURE,
    payload: error,
  };
}

export function fetchAllBenchesModelsRequest() {
  return {
    type: FETCH_ALL_BENCHES_MODELS_REQUEST,
  };
}

export function fetchAllBenchesModelsSuccess(data) {
  return {
    type: FETCH_ALL_BENCHES_MODELS_SUCCESS,
    payload: data,
  };
}

export function fetchAllBenchesModelsFailure(error) {
  return {
    type: FETCH_ALL_BENCHES_MODELS_FAILURE,
    payload: error,
  };
}

export function fetchAllAerysModelsRequest() {
  return {
    type: FETCH_ALL_AERYS_MODELS_REQUEST,
  };
}

export function fetchAllAerysModelsSuccess(data) {
  return {
    type: FETCH_ALL_AERYS_MODELS_SUCCESS,
    payload: data,
  };
}

export function fetchAllAerysModelsFailure(error) {
  return {
    type: FETCH_ALL_AERYS_MODELS_FAILURE,
    payload: error,
  };
}

export function fetchContainerModelDataRequest() {
  return {
    type: FETCH_CONTAINERS_MODEL_DATA_REQUEST,
  };
}

export function fetchContainerModelDataSuccess(containerType) {
  return {
    type: FETCH_CONTAINERS_MODEL_DATA_SUCCESS,
    payload: containerType,
  };
}

export function fetchContainerModelDataFailure(error) {
  return {
    type: FETCH_CONTAINERS_MODEL_DATA_FAILURE,
    payload: error,
  };
}

export function fetchAllModulesRequest() {
  return {
    type: FETCH_ALL_MODULES_REQUEST,
  };
}

export function fetchAllModulesSuccess(data) {
  return {
    type: FETCH_ALL_MODULES_SUCCESS,
    payload: data,
  };
}

export function fetchAllModulesFailure(error) {
  return {
    type: FETCH_ALL_MODULES_FAILURE,
    payload: error,
  };
}

export function noSearchResults(searchQuery) {
  return {
    type: NO_SEARCH_RESULTS,
    payload: searchQuery,
  };
}

export function fetchAllLogsRequest() {
  return {
    type: FETCH_ALL_LOGS_REQUEST,
  };
}

export function fetchAllLogsSuccess(data) {
  return {
    type: FETCH_ALL_LOGS_SUCCESS,
    payload: data,
  };
}

export function fetchAllLogsFailure(error) {
  return {
    type: FETCH_ALL_LOGS_FAILURE,
    payload: error,
  };
}

export function fetchProductListRequest() {
  return {
    type: FETCH_DEPLOYED_LIST_REQUEST,
  };
}

export function fetchProductListSuccess(data) {
  return {
    type: FETCH_DEPLOYED_LIST_SUCCESS,
    payload: data,
  };
}

export function fetchProductListFailure(error) {
  return {
    type: FETCH_DEPLOYED_LIST_FAILURE,
    payload: error,
  };
}
