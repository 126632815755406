import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ApiResponseMessage from '../Modal/ApiResponseMessage';
import { extendSubscription } from '../../api/manageApi';
import YearsList from '../DropdownList/DropdownList';
import { deSelectedRoutes } from '../../redux/actions/manageAppActions';
import { fetchDeployedProductInfo } from '../../api/productInfoApi';

const Subscription = ({
  errorMessage, selectedProduct, extendSubscriptionResponse,
}) => {
  const dispatch = useDispatch();
  const [modalOpen, toggleModal] = useState(false);
  const [yearsValue, setYearsToExtend] = useState('1');

  const handleYearsClick = ({ target }) => {
    setYearsToExtend(target.value);
  };

  const submitExtendSubscription = () => {
    dispatch(extendSubscription(selectedProduct.id, yearsValue));
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
    dispatch(fetchDeployedProductInfo(selectedProduct.id));
    dispatch(deSelectedRoutes());
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Subscription form</h5>
            <h4 className="subhead">Is subscription valid:
              {selectedProduct.has_valid_subscription ? <CheckIcon color="green" /> : <CloseIcon color="red" /> }
              {selectedProduct.has_valid_subscription ? 'until ' : 'expired on '}
              {moment(selectedProduct.subscription_expires).format('DD.MM.YYYY') || 'Product not added in Solos'}
            </h4>
            <h4 className="subhead">Last edited by&nbsp;
              {selectedProduct.subscription_renewed_by} on&nbsp;
              {moment(selectedProduct.subscription_renewed_at).format('DD.MM.YYYY HH:mm')}
            </h4>
          </div>
          <div className="form form--horizontal">
            <div className="form__form-group">
              <span className="form__form-group-label">
                <span className="news__new-star">*</span>
                Extend subscription
              </span>
              <div className="form__form-group-field">
                <YearsList
                  labelText="Years extending"
                  handleClick={handleYearsClick}
                  currentValue="1"
                />
              </div>
            </div>
          </div>
          <div className="schedule-view heating-container">
            <div className="save-btn">
              <Button
                color="primary"
                type="submit"
                onClick={submitExtendSubscription}
              >Save
              </Button>
            </div>
          </div>
          <ApiResponseMessage
            message={`Extend Subscription - ${extendSubscriptionResponse || errorMessage}`}
            modalOpen={modalOpen}
            closeModal={closeModal}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

Subscription.propTypes = {
  errorMessage: PropTypes.string,
  selectedProduct: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])).isRequired,
  extendSubscriptionResponse: PropTypes.string.isRequired,
};

Subscription.defaultProps = {
  errorMessage: '',
};

export default Subscription;
