import {
  fetchProductInfoRequest,
  fetchProductInfoFailure,
  fetchDeployedProductInfoSuccess,
  fetchUsageSummarySuccess,
  getLastReportSuccess,
  getLastReportFailure,
  getLastDiscoverySuccess,
  getLastDiscoveryFailure,
  deleteTestDataRequest,
  deleteTestDataSuccess,
  deleteTestDataFailure,
  setLastCalibrationDateRequest,
  setLastCalibrationDateSuccess,
  setLastCalibrationDateFailure,
  setTypeOfCalculationRequest,
  setTypeOfCalculationSuccess,
  setTypeOfCalculationFailure,
  fetchForceDiscoverySuccess,
  fetchForceDiscoveryFailure,
  fetchForceDiscoveryRequest,
} from '../redux/actions/productInfoActions';

import authHeader from './authHeader';

// Lets leave these for future use on start/end dates
// const now = moment.utc();
// const yesterday = moment.utc().subtract(1, 'day').format();
// const week = moment.utc().subtract(7, 'day').format();
// const month = moment.utc().subtract(30, 'day').format();

const requestOptions = {
  method: 'GET',
  headers: authHeader(),
};

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
}

export function fetchDeployedProductInfo(id) {
  return (dispatch) => {
    dispatch(fetchProductInfoRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/${id}`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchDeployedProductInfoSuccess(res));
      })
      .catch(error => dispatch(fetchProductInfoFailure(error)));
  };
}

export function fetchUsageSummary(id /* date */) {
  // let startDate;
  // switch (date) {
  //   case 'today':
  //     startDate = now;
  //     break;
  //   case 'yesterday':
  //     startDate = yesterday;
  //     break;
  //   case 'week':
  //     startDate = week;
  //     break;
  //   case 'month':
  //     startDate = month;
  //     break;
  //   default:
  //     break;
  // }

  const options = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      selected_products: [id],
      // optional params, if not send we will receive all data
      // start_date: startDate,
      // end_date: now,
    }),
  };

  return (dispatch) => {
    dispatch(fetchProductInfoRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/distributor/deployed/status-report`, options)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(fetchUsageSummarySuccess(res));
      })
      .catch(error => dispatch(fetchProductInfoFailure(error)));
  };
}

export function getLastReport(id) {
  return (dispatch) => {
    dispatch(fetchProductInfoRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/last-comm/${id}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          dispatch(getLastReportFailure(res.error));
        } else dispatch(getLastReportSuccess(res.success));
      })
      .catch(error => dispatch(fetchProductInfoFailure(error)));
  };
}

export function getLastDiscovery(id) {
  return (dispatch) => {
    dispatch(fetchProductInfoRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/last-discovery/${id}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          dispatch(getLastDiscoveryFailure(res.error));
        } else dispatch(getLastDiscoverySuccess(res.success));
      })
      .catch(error => dispatch(fetchProductInfoFailure(error)));
  };
}

export function deleteTestData(productId) {
  const options = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ deployed_product_id: productId }),
  };

  return (dispatch) => {
    dispatch(deleteTestDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/sensors/delete-test-data`, options)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(deleteTestDataSuccess(res.data));
      })
      .catch(error => dispatch(deleteTestDataFailure(error)));
  };
}

export function forceDiscovery(productId) {
  return (dispatch) => {
    dispatch(fetchForceDiscoveryRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/dev/force-rediscover/${productId}`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchForceDiscoverySuccess(res));
      })
      .catch(error => dispatch(fetchForceDiscoveryFailure(error)));
  };
}

export function setLastCalibrationDate(selectedProduct, date) {
  const postOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      calibration_date: date,
    }),
  };

  return (dispatch) => {
    dispatch(setLastCalibrationDateRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/set-last-calibration-date/${selectedProduct}`, postOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(setLastCalibrationDateSuccess(res.success));
        dispatch(fetchDeployedProductInfo(selectedProduct));
      })
      .catch(error => dispatch(setLastCalibrationDateFailure(error)));
  };
}

export function setTypeOfCalculation(selectedProduct, calcType) {
  const postOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(setTypeOfCalculationRequest());
    // eslint-disable-next-line max-len
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/sensors/set-firmware-calc/${selectedProduct}/${calcType}`, postOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(setTypeOfCalculationSuccess(res.success));
        dispatch(fetchDeployedProductInfo(selectedProduct));
      })
      .catch(error => dispatch(setTypeOfCalculationFailure(error)));
  };
}
