export const FETCH_SUBSCRIBERS_REQUEST = 'FETCH_SUBSCRIBERS_REQUEST';
export const FETCH_SUBSCRIBERS_SUCCESS = 'FETCH_SUBSCRIBERS_SUCCESS';
export const FETCH_SUBSCRIBERS_FAILURE = 'FETCH_SUBSCRIBERS_FAILURE';
export const REMOVE_SUBSCRIBER_REQUEST = 'REMOVE_SUBSCRIBER_REQUEST';
export const REMOVE_SUBSCRIBER_SUCCESS = 'REMOVE_SUBSCRIBER_SUCCESS';
export const REMOVE_SUBSCRIBER_FAILURE = 'REMOVE_SUBSCRIBER_FAILURE';
export const ADD_SUBSCRIBER_REQUEST = 'ADD_SUBSCRIBER_REQUEST';
export const ADD_SUBSCRIBER_SUCCESS = 'ADD_SUBSCRIBER_SUCCESS';
export const ADD_SUBSCRIBER_FAILURE = 'ADD_SUBSCRIBER_FAILURE';


export function fetchSubscribersRequest() {
  return {
    type: FETCH_SUBSCRIBERS_REQUEST,
  };
}

export function fetchSubscribersSuccess(data) {
  return {
    type: FETCH_SUBSCRIBERS_SUCCESS,
    payload: data,
  };
}

export function fetchSubscribersFailure(error) {
  return {
    type: FETCH_SUBSCRIBERS_FAILURE,
    payload: error,
  };
}

export function removeSubscriberRequest() {
  return {
    type: REMOVE_SUBSCRIBER_REQUEST,
  };
}

export function removeSubscriberSuccess(data) {
  return {
    type: REMOVE_SUBSCRIBER_SUCCESS,
    payload: data,
  };
}

export function removeSubscriberFailure(error) {
  return {
    type: REMOVE_SUBSCRIBER_FAILURE,
    payload: error,
  };
}

export function addSubscriberRequest() {
  return {
    type: ADD_SUBSCRIBER_REQUEST,
  };
}

export function addSubscriberSuccess(data) {
  return {
    type: ADD_SUBSCRIBER_SUCCESS,
    payload: data,
  };
}

export function addSubscriberFailure(error) {
  return {
    type: ADD_SUBSCRIBER_FAILURE,
    payload: error,
  };
}
