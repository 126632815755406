import {
  FETCH_ALL_CUSTOMERS_DATA_REQUEST,
  FETCH_ALL_CUSTOMERS_DATA_SUCCESS,
  FETCH_ALL_CUSTOMERS_DATA_FAILURE,
  FETCH_SINGLE_CUSTOMER_DATA_REQUEST,
  FETCH_SINGLE_CUSTOMER_DATA_SUCCESS,
  FETCH_SINGLE_CUSTOMER_DATA_FAILURE,
  DEACTIVATE_CUSTOMER_REQUEST,
  DEACTIVATE_CUSTOMER_SUCCESS,
  DEACTIVATE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  SEND_ACTIVATION_LINK_REQUEST,
  SEND_ACTIVATION_LINK_SUCCESS,
  SEND_ACTIVATION_LINK_FAILURE,
  ASSIGN_PRODUCT_TO_CUSTOMER_REQUEST,
  ASSIGN_PRODUCT_TO_CUSTOMER_SUCCESS,
  ASSIGN_PRODUCT_TO_CUSTOMER_FAILURE,
  REMOVE_PRODUCT_FROM_CUSTOMER_REQUEST,
  REMOVE_PRODUCT_FROM_CUSTOMER_SUCCESS,
  REMOVE_PRODUCT_FROM_CUSTOMER_FAILURE,
  UPDATE_2FA_SUCCESS,
  UPDATE_2FA_REQUEST,
  UPDATE_2FA_FAILURE,
} from '../actions/customersAction';

const initialState = {
  error: null,
  loading: false,
  allCustomersList: [],
  customerData: {},
  deactivateCustomerResponse: '',
  deletedCustomerResponse: '',
  activationLinkResponse: '',
  assignedProductResponse: '',
  removedProductResponse: '',
  disable2FAResponse: '',
};

function allCustomersReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_2FA_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case UPDATE_2FA_SUCCESS:
      return {
        ...state,
        disable2FAResponse: action.payload,
        loading: false,
        errorMessage: '',
      };
    case UPDATE_2FA_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case FETCH_ALL_CUSTOMERS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_CUSTOMERS_DATA_SUCCESS:
      return {
        ...state,
        allCustomersList: action.payload,
        loading: false,
      };
    case FETCH_ALL_CUSTOMERS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_SINGLE_CUSTOMER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SINGLE_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        customerData: action.payload,
        loading: false,
      };
    case FETCH_SINGLE_CUSTOMER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DEACTIVATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DEACTIVATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deactivateCustomerResponse: action.payload,
        loading: false,
      };
    case DEACTIVATE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deleteCustomerResponse: action.payload,
        loading: false,
      };
    case DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SEND_ACTIVATION_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_ACTIVATION_LINK_SUCCESS:
      return {
        ...state,
        activationLinkResponse: action.payload,
        loading: false,
      };
    case SEND_ACTIVATION_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ASSIGN_PRODUCT_TO_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ASSIGN_PRODUCT_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        assignedProductResponse: action.payload,
        loading: false,
      };
    case ASSIGN_PRODUCT_TO_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REMOVE_PRODUCT_FROM_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_PRODUCT_FROM_CUSTOMER_SUCCESS:
      return {
        ...state,
        removedProductResponse: action.payload,
        loading: false,
      };
    case REMOVE_PRODUCT_FROM_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default allCustomersReducer;
