import {
  fetchCompaniesRequest,
  fetchCompaniesSuccess,
  fetchCompaniesFailure,
  verifyCompanyRequest,
  verifyCompanySuccess,
  verifyCompanyFailure,
  fetchAllCompaniesRequest,
  fetchAllCompaniesSuccess,
  fetchAllCompaniesFailure,
  fetchSingleCompanyRequest,
  fetchSingleCompanySuccess,
  fetchSingleCompanyFailure,
  fetchCompanyProductsRequest,
  fetchCompanyProductsSuccess,
  fetchCompanyProductsFailure,
  productCompanyActionsFailure,
  productCompanyActionsRequest,
  productCompanyActionsSuccess,
} from '../redux/actions/companiesAction';

import authHeader from './authHeader';

/* function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
} */

const requestOptions = {
  method: 'GET',
  headers: authHeader(),
};

export function fetchCompanies() {
  return (dispatch) => {
    dispatch(fetchCompaniesRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/companies/unverified_list`, requestOptions)
      // .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchCompaniesSuccess(res));
      })
      .catch(error => dispatch(fetchCompaniesFailure(error)));
  };
}

export function verifyCompany(verificationCode) {
  const updateOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(verifyCompanyRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/verify/${verificationCode}`, updateOptions)
      // .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(verifyCompanySuccess(res));
      })
      .catch(error => dispatch(verifyCompanyFailure(error)));
  };
}

export function fetchAllCompanies() {
  const getOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchAllCompaniesRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/companies`, getOptions)
      // .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchAllCompaniesSuccess(res.data));
      })
      .catch(error => dispatch(fetchAllCompaniesFailure(error)));
  };
}

export function fetchSingleCompany(companyId) {
  const getOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchSingleCompanyRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/companies/show/${companyId}`, getOptions)
      // .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchSingleCompanySuccess(res.data));
      })
      .catch(error => dispatch(fetchSingleCompanyFailure(error)));
  };
}

export function fetchProductsInCompany(companyId) {
  const getOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchCompanyProductsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/companies/deployed-products/${companyId}`, getOptions)
      // .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchCompanyProductsSuccess(res.data));
      })
      .catch(error => dispatch(fetchCompanyProductsFailure(error)));
  };
}

export function removeProductFromCompany(companyId, productId) {
  const getOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(productCompanyActionsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/companies/remove-deployed-product/${companyId}/${productId}`, getOptions)
      // .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(productCompanyActionsSuccess(res.success));
        dispatch(fetchProductsInCompany(companyId));
      })
      .catch(error => dispatch(productCompanyActionsFailure(error)));
  };
}

export function addProductToCompany(companyId, activationCode) {
  const getOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(productCompanyActionsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/companies/add-deployed-product/${companyId}/${activationCode}`, getOptions)
      // .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(productCompanyActionsSuccess(res.success));
        dispatch(fetchProductsInCompany(companyId));
      })
      .catch(error => dispatch(productCompanyActionsFailure(error)));
  };
}
