import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import showResults from '../Form/Show';
import Configurator from './components/ConfiguratorMainForm';

const ConfiguratorForm = ({ t }) => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">{t('forms.configurator_form.title')}</h3>
        <h3 className="page-subhead subhead"> Configuration
        </h3>
      </Col>
    </Row>
    <Configurator onSubmit={showResults} />
  </Container>
);

ConfiguratorForm.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ConfiguratorForm);
