import React, { useState } from 'react';
import { Modal, Button } from 'reactstrap';

import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../shared/img/closeIcon.svg';


const ModalWithDropdownField = ({
  modalOpen, onCloseClick, message, value, confirmAction,
}) => {
  const handleClosing = () => {
    onCloseClick(false);
  };

  const [valueToSend, setValue] = useState(value);

  const onListChange = (e) => {
    setValue(e.target.value);
  };

  const listOptions = ['Backend calculation', 'Firmware calculation'];

  return (
    <Modal isOpen={modalOpen} className="custom__modal-container confirm-modal">
      <div className="custom__modal-header">
        <button
          className="close-btn"
          type="button"
          onClick={() => handleClosing()}
        > <CloseIcon alt="close" />
        </button>
      </div>
      <div className="modal__body">
        <h3 className=" modal__title">Confirm action</h3>
        <p>{message}</p>
        <div>
          Choose type of calculation:
          <select onChange={e => onListChange(e)} defaultValue={valueToSend}>
            {listOptions.map(item => <option value={item} key={item}>{item}</option>)}
          </select>
        </div>
        <div className="save-btn">
          <Button
            padding="2px 20px"
            onClick={() => confirmAction(valueToSend)}
          >Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalWithDropdownField.propTypes = {
  message: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  value: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
};

ModalWithDropdownField.defaultProps = {
  message: 'Are you sure?',
  value: '1',
};

export default ModalWithDropdownField;
