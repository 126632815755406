import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Col,
  ButtonToolbar,
} from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from '../../../shared/helpers/history';
import TableHeaderMaintenance from './TableHeaderMaintenance';

let counter = 0;
function createData(maintenanceId, name, id, country, status) {
  counter += 1;

  return {
    id: counter,
    maintenance_id: maintenanceId,
    type: name,
    details: id,
    country,
    status,
  };
}

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class ActiveMaintenanceList extends PureComponent {
  constructor(props) {
    super(props);
    const { maintenanceList, paginationOptions } = this.props;

    this.state = {
      order: 'desc',
      orderBy: 'id',
      data: maintenanceList.map(item => createData(
        item.maintenance_id,
        item.maintenance_name,
        item.id,
        item.country.name,
        item.status,
      )),
      page: paginationOptions.currentPage - 1,
      total: paginationOptions.total,
      rowsPerPage: paginationOptions.perPage,
      searchTerm: '',
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleClick = (event) => {
    if (event.target.id === 'thisIsLink') history.push(event.target.link);
  };

  handleChangePage = (event, page) => {
    const { handlePagination } = this.props;
    handlePagination(page + 1);
    this.setState({ page });
  };

  handleSearchInput = ({ target }) => {
    this.setState({ searchTerm: target.value });
  }

  handleOnSearch = (event) => {
    const { searchTerm } = this.state;
    const { handleSearchProducts } = this.props;
    handleSearchProducts(event, searchTerm);
  }

  render() {
    const {
      data, order, orderBy, rowsPerPage, page, total, searchTerm,
    } = this.state;
    const { searchResultsMessage, handleGoBack } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          {searchResultsMessage ? (
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">List of products</h5>
              </div>
              <ButtonToolbar>
                <form className="form" onSubmit={this.handleOnSearch}>
                  <div className="form__form-group products-list__search">
                    <input
                      placeholder="Search maintenance"
                      value={searchTerm}
                      onChange={this.handleSearchInput}
                    />
                    <MagnifyIcon className="clickable" onClick={this.handleOnSearch} />
                  </div>
                </form>
              </ButtonToolbar>
              <div><strong>{searchResultsMessage}</strong></div>
              <div>
                <span> Search again or
                  <button className="inbox__email-reply-btn-full" type="button" onClick={handleGoBack}>
                    go back
                  </button> to full list.
                </span>
              </div>
            </CardBody>
          )
            : (
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">List of products</h5>
                </div>
                <ButtonToolbar>
                  <form className="form" onSubmit={this.handleOnSearch}>
                    <div className="form__form-group products-list__search">
                      <input
                        placeholder="Search product"
                        value={searchTerm}
                        onChange={this.handleSearchInput}
                      />
                      <MagnifyIcon className="clickable" onClick={this.handleOnSearch} />
                    </div>
                  </form>
                </ButtonToolbar>
                <div className="material-table__wrap">
                  <Table className="material-table">
                    <TableHeaderMaintenance
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={data.length}
                    />
                    <TableBody>
                      {data
                        .sort(getSorting(order, orderBy))
                        .map(d => (
                          <TableRow
                            className="material-table__row"
                            role="checkbox"
                            onClick={event => this.handleClick(event, d.id)}
                            tabIndex={-1}
                            key={d.id}
                          >
                            <TableCell
                              className="material-table__cell material-table__cell-right"
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              {d.maintenance_id}
                            </TableCell>
                            <TableCell
                              className="material-table__cell material-table__cell-right"
                            >{d.type}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              <Link
                                to={`/maintenance/activities/${d.details}`}
                                link={`/maintenance/activities/${d.details}`}
                                id="thisIsLink"
                              >edit
                              </Link>
                            </TableCell>
                            <TableCell
                              className="material-table__cell material-table__cell-right"
                            >{d.country}
                            </TableCell>
                            <TableCell
                              className="material-table__cell material-table__cell-right"
                            >{d.status}
                            </TableCell>
                          </TableRow>
                        ))}
                      {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  component="div"
                  className="material-table__pagination"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onChangePage={this.handleChangePage}
                  rowsPerPageOptions={[]}
                  dir="ltr"
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                />
              </CardBody>
            )
          }
        </Card>
      </Col>
    );
  }
}

ActiveMaintenanceList.propTypes = {
  maintenanceList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ]))).isRequired,
  paginationOptions: PropTypes.objectOf(PropTypes.node).isRequired,
  handlePagination: PropTypes.func.isRequired,
  handleSearchProducts: PropTypes.func.isRequired,
  searchResultsMessage: PropTypes.string.isRequired,
  handleGoBack: PropTypes.func.isRequired,
};
