/* eslint-disable prefer-destructuring */
import moment from 'moment';
import {
  fetchSensorsRequest,
  fetchSensorsSuccess,
  fetchSensorsFailure,
  fetchSensorsYesterdaySuccess,
  fetchSensorsWeekSuccess,
  fetchSensorsMonthSuccess,
  fetchSensorsCustomSuccess,
} from '../redux/actions/sensorAppActions';
import authHeader from './authHeader';

const now = moment.utc();
const yesterday = moment.utc().subtract(1, 'day').format();
const week = moment.utc().subtract(7, 'day').format();
const month = moment.utc().subtract(30, 'day').format();

function fetchSensors(selectedProduct, moduleId, date, customDateParams) {
  let startDate;
  let endDate = now;

  switch (date) {
    case 'today':
      startDate = now;
      break;
    case 'yesterday':
      startDate = yesterday;
      break;
    case 'week':
      startDate = week;
      break;
    case 'month':
      startDate = month;
      break;
    case 'custom':
      startDate = customDateParams.startDate;
      endDate = customDateParams.endDate;
      break;
    default:
      break;
  }

  const singleOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      module_id: moduleId,
      deployed_product_id: selectedProduct,
      start_date: startDate,
      end_date: endDate,
    }),
  };

  return (dispatch) => {
    dispatch(fetchSensorsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/sensors`, singleOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        if (date === 'yesterday') dispatch(fetchSensorsYesterdaySuccess(res));
        else if (date === 'week') dispatch(fetchSensorsWeekSuccess(res));
        else if (date === 'month') dispatch(fetchSensorsMonthSuccess(res));
        else if (date === 'custom') dispatch(fetchSensorsCustomSuccess(res));
        else dispatch(fetchSensorsSuccess(res));
      })
      .catch(error => dispatch(fetchSensorsFailure(error)));
  };
}

export default fetchSensors;
