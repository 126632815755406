import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  selectDate,
} from '../../redux/actions/sensorAppActions';
import fetchSensors from '../../api/sensorsApi';

const CustomDateSelector = ({ selectedSensors, selectedProducts }) => {
  const dispatch = useDispatch();
  const today = new Date();
  const [startDate, setStartDate] = useState(today.setMonth(today.getMonth() - 3));
  const [endDate, setEndDate] = useState(new Date());

  const params = {
    startDate: moment(startDate).format('DD.MM.YYYY'),
    endDate: moment(endDate).format('DD.MM.YYYY'),
    title: 'custom',
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);

    const paramsToSend = params;
    paramsToSend.startDate = moment(date).format('DD.MM.YYYY');

    dispatch(selectDate(params));

    // get sense data for selected sensors
    // we have list of sensor names only so we need to map them with correct module ID
    selectedSensors.forEach((sensor) => {
      let moduleId;
      // for special case "micromobility" there are 2 modules: 35 and 36
      const secondModuleId = 36;

      switch (sensor) {
        case 'temperature':
          moduleId = 22;
          break;
        case 'humidity':
          moduleId = 22;
          break;
        case 'pressure':
          moduleId = 22;
          break;
        case 'noise':
          moduleId = 11;
          break;
        case 'bench-users':
          moduleId = 9;
          break;
        case 'wireless-charging':
          moduleId = 5;
          break;
        case 'usb-charging':
          moduleId = 4;
          break;
        case 'energy-produced':
          moduleId = 50;
          break;
        case 'micromobility':
          moduleId = 35;
          break;
        case 'particulate-matter':
          moduleId = 37;
          break;
        case 'air-quality':
          moduleId = 45;
          break;
        case 'flowerpot-soil-moisture':
          moduleId = 46;
          break;
        default:
          break;
      }

      const productInfo = selectedProducts[0];
      const isAerysS = productInfo.slug === 'aerys-s-monitor';

      if (moduleId === 35) {
        dispatch(fetchSensors(selectedProducts, moduleId, paramsToSend.title, paramsToSend));
        dispatch(fetchSensors(selectedProducts, secondModuleId, paramsToSend.title, paramsToSend));
      } else if (moduleId === 45 && !isAerysS) {
        dispatch(fetchSensors(selectedProducts, 45, paramsToSend.title, paramsToSend));
        dispatch(fetchSensors(selectedProducts, 44, paramsToSend.title, paramsToSend));
        dispatch(fetchSensors(selectedProducts, 43, paramsToSend.title, paramsToSend));
        dispatch(fetchSensors(selectedProducts, 42, paramsToSend.title, paramsToSend));
        dispatch(fetchSensors(selectedProducts, 51, paramsToSend.title, paramsToSend));
      } else if (moduleId === 45 && isAerysS) {
        const modules = productInfo[0].deployed_modules.filter(item => item.module_id === 51
        || item.module_id === 45 || item.module_id === 44 || item.module_id === 43 || item.module_id === 42);

        modules.forEach((item) => {
          dispatch(fetchSensors(selectedProducts, item.module_id, paramsToSend.title, paramsToSend));
        });
      } else {
        dispatch(fetchSensors(selectedProducts, moduleId, paramsToSend.title, paramsToSend));
      }
    });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    const paramsToSend = params;
    paramsToSend.endDate = moment(date).format('DD.MM.YYYY');

    dispatch(selectDate(paramsToSend));

    // get sense data for selected sensors
    // we have list of sensor names only so we need to map them with correct module ID
    selectedSensors.forEach((sensor) => {
      let moduleId;
      // for special case "micromobility" there are 2 modules: 35 and 36
      const secondModuleId = 36;

      switch (sensor) {
        case 'temperature':
          moduleId = 22;
          break;
        case 'humidity':
          moduleId = 22;
          break;
        case 'pressure':
          moduleId = 22;
          break;
        case 'noise':
          moduleId = 11;
          break;
        case 'air-quality':
          moduleId = 45;
          break;
        case 'bench-users':
          moduleId = 9;
          break;
        case 'wireless-charging':
          moduleId = 5;
          break;
        case 'usb-charging':
          moduleId = 4;
          break;
        case 'energy-produced':
          moduleId = 50;
          break;
        case 'micromobility':
          moduleId = 35;
          break;
        default:
          break;
      }
      const productInfo = selectedProducts[0];
      const isAerysS = productInfo.slug === 'aerys-s-monitor';

      if (moduleId === 35) {
        dispatch(fetchSensors(selectedProducts, moduleId, paramsToSend.title, paramsToSend));
        dispatch(fetchSensors(selectedProducts, secondModuleId, paramsToSend.title, paramsToSend));
      } else if (moduleId === 45 && !isAerysS) {
        dispatch(fetchSensors(selectedProducts, 45, paramsToSend.title, paramsToSend));
        dispatch(fetchSensors(selectedProducts, 44, paramsToSend.title, paramsToSend));
        dispatch(fetchSensors(selectedProducts, 43, paramsToSend.title, paramsToSend));
        dispatch(fetchSensors(selectedProducts, 42, paramsToSend.title, paramsToSend));
        dispatch(fetchSensors(selectedProducts, 51, paramsToSend.title, paramsToSend));
      } else if (moduleId === 45 && isAerysS) {
        const modules = productInfo[0].deployed_modules.filter(item => item.module_id === 51
        || item.module_id === 45 || item.module_id === 44 || item.module_id === 43 || item.module_id === 42);

        modules.forEach((item) => {
          dispatch(fetchSensors(selectedProducts, item.module_id, paramsToSend.title, paramsToSend));
        });
      } else {
        dispatch(fetchSensors(selectedProducts, moduleId, paramsToSend.title, paramsToSend));
      }
    });
  };

  return (
    <div className="custom__date-picker">
      <div className="date-label">
        <span>Start date:</span>
      </div>
      <DatePicker
        selected={startDate}
        onChange={date => handleStartDateChange(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat="dd.MM.yyyy"
      />
      <div className="date-label">
        <span>End date:</span>
      </div>
      <DatePicker
        selected={endDate}
        onChange={date => handleEndDateChange(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        dateFormat="dd.MM.yyyy"
      />
    </div>
  );
};

CustomDateSelector.propTypes = {
  selectedProducts: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedSensors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  products: state.deployedProducts,
});

export default connect(mapStateToProps)(CustomDateSelector);
