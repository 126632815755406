import React, { Component, Fragment } from 'react';
import { connect, Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { hot } from 'react-hot-loader';
import '../../scss/app.scss';
import MyRouter from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import Loading from '../../shared/components/Loading';
import history from '../../shared/helpers/history';

i18next.init(i18nextConfig);

const ThemeComponent = ({ children, themeName }) => {
  const theme = createMuiTheme({
    palette: {
      type: themeName === 'theme-dark' ? 'dark' : 'light',
    },
  });
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect(state => ({ themeName: state.theme.className }))(ThemeComponent);

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <Router history={history}>
          <I18nextProvider i18n={i18next}>
            <ScrollToTop>
              <Fragment>
                {!loaded
                    && (
                      <Loading loading={loading} />
                    )
                  }
                <ConnectedThemeComponent>
                  <div>
                    <MyRouter />
                  </div>
                </ConnectedThemeComponent>
              </Fragment>
            </ScrollToTop>
          </I18nextProvider>
        </Router>
      </Provider>
    );
  }
}

export default hot(module)(App);
