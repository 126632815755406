const validate = (values) => {
  const errors = {};
  if (!values.maintenance_id) {
    errors.maintenance_id = 'Maintenance ID can not be empty';
  }
  if (!values.maintenance_name) {
    errors.maintenance_name = 'Maintenance name can not be empty';
  }
  if (!values.country) {
    errors.country = 'country can not be empty';
  }
  if (!values.products) {
    errors.products = 'Products can not be empty';
  }
  if (!values.contact_person) {
    errors.contact_person = 'Contact person can not be empty';
  }
  if (!values.contact_details) {
    errors.contact_details = 'Contact Deatils can not be empty';
  }
  if (!values.under_warranty) {
    errors.under_warranty = 'Under warranty sensor can not be empty';
  }
  if (!values.maintenance_description) {
    errors.maintenance_description = 'Maintenance description can not be empty';
  }

  return errors;
};

export default validate;
