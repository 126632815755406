/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-constant-condition */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
// Load Highcharts modules for export options of each graph
import Exporting from 'highcharts/modules/exporting';

import SensorInfoAggregates from './AirQualityIndexAggregates';
import SensorAQIModal from '../Modal/SensorAQIIndex';

Exporting(Highcharts);

const SensorInfo = ({ sensorData }) => {
  const [productModalOpen, toggleModal] = useState(false);
  const categories = [];
  const xAsisTitle = sensorData.showDate ? 'Date and time period' : 'Time period';

  if (sensorData.data.values.length > 0) {
    sensorData.data.values.forEach((item) => {
      if (sensorData.showDate) categories.push(`${item.date} at ${item.time}`);
      else categories.push(item.time);
    });
  }

  const valuesToSend = [];
  let colorToDisplay = '';
  let valueForYaxsis = 0;
  sensorData.data.values.forEach((item) => {
    if (item.type === 'pm2.5') {
      if (item.value < 10) {
        colorToDisplay = '#50F0E6';
        valueForYaxsis = 1;
      } else if (item.value > 10 && item.value < 20) {
        colorToDisplay = '#50CCAA';
        valueForYaxsis = 2;
      } else if (item.value > 20 && item.value < 25) {
        colorToDisplay = '#F0E641';
        valueForYaxsis = 3;
      } else if (item.value > 25 && item.value < 50) {
        colorToDisplay = '#FF5050';
        valueForYaxsis = 4;
      } else if (item.value > 50 && item.value < 75) {
        colorToDisplay = '#960032';
        valueForYaxsis = 5;
      } else if (item.value > 75 && item.value < 800) {
        colorToDisplay = '#7D2181';
        valueForYaxsis = 6;
      }
      valuesToSend.push({
        y: valueForYaxsis,
        color: colorToDisplay,
        name: `PM2.5 for ${item.date} at ${item.time}`,
        labelToShow: item.label,
      });
    } else if (item.type === 'pm10') {
      if (item.value < 20) {
        colorToDisplay = '#50F0E6';
        valueForYaxsis = 1;
      } else if (item.value > 20 && item.value < 40) {
        colorToDisplay = '#50CCAA';
        valueForYaxsis = 2;
      } else if (item.value > 40 && item.value < 50) {
        colorToDisplay = '#F0E641';
        valueForYaxsis = 3;
      } else if (item.value > 50 && item.value < 100) {
        colorToDisplay = '#FF5050';
        valueForYaxsis = 4;
      } else if (item.value > 100 && item.value < 150) {
        colorToDisplay = '#960032';
        valueForYaxsis = 5;
      } else if (item.value > 150 && item.value < 1200) {
        colorToDisplay = '#7D2181';
        valueForYaxsis = 6;
      }
      valuesToSend.push({
        y: valueForYaxsis,
        color: colorToDisplay,
        name: `PM10 for ${item.date} at ${item.time}`,
        labelToShow: item.label,
      });
    } else if (item.type === 'no2') {
      if (item.value < 40) {
        colorToDisplay = '#50F0E6';
        valueForYaxsis = 1;
      } else if (item.value > 40 && item.value < 90) {
        colorToDisplay = '#50CCAA';
        valueForYaxsis = 2;
      } else if (item.value > 90 && item.value < 120) {
        colorToDisplay = '#F0E641';
        valueForYaxsis = 3;
      } else if (item.value > 120 && item.value < 230) {
        colorToDisplay = '#FF5050';
        valueForYaxsis = 4;
      } else if (item.value > 230 && item.value < 340) {
        colorToDisplay = '#960032';
        valueForYaxsis = 5;
      } else if (item.value > 340 && item.value < 1000) {
        colorToDisplay = '#7D2181';
        valueForYaxsis = 6;
      }
      valuesToSend.push({
        y: valueForYaxsis,
        color: colorToDisplay,
        name: `N02 for ${item.date} at ${item.time}`,
        labelToShow: item.label,
      });
    } else if (item.type === 'o3') {
      if (item.value < 50) {
        colorToDisplay = '#50F0E6';
        valueForYaxsis = 1;
      } else if (item.value > 50 && item.value < 100) {
        colorToDisplay = '#50CCAA';
        valueForYaxsis = 2;
      } else if (item.value > 100 && item.value < 130) {
        colorToDisplay = '#F0E641';
        valueForYaxsis = 3;
      } else if (item.value > 130 && item.value < 240) {
        colorToDisplay = '#FF5050';
        valueForYaxsis = 4;
      } else if (item.value > 240 && item.value < 380) {
        colorToDisplay = '#960032';
        valueForYaxsis = 5;
      } else if (item.value > 380 && item.value < 800) {
        colorToDisplay = '#7D2181';
        valueForYaxsis = 6;
      }
      valuesToSend.push({
        y: valueForYaxsis,
        color: colorToDisplay,
        name: `O3 for ${item.date} at ${item.time}`,
        labelToShow: item.label,
      });
    }
  });

  if (valuesToSend.length > 1000) {
    for (let i = 0; i < valuesToSend.length; i += 1) {
      valuesToSend.splice(i + 1, 2);
      categories.splice(i + 1, 2);
    }
  } else if (valuesToSend.length > 2000) {
    for (let i = 0; i < valuesToSend.length; i += 1) {
      valuesToSend.splice(i + 1, 3);
      categories.splice(i + 1, 3);
    }
  }

  if (categories.length !== valuesToSend.length) categories.splice(valuesToSend.length, categories.length);

  const chartOptions = {
    chart: {
      type: sensorData.graph_type,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      labels: {
        format: '{value}',
      },
      min: 0,
      max: categories.length - 1,
      tickInterval: 1,
      title: {
        text: xAsisTitle,
      },
    },
    yAxis: {
      title: {
        text: 'Air quality index',
      },
      lineWidth: 1,
      labels: {
        formatter() {
          if (this.value === 0) return 'No index';
          if (this.value === 1) return 'Good';
          if (this.value === 2) return 'Fair';
          if (this.value === 3) return 'Moderate';
          if (this.value === 4) return 'Poor';
          if (this.value === 5) return 'Very Poor';
          return 'Extremely poor';
        },
      },
      tickInterval: 1,
      min: 0,
      max: 6,
    },
    tooltip: {
      pointFormat: '<span style="color:{point.color}">{point.labelToShow}</span>',
    },
    series: [
      {
        name: 'Air Quality Index',
        data: valuesToSend,
        color: '#FF5901',
        maxPointWidth: 50,
      },
    ],
  };

  return (
    <div className="main-chart-wrapper">
      <div className="chart-title">Air Quality Index
        <button
          type="button"
          onClick={() => toggleModal(true)}
          className="product-details"
        >info
        </button>
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ className: 'chart-wrapper' }}
      />
      <div className="chart-info">
        {!isEmpty(sensorData.data.aggregates) ? (
          <SensorInfoAggregates sensorData={sensorData} />
        ) : (
          <div>
            <p>Not enough data to display aggregates.</p>
          </div>
        ) }
      </div>
      <SensorAQIModal
        modalOpen={productModalOpen}
        closeModal={() => toggleModal(false)}
      />
    </div>
  );
};

SensorInfo.propTypes = {
  sensorData: PropTypes.oneOfType([
    PropTypes.object, PropTypes.array,
  ]).isRequired,
};

export default SensorInfo;
