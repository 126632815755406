import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AerysSensors from '../../../DeployedProducts/Aerys';
import AerysInfo from '../../../DeployedProducts/Aerys/Info';
import LogsInfo from '../../../../components/LogsInfo';
import AddAirSensorForm from '../../../../components/ProductDisplayData/components/AddAirSensorForm';
import UpdateAirSensorForm from '../../../../components/ProductDisplayData/components/UpdateAirSensorForm';
import SensorDataContainer from '../../../../components/Sensors/SensorDataContainer';
import ManageDataContainer from '../../../../components/ManageData/ManageDataContainer';
import UpdatePMSensorForm from '../../../../components/ProductDisplayData/components/PMSensorEditForm';

export default () => (
  <Switch>
    <Route exact path="/aerys" component={AerysSensors} />
    <Route exact path="/aerys/:id" component={AerysInfo} />
    <Route exact path="/aerys/logs/:id" render={props => <LogsInfo typeOfProduct="aerys" {...props} />} />
    <Route exact path="/aerys/air-sensors/create" component={AddAirSensorForm} />
    <Route exact path="/aerys/air-sensors/edit/:index" component={UpdateAirSensorForm} />
    <Route exact path="/aerys/pm-gas/edit/:index" component={UpdatePMSensorForm} />
    <Route exact path="/aerys/sensors/:id" component={SensorDataContainer} />
    <Route exact path="/aerys/manage/:id" component={ManageDataContainer} />
  </Switch>
);
