import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SelectUser from './components/SelectUser';
import UserWithPermissions from './components/UserWithPermissions';
import { fetchUserData, fetchAllUsersData } from '../../api/usersApi';

class Permissions extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAllUsersData());
  }

  handleSelectedUser = (id) => {
    const { dispatch, usersList } = this.props;
    const selectedUserInfo = usersList.find(user => user.id === id);
    dispatch(fetchUserData(selectedUserInfo.username, true));
  }

  render() {
    const { t, usersList, otherUserInfo } = this.props;
    const users = usersList.length > 0 ? usersList.map(item => ({ label: item.name, value: item.id })) : [];

    return (
      <Container>
        <Row>
          <Col>
            <h3 className="page-title">{t('permissions.title')}</h3>
          </Col>
        </Row>
        <Row>
          {users.length > 0
            && (<SelectUser t={t} users={users} onUserSelect={id => this.handleSelectedUser(id)} />)}
          {otherUserInfo.id
          && (<UserWithPermissions user={otherUserInfo} key={otherUserInfo.id} />)}
        </Row>
      </Container>
    );
  }
}

Permissions.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  usersList: PropTypes.arrayOf(PropTypes.object).isRequired,
  otherUserInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.array,
  ])).isRequired,
};

const mapStateToProps = state => ({
  usersList: state.user.usersList,
  otherUserInfo: state.user.otherUserInfo,
  rtl: state.rtl,
});

export default connect(mapStateToProps)(withTranslation('common')(Permissions));
