import {
  FETCH_PRODUCT_INFO_REQUEST,
  FETCH_PRODUCT_INFO_FAILURE,
  FETCH_DEPLOYED_PRODUCT_INFO_SUCCESS,
  FETCH_USAGE_SUMMARY_SUCCESS,
  GET_LAST_REPORT_SUCCESS,
  GET_LAST_REPORT_FAILURE,
  GET_LAST_DISCOVERY_SUCCESS,
  GET_LAST_DISCOVERY_FAILURE,
  DELETE_TEST_DATA_SUCCESS,
  SET_CALIBRATION_DATE_FAILURE,
  SET_CALIBRATION_DATE_SUCCESS,
  SET_CALIBRATION_DATE_REQUEST,
  SET_TYPE_OF_CALCULATION_FAILURE,
  SET_TYPE_OF_CALCULATION_SUCCESS,
  SET_TYPE_OF_CALCULATION_REQUEST,
} from '../actions/productInfoActions';

const initialState = {
  errorMessage: '',
  loading: false,
  productDetails: {},
  usageSummary: [],
  lastReport: {},
  lastDiscovery: {},
  errorMessageDiscovery: '',
  errorMessageCommunication: '',
  lastCalibrationDate: '',
  typeOfCalculation: '',
};

function productInfoReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCT_INFO_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case FETCH_DEPLOYED_PRODUCT_INFO_SUCCESS:
      return {
        ...state,
        productDetails: action.payload,
        loading: false,
      };
    case FETCH_USAGE_SUMMARY_SUCCESS:
      return {
        ...state,
        usageSummary: action.payload,
        loading: false,
      };
    case DELETE_TEST_DATA_SUCCESS:
      return {
        ...state,
        usageSummary: action.payload,
        loading: false,
      };
    case GET_LAST_REPORT_SUCCESS:
      return {
        ...state,
        lastReport: action.payload,
        errorMessageCommunication: '',
        loading: false,
      };
    case GET_LAST_REPORT_FAILURE:
      return {
        ...state,
        errorMessageCommunication: action.payload,
        loading: false,
      };
    case GET_LAST_DISCOVERY_SUCCESS:
      return {
        ...state,
        lastDiscovery: action.payload,
        errorMessageDiscovery: '',
        loading: false,
      };
    case GET_LAST_DISCOVERY_FAILURE:
      return {
        ...state,
        errorMessageDiscovery: action.payload,
        loading: false,
      };
    case SET_CALIBRATION_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_CALIBRATION_DATE_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case SET_CALIBRATION_DATE_SUCCESS:
      return {
        ...state,
        lastCalibrationDate: action.payload,
        loading: false,
      };
    case SET_TYPE_OF_CALCULATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_TYPE_OF_CALCULATION_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case SET_TYPE_OF_CALCULATION_SUCCESS:
      return {
        ...state,
        typeOfCalculation: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default productInfoReducer;
