import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ProductDetails from '../../../../components/ProductDisplayData/components/ProductDetails';
import UsageSummary from '../../../../components/ProductDisplayData/components/UsageSummary';
import ReportAndDiscovery from '../../../../components/ProductDisplayData/components/ReportAndDiscovery';
import { UserProps } from '../../../../shared/prop-types/ReducerProps';
import Loading from '../../../../shared/components/Loading';
import {
  fetchDeployedProductInfo, fetchUsageSummary, getLastReport, getLastDiscovery, deleteTestData, forceDiscovery,
} from '../../../../api/productInfoApi';
import { getMaintenanceHistory } from '../../../../api/maintenanceApi';

const wasteInfo = ({
  match, productInfo, user, maintenanceList,
}) => {
  const {
    loading, productDetails, usageSummary, lastReport, lastDiscovery, errorMessageDiscovery, errorMessageCommunication,
  } = productInfo;
  const dispatch = useDispatch();

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    dispatch(fetchDeployedProductInfo(id));
    dispatch(fetchUsageSummary(id));
    dispatch(getLastReport(id));
    dispatch(getLastDiscovery(id));
    dispatch(getMaintenanceHistory(id));
  }, [dispatch]);

  const handleDeleteData = (productId) => {
    dispatch(deleteTestData(productId));
  };

  const typeOfProduct = productDetails.product_type && productDetails.product_type.slug;

  const handleForceDiscovery = (productId) => {
    dispatch(forceDiscovery(productId));
  };

  const maintenanceListData = maintenanceList.map(item => item.maintenance_id);

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="page-title">
            {productDetails.name && `${productDetails.name}: ${productDetails.device_id}` }
          </h2>
        </Col>
        <Col>
          {user.permissions && user.permissions.includes('manage.edit')
          && (
          <Link
            className="btn btn-primary account__btn sensor-btn-display"
            to={`/${typeOfProduct}/manage/${productDetails.id}`}
          >Manage data
          </Link>
          )}
        </Col>
      </Row>
      <Row>
        {loading ? <Loading loading={loading} />
          : (
            <>
              {productDetails.name
              && <ProductDetails data={productDetails} maintenanceListData={maintenanceListData} />
              }
              {usageSummary.length > 0
               && (
               <UsageSummary
                 productDetails={productDetails}
                 handleDeleteData={handleDeleteData}
                 data={usageSummary[0]}
               />
               )}
              <ReportAndDiscovery
                productDetails={productDetails}
                discovery={lastDiscovery}
                report={lastReport}
                errorDiscovery={errorMessageDiscovery}
                errorCommunication={errorMessageCommunication}
                handleForceDiscovery={handleForceDiscovery}
                data={usageSummary[0]}
              />
            </>
          )
        }
      </Row>
    </Container>
  );
};

wasteInfo.propTypes = {
  productInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object,
  ])).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  user: UserProps.isRequired,
  maintenanceList: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object,
  ])).isRequired,
};

const mapStateToProps = state => ({
  productInfo: state.productInfo,
  user: state.user.userInfo,

  maintenanceList: state.maintenance.maintenanceDeployedProduct,
});

export default connect(mapStateToProps)(wasteInfo);
