import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
// import GuyFawkesMaskIcon from 'mdi-react/GuyFawkesMaskIcon';
import TopbarMenuLink from './TopbarMenuLink';
import { UserProps } from '../../../shared/prop-types/ReducerProps';
import { logoutAction } from '../../../api/authApi';

// const imageFolder = `${process.env.REACT_APP_UPLOADS_URL}/avatars/`;

class TopbarProfile extends PureComponent {
  static propTypes = {
    user: UserProps.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  logout = () => {
    const { dispatch } = this.props;
    dispatch(logoutAction());
  }

  render() {
    const {
      user,
    } = this.props;
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <p className="topbar__avatar-name">
            { user.loading ? 'Loading...' : user.userInfo.name}
          </p>
          <DownIcon className="topbar__icon" />
        </button>

        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink
              title="Odjava"
              icon="exit"
              path="/login"
              onClick={this.logout}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(TopbarProfile);
