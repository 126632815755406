import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Customers from '../../../Customers';
import CustomerProfile from '../../../Customers/components/CustomerProfile';

export default () => (
  <Switch>
    <Route exact path="/customers" component={Customers} />
    <Route exact path="/customers/:customerID" component={CustomerProfile} />
  </Switch>
);
