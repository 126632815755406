const validate = (values) => {
  const errors = {};
  if (!values.powerStatus) {
    errors.powerStatus = 'Please input value!';
  }
  if (values.powerStatus && values.powerStatus > 1) {
    errors.powerStatus = 'Please input value 0 or 1';
  }
  return errors;
};

export default validate;
