export const FETCH_ALL_ACCESSORIES_REQUEST = 'FETCH_ALL_ACCESSORIES_REQUEST';
export const FETCH_ALL_ACCESSORIES_SUCCESS = 'FETCH_ALL_ACCESSORIES_SUCCESS';
export const FETCH_ALL_ACCESSORIES_FAILURE = 'FETCH_ALL_ACCESSORIES_FAILURE';
export const FETCH_ALL_COLORS_REQUEST = 'FETCH_ALL_COLORS_REQUEST';
export const FETCH_ALL_COLORS_SUCCESS = 'FETCH_ALL_COLORS_SUCCESS';
export const FETCH_ALL_COLORS_FAILURE = 'FETCH_ALL_COLORS_FAILURE';

export function fetchAllAccessoriesRequest() {
  return {
    type: FETCH_ALL_ACCESSORIES_REQUEST,
  };
}

export function fetchAllAccessoriesSuccess(data) {
  return {
    type: FETCH_ALL_ACCESSORIES_SUCCESS,
    payload: data,
  };
}

export function fetchAllAccessoriesFailure(error) {
  return {
    type: FETCH_ALL_ACCESSORIES_FAILURE,
    payload: error,
  };
}

export function fetchAllColorsRequest() {
  return {
    type: FETCH_ALL_COLORS_REQUEST,
  };
}

export function fetchAllColorsSuccess(data) {
  return {
    type: FETCH_ALL_COLORS_SUCCESS,
    payload: data,
  };
}

export function fetchAllColorsFailure(error) {
  return {
    type: FETCH_ALL_COLORS_FAILURE,
    payload: error,
  };
}
