import React, { useState } from 'react';
import { Modal, Button } from 'reactstrap';

import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../shared/img/closeIcon.svg';


const ModalWithInputText = ({
  modalOpen, onCloseClick, message, date, confirmAction,
}) => {
  const handleClosing = () => {
    onCloseClick(false);
  };

  const [dateValue, setDate] = useState(date);

  const onStartDateChange = (e) => {
    setDate(e.target.value);
  };

  return (
    <Modal isOpen={modalOpen} className="custom__modal-container confirm-modal">
      <div className="custom__modal-header">
        <button
          className="close-btn"
          type="button"
          onClick={() => handleClosing()}
        > <CloseIcon alt="close" />
        </button>
      </div>
      <div className="modal__body">
        <h3 className=" modal__title">Confirm action</h3>
        <p>{message}</p>
        <div>
          <input
            name="startDate"
            type="text"
            onChange={e => onStartDateChange(e)}
          />
        </div>
        <div className="save-btn">
          <Button
            padding="2px 20px"
            onClick={() => confirmAction(dateValue, 'tech')}
          >Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalWithInputText.propTypes = {
  message: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  date: PropTypes.string,
  confirmAction: PropTypes.func.isRequired,
};

ModalWithInputText.defaultProps = {
  message: 'Are you sure?',
  date: '',
};

export default ModalWithInputText;
