export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';
export const VERIFY_COMPANY_REQUEST = 'VERIFY_COMPANY_REQUEST';
export const VERIFY_COMPANY_SUCCESS = 'VERIFY_COMPANY_SUCCESS';
export const VERIFY_COMPANY_FAILURE = 'VERIFY_COMPANY_FAILURE';
export const FETCH_ALL_COMPANIES_REQUEST = 'FETCH_ALL_COMPANIES_REQUEST';
export const FETCH_ALL_COMPANIES_SUCCESS = 'FETCH_ALL_COMPANIES_SUCCESS';
export const FETCH_ALL_COMPANIES_FAILURE = 'FETCH_ALL_COMPANIES_FAILURE';
export const FETCH_SINGLE_COMPANY_REQUEST = 'FETCH_SINGLE_COMPANY_REQUEST';
export const FETCH_SINGLE_COMPANY_SUCCESS = 'FETCH_SINGLE_COMPANY_SUCCESS';
export const FETCH_SINGLE_COMPANY_FAILURE = 'FETCH_SINGLE_COMPANY_FAILURE';
export const FETCH_COMPANY_PRODUCTS_REQUEST = 'FETCH_COMPANY_PRODUCTS_REQUEST';
export const FETCH_COMPANY_PRODUCTS_SUCCESS = 'FETCH_COMPANY_PRODUCTS_SUCCESS';
export const FETCH_COMPANY_PRODUCTS_FAILURE = 'FETCH_COMPANY_PRODUCTS_FAILURE';
export const PRODUCT_COMPANY_ACTIONS_REQUEST = 'PRODUCT_COMPANY_ACTIONS_REQUEST';
export const PRODUCT_COMPANY_ACTIONS_SUCCESS = 'PRODUCT_COMPANY_ACTIONS_SUCCESS';
export const PRODUCT_COMPANY_ACTIONS_FAILURE = 'PRODUCT_COMPANY_ACTIONS_FAILURE';

export function fetchCompaniesRequest() {
  return {
    type: FETCH_COMPANIES_REQUEST,
  };
}

export function fetchCompaniesSuccess(data) {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    payload: data,
  };
}

export function fetchCompaniesFailure(error) {
  return {
    type: FETCH_COMPANIES_FAILURE,
    payload: error,
  };
}

export function verifyCompanyRequest() {
  return {
    type: VERIFY_COMPANY_REQUEST,
  };
}

export function verifyCompanySuccess(data) {
  return {
    type: VERIFY_COMPANY_SUCCESS,
    payload: data,
  };
}

export function verifyCompanyFailure(error) {
  return {
    type: VERIFY_COMPANY_FAILURE,
    payload: error,
  };
}

export function fetchAllCompaniesRequest() {
  return {
    type: FETCH_ALL_COMPANIES_REQUEST,
  };
}

export function fetchAllCompaniesSuccess(data) {
  return {
    type: FETCH_ALL_COMPANIES_SUCCESS,
    payload: data,
  };
}

export function fetchAllCompaniesFailure(error) {
  return {
    type: FETCH_ALL_COMPANIES_FAILURE,
    payload: error,
  };
}

export function fetchSingleCompanyRequest() {
  return {
    type: FETCH_SINGLE_COMPANY_REQUEST,
  };
}

export function fetchSingleCompanySuccess(data) {
  return {
    type: FETCH_SINGLE_COMPANY_SUCCESS,
    payload: data,
  };
}

export function fetchSingleCompanyFailure(error) {
  return {
    type: FETCH_SINGLE_COMPANY_FAILURE,
    payload: error,
  };
}

export function fetchCompanyProductsRequest() {
  return {
    type: FETCH_COMPANY_PRODUCTS_REQUEST,
  };
}

export function fetchCompanyProductsSuccess(data) {
  return {
    type: FETCH_COMPANY_PRODUCTS_SUCCESS,
    payload: data,
  };
}

export function fetchCompanyProductsFailure(error) {
  return {
    type: FETCH_COMPANY_PRODUCTS_FAILURE,
    payload: error,
  };
}

export function productCompanyActionsRequest() {
  return {
    type: PRODUCT_COMPANY_ACTIONS_REQUEST,
  };
}

export function productCompanyActionsSuccess(data) {
  return {
    type: PRODUCT_COMPANY_ACTIONS_SUCCESS,
    payload: data,
  };
}

export function productCompanyActionsFailure(error) {
  return {
    type: PRODUCT_COMPANY_ACTIONS_FAILURE,
    payload: error,
  };
}
