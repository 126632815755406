/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
import { BasicNotification } from '../../shared/components/Notification';
import { updatePowerState } from '../../api/manageApi';
import history from '../../shared/helpers/history';
import validate from './PowerButtonValidator';
import { deSelectedRoutes } from '../../redux/actions/manageAppActions';

let notification = null;

const showNotification = (title, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={message.includes('successfully') ? 'success' : 'danger'}
    />,
    duration: 6,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up ltr-support',
  });
};


class PowerButtonForm extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onPowerStateUpdate: PropTypes.string.isRequired,
    initialValues: PropTypes.objectOf((PropTypes.oneOfType([
      PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.array,
    ]))).isRequired,
    powerButtonCurrentConfig: PropTypes.arrayOf((PropTypes.oneOfType([
      PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.array,
    ]))).isRequired,
    typeOfProduct: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      powerStatus: 0,
      validationErrors: {
        powerStatus: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    const {
      onPowerStateUpdate, initialValues, dispatch, typeOfProduct,
    } = this.props;

    if (prevProps.onPowerStateUpdate !== onPowerStateUpdate) {
      const title = onPowerStateUpdate.includes('successfully') ? 'Power button state updated' : 'Api error';
      const message = `${onPowerStateUpdate}`;
      NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
      setTimeout(() => showNotification(title, message), 10);

      history.push(`/${typeOfProduct}/manage/${initialValues.id}`);
      dispatch(deSelectedRoutes());
    }
  }

  handleSubmit = (e) => {
    const { dispatch, initialValues } = this.props;
    const { validationErrors, powerStatus } = this.state;
    e.preventDefault();

    const valuesToValidate = {
      powerStatus,

    };
    const errors = validate(valuesToValidate);

    if (isEmpty(errors)) {
      const params = {
        id: initialValues.id,
        value: powerStatus,
      };
      dispatch(updatePowerState(params));
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          powerStatus: errors.powerStatus,
        },
      });
    }
  }

  handlePowerState =({ target }) => {
    this.setState({
      powerStatus: target.value,
    });
  }

  render() {
    const { validationErrors } = this.state;
    const { powerButtonCurrentConfig } = this.props;
    const powerBtnValue = powerButtonCurrentConfig.map(
      item => (item.module.config.find(element => element.var === 'M')),
    );

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Power button form</h5>
              <h5>0 - permanently activated / 1 - not permanently activated</h5>
            </div>
            <form className="form form--horizontal" onSubmit={this.handleSubmit}>
              <span className="form__form-group-label" />
              <span className="bold-text">
                {powerBtnValue.length > 0 && powerBtnValue[0].pending_value
                  ? `Pending value:${powerBtnValue[0].pending_value} ` : ''}
                <br />
                Current state:
                {powerBtnValue.length > 0 && powerBtnValue[0].value}
              </span>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Variable M
                </span>
                <div className="form__form-group-field">
                  <input
                    name="powerValue"
                    type="number"
                    onChange={this.handlePowerState}
                    min="0"
                  />
                </div>
                { validationErrors.powerStatus
                  && <span className="form__form-group-error"> {validationErrors.powerStatus} </span>}
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">Send config</Button>
              </ButtonToolbar>
            </form>

          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  onPowerStateUpdate: state.manageApp.onPowerStateUpdate,
  initialValues: state.productInfo.productDetails,
  powerButtonCurrentConfig: state.manageApp.powerButtonCurrentConfig,
});

export default connect(mapStateToProps)(PowerButtonForm);
