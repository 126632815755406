export const FETCH_AIR_SENSOR_TYPES_REQUEST = 'FETCH_AIR_SENSOR_TYPES_REQUEST';
export const FETCH_AIR_SENSOR_TYPES_SUCCESS = 'FETCH_AIR_SENSOR_TYPES_SUCCESS';
export const FETCH_AIR_SENSOR_TYPES_FAILURE = 'FETCH_AIR_SENSOR_TYPES_FAILURE';
export const CREATE_AIR_SENSOR_REQUEST = 'CREATE_AIR_SENSOR_REQUEST';
export const CREATE_AIR_SENSOR_SUCCESS = 'CREATE_AIR_SENSOR_SUCCESS';
export const CREATE_AIR_SENSOR_FAILURE = 'CREATE_AIR_SENSOR_FAILURE';
export const FETCH_SINGLE_SENSOR_REQUEST = 'FETCH_SINGLE_SENSOR_REQUEST';
export const FETCH_SINGLE_SENSOR_SUCCESS = 'FETCH_SINGLE_SENSOR_SUCCESS';
export const FETCH_SINGLE_SENSOR_FAILURE = 'FETCH_SINGLE_SENSOR_FAILURE';
export const UPDATE_AIR_SENSOR_REQUEST = 'UPDATE_AIR_SENSOR_REQUEST';
export const UPDATE_AIR_SENSOR_SUCCESS = 'UPDATE_AIR_SENSOR_SUCCESS';
export const UPDATE_AIR_SENSOR_FAILURE = 'UPDATE_AIR_SENSOR_FAILURE';
export const SELECTED_AIR_SENSOR_BOARD = 'SELECTED_AIR_SENSOR_BOARD';

export function fetchAirSensorTypesRequest() {
  return {
    type: FETCH_AIR_SENSOR_TYPES_REQUEST,
  };
}

export function fetchAirSensorTypesSuccess(sensor) {
  return {
    type: FETCH_AIR_SENSOR_TYPES_SUCCESS,
    payload: sensor,
  };
}

export function fetchAirSensorTypesFailure(error) {
  return {
    type: FETCH_AIR_SENSOR_TYPES_FAILURE,
    payload: error,
  };
}

export function createAirSensorRequest() {
  return {
    type: CREATE_AIR_SENSOR_REQUEST,
  };
}

export function createAirSensorSuccess(data) {
  return {
    type: CREATE_AIR_SENSOR_SUCCESS,
    payload: data,
  };
}

export function createAirSensorFailure(error) {
  return {
    type: CREATE_AIR_SENSOR_FAILURE,
    payload: error,
  };
}

export function fetchSingleAirSensorRequest() {
  return {
    type: FETCH_SINGLE_SENSOR_REQUEST,
  };
}

export function fetchSingleAirSensorSuccess(data) {
  return {
    type: FETCH_SINGLE_SENSOR_SUCCESS,
    payload: data,
  };
}

export function fetchSingleAirSensorFailure(error) {
  return {
    type: FETCH_SINGLE_SENSOR_FAILURE,
    payload: error,
  };
}

export function updateAirSensorRequest() {
  return {
    type: UPDATE_AIR_SENSOR_REQUEST,
  };
}

export function updateAirSensorSuccess(data) {
  return {
    type: UPDATE_AIR_SENSOR_SUCCESS,
    payload: data,
  };
}

export function updateAirSensorFailure(error) {
  return {
    type: UPDATE_AIR_SENSOR_FAILURE,
    payload: error,
  };
}

export function selectSensorBoard(sensor) {
  return {
    type: SELECTED_AIR_SENSOR_BOARD,
    payload: sensor,
  };
}
