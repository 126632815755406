import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Col,
  ButtonToolbar,
} from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import history from '../../../shared/helpers/history';
import TableHeader from './TableHeader';

let counter = 0;
function createData(deviceId, name, id, location, lastReport, customer, company, distributor, subscriptionExpires) {
  counter += 1;

  return {
    id: counter,
    device_id: deviceId,
    type: name,
    details: id,
    location,
    last_report: lastReport ? moment(lastReport).format('YYYY/MM/Do, h:mm a') : 'never',
    customer,
    company,
    distributor,
    subscription_expires: subscriptionExpires ? moment(subscriptionExpires).format('YYYY/MM/Do') : '',
    logs: id,
  };
}

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class ActiveProductsList extends PureComponent {
  constructor(props) {
    super(props);
    const { productsList, paginationOptions } = this.props;

    this.state = {
      order: 'desc',
      orderBy: 'id',
      selected: new Map([]),
      data: productsList.map(item => createData(
        item.device_id,
        item.name,
        item.id,
        item.location,
        item.last_response,
        item.customer.name,
        item.customer.company.name,
        item.distributor.name,
        item.subscription_expires,
        item.id,
      )),
      page: paginationOptions.currentPage - 1,
      total: paginationOptions.total,
      rowsPerPage: paginationOptions.perPage,
      searchTerm: '',
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    if (event.target.id === 'thisIsLink') history.push(event.target.link);
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    const { handlePagination } = this.props;
    handlePagination(page + 1);
    this.setState({ page });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  handleSearchInput = ({ target }) => {
    this.setState({ searchTerm: target.value });
  }

  handleOnSearch = (event) => {
    const { searchTerm } = this.state;
    const { handleSearchProducts } = this.props;
    handleSearchProducts(event, searchTerm);
  }

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page, total, searchTerm,
    } = this.state;
    const { searchResultsMessage, handleGoBack, typeOfProduct } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          {searchResultsMessage ? (
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">List of products</h5>
              </div>
              <ButtonToolbar>
                <form className="form" onSubmit={this.handleOnSearch}>
                  <div className="form__form-group products-list__search">
                    <input
                      placeholder="Search product"
                      value={searchTerm}
                      onChange={this.handleSearchInput}
                    />
                    <MagnifyIcon className="clickable" onClick={this.handleOnSearch} />
                  </div>
                </form>
              </ButtonToolbar>
              <div><strong>{searchResultsMessage}</strong></div>
              <div>
                <span> Search again or
                  <button className="inbox__email-reply-btn-full" type="button" onClick={handleGoBack}>
                    go back
                  </button> to full list.
                </span>
              </div>
            </CardBody>
          )
            : (
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">List of products</h5>
                </div>
                <ButtonToolbar>
                  <form className="form" onSubmit={this.handleOnSearch}>
                    <div className="form__form-group products-list__search">
                      <input
                        placeholder="Search product"
                        value={searchTerm}
                        onChange={this.handleSearchInput}
                      />
                      <MagnifyIcon className="clickable" onClick={this.handleOnSearch} />
                    </div>
                  </form>
                </ButtonToolbar>
                <div className="material-table__wrap">
                  <Table className="material-table">
                    <TableHeader
                      numSelected={[...selected].filter(el => el[1]).length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={data.length}
                    />
                    <TableBody>
                      {data
                        .sort(getSorting(order, orderBy))
                        .map((d) => {
                          const isSelected = this.isSelected(d.id);
                          return (
                            <TableRow
                              className="material-table__row"
                              role="checkbox"
                              onClick={event => this.handleClick(event, d.id)}
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={d.id}
                              selected={isSelected}
                            >
                              <TableCell className="material-table__cell" padding="checkbox">
                                <Checkbox checked={isSelected} className="material-table__checkbox" />
                              </TableCell>
                              <TableCell
                                className="material-table__cell material-table__cell-right"
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                {d.device_id}
                              </TableCell>
                              <TableCell
                                className="material-table__cell material-table__cell-right"
                              >{d.type}
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right">
                                <Link
                                  to={`/${typeOfProduct}/${d.details}`}
                                  link={`/${typeOfProduct}/${d.details}`}
                                  id="thisIsLink"
                                >details
                                </Link>
                              </TableCell>
                              <TableCell
                                className="material-table__cell material-table__cell-right"
                              >{d.location}
                              </TableCell>
                              <TableCell
                                className="material-table__cell material-table__cell-right"
                              >{d.last_report}
                              </TableCell>
                              <TableCell
                                className="material-table__cell material-table__cell-right"
                              >{d.company ? d.company : d.customer}
                              </TableCell>
                              <TableCell
                                className="material-table__cell material-table__cell-right"
                              >{d.distributor}
                              </TableCell>
                              <TableCell
                                className="material-table__cell material-table__cell-right"
                              >{d.subscription_expires}
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right">
                                <Link
                                  to={`/${typeOfProduct}/logs/${d.details}`}
                                  link={`/${typeOfProduct}/logs/${d.details}`}
                                  id="thisIsLink"
                                >all logs
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  component="div"
                  className="material-table__pagination"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onChangePage={this.handleChangePage}
                  rowsPerPageOptions={[]}
                  dir="ltr"
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                />
              </CardBody>
            )
          }
        </Card>
      </Col>
    );
  }
}

ActiveProductsList.propTypes = {
  productsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ]))).isRequired,
  typeOfProduct: PropTypes.string.isRequired,
  paginationOptions: PropTypes.objectOf(PropTypes.node).isRequired,
  handlePagination: PropTypes.func.isRequired,
  handleSearchProducts: PropTypes.func.isRequired,
  searchResultsMessage: PropTypes.string.isRequired,
  handleGoBack: PropTypes.func.isRequired,
};
