import React from 'react';
import { Modal, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../shared/img/closeIcon.svg';

const ApiResponseMessage = ({
  message,
  error,
  modalOpen,
  closeModal,
}) => (
  <Modal isOpen={modalOpen} className="custom__modal-container">
    <div className="custom__modal-header">
      <button
        className="close-btn"
        type="button"
        onClick={closeModal}
      > <CloseIcon alt="close" />
      </button>
    </div>
    <div className="modal__body">
      <h3 className=" modal__title">{error ? 'Something went wrong.' : 'Success!'}</h3>
      <p>{error || message}</p>
    </div>
    <div className="modal__btn">
      <Button
        color="primary"
        type="submit"
        onClick={closeModal}
      >Ok
      </Button>
    </div>
  </Modal>
);

ApiResponseMessage.propTypes = {
  message: PropTypes.string,
  error: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

ApiResponseMessage.defaultProps = {
  message: 'Changes were successfully saved.',
  error: '',
};

export default ApiResponseMessage;
