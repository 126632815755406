import {
  SELECT_MANAGE_APP_ROUTE,
  DESELECT_MANAGE_ROUTES,
  UPDATE_GEO_DATA_REQUEST,
  UPDATE_GEO_DATA_SUCCESS,
  UPDATE_GEO_DATA_FAILURE,
  FETCH_CURRENT_CONFIG_REQUEST,
  FETCH_CURRENT_CONFIG_FAILURE,
  FETCH_WIFI_CONFIG_SUCCESS,
  FETCH_LED_CONFIG_SUCCESS,
  UPDATE_WIFI_SCHEDULE,
  FETCH_POWER_BUTTON_CONFIG_SUCCESS,
  REQUEST_GEO_LOCATING_REQUEST,
  REQUEST_GEO_LOCATING_FAILURE,
  UPDATE_POWER_STATE_REQUEST,
  UPDATE_POWER_STATE_SUCCESS,
  UPDATE_POWER_STATE_FAILURE,
  SET_NEW_CONFIG_REQUEST,
  SET_NEW_WIFI_CONFIG_SUCCESS,
  SET_NEW_TERRA_CONFIG_SUCCESS,
  SET_NEW_CONFIG_FAILURE,
  UPDATE_LED_VALUE_FOR_SPRING,
  UPDATE_LED_VALUE_FOR_SUMMER,
  UPDATE_LED_VALUE_FOR_FALL,
  UPDATE_LED_VALUE_FOR_WINTER,
  SET_NEW_LED_CONFIG_SUCCESS,
  FETCH_HEATING_CONFIG_SUCCESS,
  SET_NEW_HEATING_CONFIG_SUCCESS,
  UPDATE_HEATING_SCHEDULE,
  UPDATE_TEMPERATURE,
  EXTEND_SUBSCRIPTION_REQUEST,
  EXTEND_SUBSCRIPTION_SUCCESS,
  EXTEND_SUBSCRIPTION_FAILURE,
  UPDATE_TERRA_SCHEDULE,
  FETCH_TERRA_CONFIG_SUCCESS,
  INITIAL_TERRA_CONFIG,
  INITIAL_WIFI_SCHEDULE,
  INITIAL_HEATING_CONFIG,
} from '../actions/manageAppActions';

const initialState = {
  selectedRoute: '',
  onGeoDataUpdate: '',
  wifiCurrentConfig: [],
  heatingCurrentConfig: [],
  ledCurrentConfig: [],
  errorMessage: '',
  powerButtonCurrentConfig: [],
  onPowerStateUpdate: '',
  onNewConfigUpdate: '',
  onLedConfigUpdate: '',
  extendSubscriptionResponse: '',
  terraCurrentConfig: [],
};

function manageAppReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_MANAGE_APP_ROUTE:
      return {
        ...state,
        selectedRoute: action.payload,
      };
    case DESELECT_MANAGE_ROUTES:
      return {
        ...state,
        selectedRoute: '',
        extendSubscriptionResponse: '',
      };
    case UPDATE_GEO_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        onGeoDataUpdate: '',
      };
    case UPDATE_GEO_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        onGeoDataUpdate: '',
      };
    case UPDATE_GEO_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onGeoDataUpdate: action.payload,
      };
    case FETCH_CURRENT_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        wifiCurrentConfig: [],
        heatingCurrentConfig: [],
        terraCurrentConfig: [],
      };
    case FETCH_WIFI_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        wifiCurrentConfig: action.payload,
      };
    case FETCH_HEATING_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        heatingCurrentConfig: action.payload,
      };
    case FETCH_TERRA_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        terraCurrentConfig: action.payload,
      };
    case FETCH_LED_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        ledCurrentConfig: action.payload,
      };
    case FETCH_CURRENT_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        wifiCurrentConfig: [],
        heatingCurrentConfig: [],
        terraCurrentConfig: [],
      };
    case FETCH_POWER_BUTTON_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        powerButtonCurrentConfig: action.payload,
      };
    case REQUEST_GEO_LOCATING_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REQUEST_GEO_LOCATING_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case UPDATE_POWER_STATE_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        onPowerStateUpdate: '',
      };
    case UPDATE_POWER_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onPowerStateUpdate: action.payload,
      };
    case UPDATE_POWER_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        onPowerStateUpdate: '',
      };
    case UPDATE_WIFI_SCHEDULE: {
      const wifiConfig = state.wifiCurrentConfig;
      const wifiStartTime = wifiConfig[0].module.config[0];
      const wifiEndTime = wifiConfig[0].module.config[1];

      if (action.payload.variable === 'B') { // variable 'B' start time
        if (action.payload.type === 'hours') wifiStartTime.value = wifiStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') wifiStartTime.value = wifiStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // variable 'E' end time
        if (action.payload.type === 'hours') wifiEndTime.value = wifiEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') wifiEndTime.value = wifiEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        wifiCurrentConfig: wifiConfig,
      };
    }
    case UPDATE_HEATING_SCHEDULE: {
      const heatingConfig = state.heatingCurrentConfig;
      const heatingStartTime = heatingConfig[0].module.config.find(element => element.var === 'B');
      const heatingEndTime = heatingConfig[0].module.config.find(element => element.var === 'E');

      if (action.payload.variable === 'B') { // variable 'B' start time
        if (action.payload.type === 'hours') heatingStartTime.value = heatingStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') heatingStartTime.value = heatingStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // variable 'E' end time
        if (action.payload.type === 'hours') heatingEndTime.value = heatingEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') heatingEndTime.value = heatingEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        heatingCurrentConfig: heatingConfig,
      };
    }
    case UPDATE_TEMPERATURE: {
      const heatingConfig = state.heatingCurrentConfig;
      const heatingTemperature = heatingConfig[0].module.config.find(element => element.var === 'V');

      if (action.payload) heatingTemperature.value = action.payload;

      return {
        ...state,
        loading: false,
        errorMessage: '',
        heatingCurrentConfig: heatingConfig,
      };
    }
    case UPDATE_TERRA_SCHEDULE: {
      const terraConfig = state.terraCurrentConfig;
      const terraStartTime = terraConfig[0].module.config.find(element => element.var === 'B');
      const terraEndTime = terraConfig[0].module.config.find(element => element.var === 'E');

      if (action.payload.variable === 'B') { // variable 'B' start time
        if (action.payload.type === 'hours') terraStartTime.value = terraStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') { terraStartTime.value = terraStartTime.value.replace(/..$/, action.payload.value); }
      } else if (action.payload.variable === 'E') { // variable 'E' end time
        if (action.payload.type === 'hours') terraEndTime.value = terraEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') terraEndTime.value = terraEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        terraCurrentConfig: terraConfig,
      };
    }
    case INITIAL_TERRA_CONFIG: {
      const terraConfig = state.terraCurrentConfig;
      const terraStartTime = terraConfig[0].module.config.find(element => element.var === 'B');
      const terraEndTime = terraConfig[0].module.config.find(element => element.var === 'E');

      terraStartTime.value = action.payload.start;
      terraEndTime.value = action.payload.end;

      return {
        ...state,
        loading: false,
        errorMessage: '',
        terraCurrentConfig: terraConfig,
      };
    }
    case INITIAL_WIFI_SCHEDULE: {
      const wifiConfig = state.wifiCurrentConfig;
      const wifiStartTime = wifiConfig[0].module.config.find(element => element.var === 'B');
      const wifiEndTime = wifiConfig[0].module.config.find(element => element.var === 'E');
      const wifiSSID = wifiConfig[0].module.config.find(element => element.var === 'S');

      wifiStartTime.value = action.payload.start;
      wifiEndTime.value = action.payload.end;
      wifiSSID.value = action.payload.ssid;

      return {
        ...state,
        loading: false,
        errorMessage: '',
        wifiCurrentConfig: wifiConfig,
      };
    }
    case INITIAL_HEATING_CONFIG: {
      const heatingConfig = state.heatingCurrentConfig;
      const heatingStartTime = heatingConfig[0].module.config.find(element => element.var === 'B');
      const heatingEndTime = heatingConfig[0].module.config.find(element => element.var === 'E');
      const heatingTemperature = heatingConfig[0].module.config.find(element => element.var === 'V');

      heatingStartTime.value = action.payload.start;
      heatingEndTime.value = action.payload.end;
      heatingTemperature.value = action.payload.temperature;

      return {
        ...state,
        loading: false,
        errorMessage: '',
        heatingCurrentConfig: heatingConfig,
      };
    }
    case SET_NEW_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        onNewConfigUpdate: '',
      };
    case SET_NEW_WIFI_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onNewConfigUpdate: action.payload,
      };
    case SET_NEW_TERRA_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onNewConfigUpdate: action.payload,
      };
    case SET_NEW_HEATING_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onNewConfigUpdate: action.payload,
      };
    case SET_NEW_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        onNewConfigUpdate: '',
      };
    case UPDATE_LED_VALUE_FOR_SPRING: {
      const newConfig = state.ledCurrentConfig;
      const ledStartTime = newConfig[0].seasons[3].config[0];
      const ledEndTime = newConfig[0].seasons[3].config[1];

      if (action.payload.variable === 'B') { // start time
        if (action.payload.type === 'hours') ledStartTime.value = ledStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledStartTime.value = ledStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // end time
        if (action.payload.type === 'hours') ledEndTime.value = ledEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledEndTime.value = ledEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        ledCurrentConfig: newConfig,
      };
    }
    case UPDATE_LED_VALUE_FOR_SUMMER: {
      const newConfig = state.ledCurrentConfig;
      const ledStartTime = newConfig[0].seasons[0].config[0];
      const ledEndTime = newConfig[0].seasons[0].config[1];

      if (action.payload.variable === 'B') { // start time
        if (action.payload.type === 'hours') ledStartTime.value = ledStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledStartTime.value = ledStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // end time
        if (action.payload.type === 'hours') ledEndTime.value = ledEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledEndTime.value = ledEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        ledCurrentConfig: newConfig,
      };
    }
    case UPDATE_LED_VALUE_FOR_FALL: {
      const newConfig = state.ledCurrentConfig;
      const ledStartTime = newConfig[0].seasons[1].config[0];
      const ledEndTime = newConfig[0].seasons[1].config[1];

      if (action.payload.variable === 'B') { // start time
        if (action.payload.type === 'hours') ledStartTime.value = ledStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledStartTime.value = ledStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // end time
        if (action.payload.type === 'hours') ledEndTime.value = ledEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledEndTime.value = ledEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        ledCurrentConfig: newConfig,
      };
    }
    case UPDATE_LED_VALUE_FOR_WINTER: {
      const newConfig = state.ledCurrentConfig;
      const ledStartTime = newConfig[0].seasons[2].config[0];
      const ledEndTime = newConfig[0].seasons[2].config[1];

      if (action.payload.variable === 'B') { // start time
        if (action.payload.type === 'hours') ledStartTime.value = ledStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledStartTime.value = ledStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // end time
        if (action.payload.type === 'hours') ledEndTime.value = ledEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledEndTime.value = ledEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        ledCurrentConfig: newConfig,
      };
    }
    case SET_NEW_LED_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onLedConfigUpdate: action.payload,
      };
    case EXTEND_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        extendSubscriptionResponse: '',
      };
    case EXTEND_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        extendSubscriptionResponse: action.payload,
      };
    case EXTEND_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        extendSubscriptionResponse: '',
      };
    default:
      return state;
  }
}

export default manageAppReducer;
