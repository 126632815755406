import {
  createMaintenanceRequest,
  createMaintenanceSuccess,
  createMaintenanceFailure,
  getMaintenanceHistoryRequest,
  getMaintenanceHistorySuccess,
  getMaintenanceHistoryFailure,
  getMaintenanceByIdRequest,
  getMaintenanceByIdSuccess,
  getMaintenanceByIdFailure,
  closeMaintenanceByIdRequest,
  closeMaintenanceByIdSuccess,
  closeMaintenanceByIdFailure,
  updatePartsMaintenanceByIdRequest,
  updatePartsMaintenanceByIdSuccess,
  updatePartsMaintenanceByIdFailure,
  updateNameOrDateMaintenanceByIdRequest,
  updateNameOrDateMaintenanceByIdSuccess,
  updateNameOrDateMaintenanceByIdFailure,
  getAllSparePartsRequest,
  getAllSparePartsSuccess,
  getAllSparePartsFailure,
  createNewSparePartRequest,
  createNewSparePartSuccess,
  createNewSparePartFailure,
  deleteSparePartByIdRequest,
  deleteSparePartByIdSuccess,
  deleteSparePartByIdFailure,
  getSparePartByProductRequest,
  getSparePartByProductSuccess,
  getSparePartByProductFailure,
} from '../redux/actions/maintenanceAction';

import authHeader from './authHeader';
import history from '../shared/helpers/history';


export default function createMaintenance(params) {
  const postOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      maintenance_id: params.maintenance_id,
      maintenance_name: params.maintenance_name,
      country_id: params.country,
      products: params.products,
      contact_person: params.contact_person,
      contact_details: params.contact_details,
      under_warranty: params.under_warranty,
      description: params.maintenance_description,
    }),
  };

  return (dispatch) => {
    dispatch(createMaintenanceRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/maintenance`, postOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(createMaintenanceSuccess(res.data));
        history.push('/maintenance');
      })
      .catch(error => dispatch(createMaintenanceFailure(error)));
  };
}

export function getMaintenanceHistory(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(getMaintenanceHistoryRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/maintenance/deployed-product/${id}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(getMaintenanceHistorySuccess(res));
      })
      .catch(error => dispatch(getMaintenanceHistoryFailure(error)));
  };
}

export function getMaintenanceById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(getMaintenanceByIdRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/maintenance/${id}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(getMaintenanceByIdSuccess(res));
      })
      .catch(error => dispatch(getMaintenanceByIdFailure(error)));
  };
}

export function closeMaintenanceById(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(closeMaintenanceByIdRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/maintenance/close/${id}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(closeMaintenanceByIdSuccess(res));
        history.push('/maintenance');
      })
      .catch(error => dispatch(closeMaintenanceByIdFailure(error)));
  };
}

export function updatePartsMaintenanceById(maintenanceId, deployedProductId, changedParts) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      parts_changed: changedParts,
    }),
  };

  return (dispatch) => {
    dispatch(updatePartsMaintenanceByIdRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/maintenance/changed-parts/${maintenanceId}/${deployedProductId}`,
      requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(updatePartsMaintenanceByIdSuccess(res));
        window.location.reload();
      })
      .catch(error => dispatch(updatePartsMaintenanceByIdFailure(error)));
  };
}

export function updateNameOrDateMaintenanceById(id, value, type) {
  let body = {};
  if (type === 'tech') {
    body = JSON.stringify({
      technician_name: value,
    });
  } else {
    body = JSON.stringify({
      maintenance_date: value,
    });
  }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body,
  };

  return (dispatch) => {
    dispatch(updateNameOrDateMaintenanceByIdRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/maintenance/${id}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(updateNameOrDateMaintenanceByIdSuccess(res));
        dispatch(getMaintenanceById(id));
      })
      .catch(error => dispatch(updateNameOrDateMaintenanceByIdFailure(error)));
  };
}

export function getAllSpareParts() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(getAllSparePartsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/spare-parts`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(getAllSparePartsSuccess(res));
      })
      .catch(error => dispatch(getAllSparePartsFailure(error)));
  };
}

export function createSparePart(params) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      pantheon_id: params.pantheon_id,
      name: params.spare_part_name,
      product_types: params.product_types,
    }),
  };

  return (dispatch) => {
    dispatch(createNewSparePartRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/spare-parts`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(createNewSparePartSuccess(res));

        history.push('/maintenance/spare-parts');
      })
      .catch(error => dispatch(createNewSparePartFailure(error)));
  };
}

export function deleteSparePart(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(deleteSparePartByIdRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/spare-parts/${id}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(deleteSparePartByIdSuccess(res));

        history.push('/maintenance/spare-parts');
      })
      .catch(error => dispatch(deleteSparePartByIdFailure(error)));
  };
}

export function getSparePartByProductId(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(getSparePartByProductRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/spare-parts/${id}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(getSparePartByProductSuccess(res));
      })
      .catch(error => dispatch(getSparePartByProductFailure(error)));
  };
}
