import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../../Layout';
import NotFound404 from '../../../DefaultPage/404';
import HomePage from '../../../Homepage';
import Configurator from '../../../Configurator';
import Permissions from '../../../EditPermissions';
import BenchesRoutes from './BenchesRoutes';
import CustomersRoutes from './CustomersRoutes';
import AerysRoutes from './AerysRoutes';
import WasteRoutes from './WasteRoutes';
import CompanyVerification from '../../../../components/CompanyVerification';
import Subscribers from '../../../Subscribers';
import Notifications from '../../../Notifications';
import CompaniesRoutes from './CompaniesRoutes';
import MaintenanceRoutes from './MaintenanceRoutes';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/home" component={HomePage} />
        <Route exact path="/configuration" component={Configurator} />
        <Route path="/customers" component={CustomersRoutes} />
        <Route exact path="/users-permissions" component={Permissions} />
        <Route path="/companies" component={CompaniesRoutes} />
        <Route exact path="/company-verification" component={CompanyVerification} />
        <Route path="/benches" component={BenchesRoutes} />
        <Route path="/aerys" component={AerysRoutes} />
        <Route path="/waste-containers" component={WasteRoutes} />
        <Route exact path="/subscribers" component={Subscribers} />
        <Route exact path="/create-notification" component={Notifications} />
        <Route exact path="/permissions" component={Permissions} />
        <Route path="/maintenance" component={MaintenanceRoutes} />
        <Route component={NotFound404} />
      </Switch>
    </div>
  </div>
);
