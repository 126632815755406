import PropTypes from 'prop-types';

const {
  string, bool, shape, func, number,
} = PropTypes;

export const CustomizerProps = shape({
  squaredCorners: bool,
  withBoxShadow: bool,
  topNavigation: bool,
});

export const SidebarProps = shape({
  show: PropTypes.bool,
  collapse: PropTypes.bool,
});

export const ThemeProps = shape({
  className: string,
});

export const RTLProps = shape({
  direction: string,
});

export const UserProps = shape({
  loading: bool,
  userInfo: shape({
    id: number,
    name: string,
    username: string,
    picture: string,
  }),
});

export const BenchProps = shape({
  loading: bool,
  modelInfo: shape({
    id: number,
    name: string,
    slug: string,
  }),
});

export const ContainerProps = shape({
  loading: bool,
  containerModelInfo: shape({
    id: number,
    name: string,
    slug: string,
  }),
});

export const AuthOProps = shape({
  isAuthenticated: bool,
  loading: bool,
  user: shape({
    name: string,
    picture: string,
  }),
  logout: func,
});
