const validate = (values) => {
  const errors = {};
  if (values.correction_factor && values.correction_factor < 0) {
    errors.correction_factor = 'Correction factor can not be less than 0 ';
  }

  return errors;
};

export default validate;
