import React, { useState } from 'react';
import { Input } from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectDate } from '../../redux/actions/sensorAppActions';
import CustomDateSelector from './CustomDateSelector';

const now = moment().format('DD.MM.YYYY');
const yesterday = moment().subtract(1, 'day').format('DD.MM.YYYY');
const week = moment().subtract(7, 'day').format('DD.MM.YYYY');
const month = moment().subtract(30, 'day').format('DD.MM.YYYY');

const TimePeriodSelector = ({ selectedTimePeriod, selectedProducts, selectedSensors }) => {
  const dispatch = useDispatch();

  const [customOptionIsVisible, showCustomDatePicker] = useState(false);


  const handleChange = (startDate, endDate, title) => {
    const params = {
      startDate,
      endDate,
      title,
    };

    if (title === 'custom') showCustomDatePicker(true);
    else {
      showCustomDatePicker(false);
      dispatch(selectDate(params));
    }
  };

  return (
    <div className="time__period-selector">
      <div className="date-display">
        {selectedTimePeriod.startDate !== selectedTimePeriod.endDate
          ? <span>{selectedTimePeriod.startDate} - {selectedTimePeriod.endDate}</span>
          : <span>{selectedTimePeriod.startDate}</span>}
      </div>
      <div className="radio-input">
        <Input
          type="radio"
          name="timeSelector"
          id="today"
          defaultChecked
          value={now}
          onChange={() => handleChange(now, now, 'today')}
        />
        <label className="label-radio" htmlFor="today">Today</label>
        <Input
          type="radio"
          name="timeSelector"
          id="yesterday"
          value={yesterday}
          onChange={() => handleChange(yesterday, yesterday, 'yesterday')}
        />
        <label className="label-radio" htmlFor="yesterday">Yesterday</label>
        <Input
          type="radio"
          name="timeSelector"
          id="week"
          value={week}
          onChange={() => handleChange(week, now, 'week')}
        />
        <label className="label-radio" htmlFor="week">Last 7 days</label>
        <Input
          type="radio"
          name="timeSelector"
          id="month"
          value={month}
          onChange={() => handleChange(month, now, 'month')}
        />
        <label className="label-radio" htmlFor="month">Last 30 days</label>
        <Input
          type="radio"
          name="timeSelector"
          id="custom"
          className={`${selectedProducts.length > 1 && selectedSensors.includes('air-quality') ? 'disabled' : ''}`}
          value={month}
          disabled={selectedProducts.length > 1 && selectedSensors.includes('air-quality')}
          onChange={() => handleChange('', '', 'custom')}
        />
        <label className="label-radio" htmlFor="custom">Custom</label>
      </div>
      {customOptionIsVisible && (
        <CustomDateSelector
          selectedSensors={selectedSensors}
          selectedProducts={selectedProducts}
        />
      )}
    </div>
  );
};

TimePeriodSelector.propTypes = {
  selectedTimePeriod: PropTypes.objectOf(PropTypes.string).isRequired,
  selectedProducts: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedSensors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TimePeriodSelector;
