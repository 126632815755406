import {
  FETCH_SUBSCRIBERS_REQUEST,
  FETCH_SUBSCRIBERS_SUCCESS,
  FETCH_SUBSCRIBERS_FAILURE,
  REMOVE_SUBSCRIBER_REQUEST,
  REMOVE_SUBSCRIBER_SUCCESS,
  REMOVE_SUBSCRIBER_FAILURE,
  ADD_SUBSCRIBER_REQUEST,
  ADD_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_FAILURE,
} from '../actions/subscribersAction';

const initialState = {
  error: null,
  loading: false,
  subscribersList: [],
  removeSubscriberResponse: '',
  addSubscriberResponse: '',
};

function subscribersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBSCRIBERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        subscribersList: action.payload,
        loading: false,
      };
    case FETCH_SUBSCRIBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REMOVE_SUBSCRIBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        removeSubscriberResponse: action.payload,
        loading: false,
      };
    case REMOVE_SUBSCRIBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_SUBSCRIBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        addSubscriberResponse: action.payload,
        loading: false,
      };
    case ADD_SUBSCRIBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default subscribersReducer;
