import React, { useState } from 'react';
import {
  Col, Card, CardBody, Button,
} from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import ConfirmModal from '../../Modal/ConfirmModal';

const UsageSummary = ({ data, handleDeleteData, productDetails }) => {
  const [openModal, toggleModal] = useState(false);

  const deleteData = () => {
    toggleModal(false);
    handleDeleteData(data.deployed_product_id);
  };

  return (
    <>
      <Col lg={6}>
        <Card>
          <CardBody>
            <h3>Usage summary:</h3>
            <p><strong>Battery Status:</strong>{data.battery_status ? `${data.battery_status}%` : 'No data'}</p>
            {data.sensor_info.map(info => (
              <p key={info.name}>
                <strong>{info.name}:</strong> {info.data.aggregates.sum} {info.unit !== 'chargings' && info.unit}
              </p>
            ))}
            <hr />
            <p><strong>Last report:</strong> {moment(data.last_report).format('DD.MM.YYYY   HH:mm')}</p>
            {!productDetails.is_permanently_active
            && (
            <div className="delete-data">
              <Button color="primary" className="icon profile__btn" onClick={() => toggleModal(true)}>
                Delete test data
              </Button>
            </div>
            )}
          </CardBody>
        </Card>
      </Col>
      <ConfirmModal
        modalOpen={openModal}
        onCloseClick={toggleModal}
        message={`Are you sure you want to delete test
         data for product with serial number ${productDetails.device_id}?`}
        handleConfirm={deleteData}
      />
    </>
  );
};

UsageSummary.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object, PropTypes.number,
  ])).isRequired,
  handleDeleteData: PropTypes.func.isRequired,
  productDetails: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object, PropTypes.number,
  ])).isRequired,
};


export default UsageSummary;
