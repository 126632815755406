
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import CustomersListTable from './components/CustomersListTable';
import { fetchSingleCompany } from '../../api/companiesApi';


const CustomersListContainer = ({ companyInfo }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchSingleCompany(id));
  }, [dispatch, id]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Customers list for {companyInfo.name}</h3>
        </Col>
      </Row>
      <Row>
        {companyInfo.customers
          && (
          <CustomersListTable
            allCustomersList={companyInfo.customers}
          />
          )}
      </Row>
    </Container>
  );
};


CustomersListContainer.propTypes = {
  companyInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
};

const mapStateToProps = state => ({
  companyInfo: state.companies.companyInfo,
});


export default connect((mapStateToProps))(CustomersListContainer);
