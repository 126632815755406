import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CustomerProfileCard from './CustomerProfileCard';
import {
  fetchSingleCustomerData,
  removeProductFromCustomer,
  assignProductToCustomer,
  sendActivationLink,
  deleteCustomer,
  deactivateCustomer,
  update2FA,
} from '../../../api/customersApi';
import { UserProps } from '../../../shared/prop-types/ReducerProps';

class CustomerProfile extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        customerID: PropTypes.node,
      }).isRequired,
    }).isRequired,
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(fetchSingleCustomerData(match.params.customerID));
  }

  removeProductFromCustomer = (activationCode, customerId) => {
    const { dispatch } = this.props;
    dispatch(removeProductFromCustomer(activationCode, customerId));
  }

  assignProductToCustomer = (activationCode, customerId) => {
    const { dispatch } = this.props;
    dispatch(assignProductToCustomer(activationCode, customerId));
  }

  sendActivationLink = (customerId) => {
    const { dispatch } = this.props;
    dispatch(sendActivationLink(customerId));
  }

  deleteCustomer = (customerId) => {
    const { dispatch } = this.props;
    dispatch(deleteCustomer(customerId));
  }

  deactivateCustomer = (customerId) => {
    const { dispatch } = this.props;
    dispatch(deactivateCustomer(customerId));
  }

  disable2FA = (customerId) => {
    const { dispatch } = this.props;
    dispatch(update2FA(customerId));
  }

  render() {
    const { customerInfo, user } = this.props;

    return (
      <Container>
        <div className="profile">
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Row>
                {customerInfo.id
                && (
                <CustomerProfileCard
                  customerInfo={customerInfo}
                  showMailButton
                  handleDeleteCustomer={this.deleteCustomer}
                  handleDeactivateCustomer={this.deactivateCustomer}
                  handleSendActivationLink={this.sendActivationLink}
                  handleAddProduct={this.assignProductToCustomer}
                  handleRemoveProduct={this.removeProductFromCustomer}
                  handleDisable2FA={this.disable2FA}
                  user={user}
                />
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  customerInfo: state.customers.customerData,
  user: state.user.userInfo,
});

CustomerProfile.propTypes = {
  customerInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  user: UserProps.isRequired,
};

export default connect(mapStateToProps)(CustomerProfile);
