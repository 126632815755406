/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LogsTable from './LogsTable';
import { fetchAllLogs } from '../../api/productsApi';
import Loading from '../../shared/components/Loading';
import { UserProps } from '../../shared/prop-types/ReducerProps';

const ProductLogs = ({
  match, allLogs, loading, user, typeOfProduct,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const { params } = match;
    const { id } = params;
    dispatch(fetchAllLogs(id));
  }, [dispatch]);

  const handlePeriodClick = (startDate, endDate) => {
    const { params } = match;
    const { id } = params;
    dispatch(fetchAllLogs(id, startDate, endDate));
  };

  const { params } = match;
  const { id } = params;


  return (
    <Container className="dashboard">
      <Row>
        <Col md={6}>
          <h3 className="page-title">All communication logs </h3>
        </Col>
        <Col md={6}>
          <Link
            className="btn btn-primary account__btn sensor-btn-display"
            to={`/${typeOfProduct}/sensors/${id}`}
          >Sensor data
          </Link>
          {user.permissions && user.permissions.includes('manage.edit')
          && (
          <Link
            className="btn btn-primary account__btn sensor-btn-display"
            to={`/${typeOfProduct}/manage/${id}`}
          >Manage data
          </Link>
          )}
          <Link
            className="btn btn-primary account__btn sensor-btn-display"
            to={`/${typeOfProduct}/${id}`}
          >Details
          </Link>
        </Col>
      </Row>
      <Row>
        {loading ? (
          <Loading loading={loading} />
        )
          : allLogs.length > 0
            ? <LogsTable allLogs={allLogs} handlePeriodClick={handlePeriodClick} />
            : <p>There are no logs for selected product</p>
        }
      </Row>
    </Container>
  );
};


ProductLogs.propTypes = {
  allLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  user: UserProps.isRequired,
  typeOfProduct: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  allLogs: state.products.allLogs,
  loading: state.products.loading,
  user: state.user.userInfo,
});


export default connect(mapStateToProps)(ProductLogs);
