import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../../shared/components/Loading';
import { fetchSubscribers, removeSubscriber } from '../../api/subscribersApi';
import SubscribersList from './SubscribersList';

class Subscribers extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchSubscribers());
  }

  deleteSubscriber = (subscriberId) => {
    const { dispatch } = this.props;
    dispatch(removeSubscriber(subscriberId));
  }

  render() {
    const { subscribersList, loading } = this.props;

    return (
      <Container>
        <Row>
          <Col>
            <h3 className="page-title">List of subscribers</h3>
          </Col>
        </Row>
        <Row>
          {loading ? (
            <Loading loading={loading} />
          )
            : subscribersList.length > 0 && (
            <SubscribersList
              key={subscribersList.length}
              subscribersList={subscribersList}
              handleDeleteSubscriber={this.deleteSubscriber}
            />
            )
          }
        </Row>
      </Container>
    );
  }
}

Subscribers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  subscribersList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  subscribersList: state.subscribers.subscribersList,
  loading: state.products.loading,
});

export default connect(mapStateToProps)(Subscribers);
