import {
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAILURE,
} from '../actions/rolesAction';

const initialState = {
  error: null,
  loading: false,
  departmentsList: [],
};

function rolesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEPARTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departmentsList: action.payload,
        loading: false,
      };
    case GET_DEPARTMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default rolesReducer;
