import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';

class VerifiedCompany extends PureComponent {
  render() {
    const {
      companyVerificationSuccess, error, returnHandler,
    } = this.props;

    return (
      <Container>
        {error ? (
          <div>
            <h1>Error: {error}</h1>
            <button
              className="return-button"
              type="button"
              onClick={returnHandler}
            >Povratak na listu
            </button>
          </div>
        ) : (
          <div>
            <Row className="table-body">
              <Col md={1}>
                <p>{companyVerificationSuccess.id}</p>
              </Col>
              <Col md={2}>
                <p>{companyVerificationSuccess.administrator && companyVerificationSuccess.administrator.name}</p>
              </Col>
              <Col md={2}>
                <p>{companyVerificationSuccess.name}</p>
              </Col>
              <Col md={2}>
                <p>{companyVerificationSuccess.address}</p>
              </Col>
              <Col md={2}>
                <p>{companyVerificationSuccess.vat}</p>
              </Col>
              <Col md={1}>
                <p>{companyVerificationSuccess.city}</p>
              </Col>
              <Col md={2}>
                <p>{companyVerificationSuccess.country && companyVerificationSuccess.country.name}</p>
              </Col>
            </Row>
            <Row>
              <h1 className="verification-text">Tvrtka {companyVerificationSuccess.name} je uspješno verificirana!</h1>
            </Row>
            <button
              className="return-button"
              type="button"
              onClick={returnHandler}
            >Povratak na listu
            </button>
          </div>
        )}
      </Container>
    );
  }
}

VerifiedCompany.propTypes = {
  companyVerificationSuccess: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool,
  ])).isRequired,
  error: PropTypes.string.isRequired,
  returnHandler: PropTypes.func.isRequired,
};

export default VerifiedCompany;
