import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
// import renderCheckBoxField from '../form/CheckBox';
import validate from './validator';

import { loginAction, logoutAction } from '../../../api/authApi';
// import renderCheckBoxField from '../form/CheckBox';

class LogInForm extends PureComponent {
  static propTypes = {
    errorMessage: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    dispatchLogin: PropTypes.func.isRequired,
    dispatchLogout: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      dispatchLogout,
    } = this.props;

    // reset login status
    dispatchLogout();

    this.state = {
      showPassword: false,
      username: '',
      password: '',
      validationErrors: {
        username: '',
        password: '',
      },
      isChecked: false,
    };

    this.showPassword = this.showPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
  }

  componentDidMount() {
    if (localStorage.checkbox && localStorage.username) {
      this.setState({
        isChecked: true,
        username: localStorage.username,
        password: localStorage.password,
      });
    }
  }

  onChangeCheckbox = (event) => {
    this.setState({
      isChecked: event.target.checked,
    });
  }

  handleLoginSubmit = (event) => {
    event.preventDefault();

    const { dispatchLogin } = this.props;
    const {
      username, password, isChecked, validationErrors,
    } = this.state;
    const valuesToValidate = {
      username,
      password,
    };
    const errors = validate(valuesToValidate);
    if (isEmpty(errors)) {
      if (isChecked && username) {
        localStorage.username = username;
        localStorage.password = password;
        localStorage.checkbox = isChecked;
      }
      dispatchLogin(username, password);
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          username: errors.username,
          password: errors.password,
        },
      });
    }
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { validationErrors } = this.state;

    // clear validation error when we start typing
    this.setState({
      [name]: value,
      validationErrors: {
        ...validationErrors,
        [name]: '',
      },
    });
  }

  render() {
    const {
      form,
      errorMessage,
    } = this.props;
    const {
      username, password, showPassword, validationErrors, // isChecked,
    } = this.state;
    return (
      <form className="form login-form" onSubmit={this.handleLoginSubmit}>
        <Alert
          color="danger"
          isOpen={!!errorMessage}
        >
          {errorMessage}
        </Alert>
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
              {<Field
                name="username"
                component="input"
                type="text"
                placeholder="Username"
                value={username}
                onChange={this.handleChange}
                autoComplete="username"
              />}
              { validationErrors.username
              && <span className="form__form-group-error"> {validationErrors.username} </span>}
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
              <Field
                name="password"
                component="input"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={this.handleChange}
                autoComplete="password"
              />
              { validationErrors.password
              && <span className="form__form-group-error"> {validationErrors.password} </span>}
            </div>
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>
            <div className="account__forgot-password">
              <a href="mailto:prijava-kvara@include.eu">Forgot your password?</a>
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group form__form-group-field">
            {/* TODO:<Field
              name="isChecked"
              component={renderCheckBoxField}
              label="Remebmer me"
              value={isChecked}
            /> */}
          </div>
        </div>
        <div className="account__btns">
          <Button className="account__btn btn btn-primary">
            Login
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  errorMessage: state.authentication.errorMessage,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  dispatchLogin: loginAction,
  dispatchLogout: logoutAction,
}, dispatch);

const LogInFormComponent = reduxForm({
  form: 'loginForm',
})(LogInForm);

export default connect(mapStateToProps, mapDispatchToProps)(LogInFormComponent);
