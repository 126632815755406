import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MaintenanceList from '../../../DeployedProducts/Maintenance';
import MaintenanceAddForm from '../../../DeployedProducts/Maintenance/MaintenanceAddForm';
import MaintenanceActivities from '../../../DeployedProducts/Maintenance/MaintenanceActivities';
import AllSpareParts from '../../../DeployedProducts/Maintenance/AllSpareParts';
import MaintenanceSparePartAddForm from '../../../DeployedProducts/Maintenance/MaintenanceSparePartAddForm';


export default () => (
  <Switch>
    <Route exact path="/maintenance" component={MaintenanceList} />
    <Route exact path="/maintenance/add-new" component={MaintenanceAddForm} />
    <Route exact path="/maintenance/activities/:id" component={MaintenanceActivities} />
    <Route exact path="/maintenance/spare-parts" component={AllSpareParts} />
    <Route exact path="/maintenance/spare-parts/add-new" component={MaintenanceSparePartAddForm} />
  </Switch>
);
