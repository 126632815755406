import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { UserProps } from '../../../shared/prop-types/ReducerProps';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { user } = this.props;
    return (
      <div className="sidebar__content">
        {/* <ul className="sidebar__block">
          <SidebarLink title="Homepage" icon="home" route="/dashboard" />
          <SidebarLink title="Configuration" route="/configuration" icon="history" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarCategory title="Dash managment" icon="book">
            <SidebarLink title="Product editor" route="/dashboard-products" onClick={this.hideSidebar} />
            <SidebarLink title="Language editor" route="/dashboard-languages" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul> */}
        <ul className="sidebar__block">
          <SidebarLink title="Solos users" icon="user" route="/customers" onClick={this.hideSidebar} />
          <SidebarLink title="Solos companies" icon="users" route="/companies" onClick={this.hideSidebar} />
          { user.permissions && user.permissions.includes('company_verification.edit')
          && (
          <SidebarLink
            title="Company verification"
            icon="briefcase"
            route="/company-verification"
            onClick={this.hideSidebar}
          />
          )}
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Steora benches" icon="page-break" route="/benches" onClick={this.hideSidebar} />
          <SidebarLink title="Aerys" icon="sun" route="/aerys" onClick={this.hideSidebar} />
          <SidebarLink title="Terra containers" icon="trash" route="/waste-containers" onClick={this.hideSidebar} />
        </ul>
        {/* <ul className="sidebar__block">
          <SidebarCategory title="Products configuration (configurator)" icon="pencil">
            <SidebarLink title="Benches" route="/edit-benches" onClick={this.hideSidebar} />
            <SidebarLink title="Waste containers" route="/edit-waste" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul> */}

        {/* <ul className="sidebar__block">
          <SidebarLink
            title="Permissions-Customers"
            icon="exit"
            route="/customers-permissions"
            onClick={this.hideSidebar}
          />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink
            title="Permissions-Include users"
            icon="exit"
            route="/users-permissions"
            onClick={this.hideSidebar}
          />
        </ul> */}
        <ul className="sidebar__block">
          <SidebarLink title="Subscribers" icon="envelope" route="/subscribers" onClick={this.hideSidebar} />
        </ul>
        { user.permissions && user.permissions.includes('notifications.create')
          && (
          <ul className="sidebar__block">
            <SidebarCategory title="Notifications" icon="bullhorn" onClick={this.hideSidebar}>
              <SidebarLink title="Create global notification" route="/create-notification" onClick={this.hideSidebar} />
            </SidebarCategory>
          </ul>
          )}
        {
          <ul className="sidebar__block">
            <SidebarCategory title="Maintenance" icon="clock" onClick={this.hideSidebar}>
              <SidebarLink title="Maintenance List" route="/maintenance" onClick={this.hideSidebar} />
              <SidebarLink title="Spare Parts" route="/maintenance/spare-parts" onClick={this.hideSidebar} />
            </SidebarCategory>
          </ul>
          }
        { user.permissions && user.permissions.includes('permissions.edit')
          && (
          <ul className="sidebar__block">
            <SidebarLink
              title="Permissions (for Include users)"
              icon="cog"
              route="/permissions"
              onClick={this.hideSidebar}
            />
          </ul>
          )}
        <ul className="sidebar__block">
          <SidebarLink title="Log out" icon="exit" route="/login" onClick={this.hideSidebar} />
        </ul>
      </div>
    );
  }
}

SidebarContent.propTypes = {
  user: UserProps.isRequired,
};


export default compose(withTranslation('common')(SidebarContent));
