import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  VERIFY_COMPANY_REQUEST,
  VERIFY_COMPANY_SUCCESS,
  VERIFY_COMPANY_FAILURE,
  FETCH_ALL_COMPANIES_REQUEST,
  FETCH_ALL_COMPANIES_SUCCESS,
  FETCH_ALL_COMPANIES_FAILURE,
  FETCH_SINGLE_COMPANY_REQUEST,
  FETCH_SINGLE_COMPANY_SUCCESS,
  FETCH_SINGLE_COMPANY_FAILURE,
  FETCH_COMPANY_PRODUCTS_FAILURE,
  FETCH_COMPANY_PRODUCTS_REQUEST,
  FETCH_COMPANY_PRODUCTS_SUCCESS,
  PRODUCT_COMPANY_ACTIONS_SUCCESS,
  PRODUCT_COMPANY_ACTIONS_REQUEST,
  PRODUCT_COMPANY_ACTIONS_FAILURE,
} from '../actions/companiesAction';

const initialState = {
  error: '',
  loading: false,
  allCompaniesList: [],
  companiesList: [],
  companyVerificationSuccess: {},
  companyInfo: {},
  companyProducts: [],
  companyActionsResponse: '',
};

function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companiesList: action.payload,
        loading: false,
      };
    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ALL_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        allCompaniesList: action.payload,
        loading: false,
        companyInfo: {},
        companyProducts: [],
      };
    case FETCH_ALL_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        allCompaniesList: [],
        companyInfo: {},
        companyProducts: [],
      };
    case FETCH_SINGLE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        companyInfo: action.payload,
        loading: false,
      };
    case FETCH_SINGLE_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        companyInfo: {},
      };
    case FETCH_COMPANY_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_COMPANY_PRODUCTS_SUCCESS:
      return {
        ...state,
        companyProducts: action.payload,
        loading: false,
      };
    case FETCH_COMPANY_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        companyProducts: {},
      };
    case VERIFY_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_COMPANY_SUCCESS:
      return {
        ...state,
        companyVerificationSuccess: action.payload,
        loading: false,
      };
    case PRODUCT_COMPANY_ACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case PRODUCT_COMPANY_ACTIONS_SUCCESS:
      return {
        ...state,
        companyActionsResponse: action.payload,
        loading: false,
      };
    case PRODUCT_COMPANY_ACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        companyActionsResponse: {},
      };
    case VERIFY_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default companiesReducer;
