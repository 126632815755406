/* eslint-disable consistent-return */
import React, { PureComponent } from 'react';
import ReactDataGrid from 'react-data-grid';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

export default class EditableTable extends PureComponent {
  static propTypes = {
    heads: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    filteredRows: PropTypes.arrayOf(PropTypes.shape()),
    styleName: PropTypes.string,
  };

  static defaultProps = {
    styleName: '',
    filteredRows: [],
  };

  constructor(props, context) {
    super(props, context);
    const originalRows = props.rows;
    // const rows = originalRows.slice(0, 10);
    const rows = originalRows;
    this.state = { rows, originalRows };
  }

  handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    let { rows } = this.state;
    rows = rows.slice();

    for (let i = fromRow; i <= toRow; i += 1) {
      const rowToUpdate = rows[i];
      rows[i] = update(rowToUpdate, { $merge: updated });
    }

    this.setState({ rows });
  };


  handleGridSort = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
      } if (sortDirection === 'DESC') {
        return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
      }
    };

    const { originalRows } = this.state;
    const sortRows = originalRows.slice(0);
    // maka san slice, tako da prikaze sve
    // const rows = sortDirection === 'NONE' ? originalRows.slice(0, 10) : sortRows.sort(comparer).slice(0, 10);
    const rows = sortDirection === 'NONE' ? originalRows : sortRows.sort(comparer);

    this.setState({ rows });
  };

  rowGetter = (i) => {
    const { filteredRows } = this.props;
    const { rows } = this.state;
    if (filteredRows.length > 0) {
      return filteredRows[i];
    }
    return rows[i];
  };

  rowRenderer = ({ renderBaseRow, ...props }) => <div className="table-row__wrapper">{renderBaseRow(props)}</div>;

  render() {
    const { heads, rows, styleName } = this.props;
    return (
      <div className={`table ${styleName}`}>
        <ReactDataGrid
          onGridSort={this.handleGridSort}
          enableCellSelect
          columns={heads}
          rowGetter={this.rowGetter}
          rowsCount={rows.length}
          onGridRowsUpdated={this.handleGridRowsUpdated}
          rowHeight={44}
          rowRenderer={this.rowRenderer}
          minColumnWidth={100}
        />
      </div>
    );
  }
}
