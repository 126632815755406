import {
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAILURE,
  FETCH_ALL_USERS_DATA_REQUEST,
  FETCH_ALL_USERS_DATA_SUCCESS,
  FETCH_ALL_USERS_DATA_FAILURE,
  FETCH_OTHER_USER_INFO,
  ASSIGN_PERMISSIONS_TO_USER_REQUEST,
  ASSIGN_PERMISSIONS_TO_USER_SUCCESS,
  ASSIGN_PERMISSIONS_TO_USER_FAILURE,
} from '../actions/userAction';

const initialState = {
  error: null,
  loading: false,
  userInfo: {},
  usersList: [],
  otherUserInfo: {},
  lastUserUpdated: '',
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        loading: false,
      };
    case FETCH_USER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ALL_USERS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_USERS_DATA_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        otherUserInfo: {},
        loading: false,
      };
    case FETCH_ALL_USERS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_OTHER_USER_INFO:
      return {
        ...state,
        otherUserInfo: action.payload,
        loading: false,
      };
    case ASSIGN_PERMISSIONS_TO_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ASSIGN_PERMISSIONS_TO_USER_SUCCESS:
      return {
        ...state,
        lastUserUpdated: action.payload,
        userInfo: {},
        loading: false,
      };
    case ASSIGN_PERMISSIONS_TO_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default userReducer;
