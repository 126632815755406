import React from 'react';
import PropTypes from 'prop-types';
import ScheduleSingleRow from './Schedule';

const ScheduleView = ({
  handleScheduleClick,
  config,
}) => (
  <div className="schedule-view">
    <div className="schedule-row">
      <ScheduleSingleRow
        labelText="Variable B (Turn on:)"
        handleScheduleClick={(value, type) => handleScheduleClick(value, type, 'B')}
        currentValue={config.start}
      />
      <ScheduleSingleRow
        labelText="Variable E (Turn off:)"
        handleScheduleClick={(value, type) => handleScheduleClick(value, type, 'E')}
        currentValue={config.end}
      />
    </div>
  </div>
);

ScheduleView.propTypes = {
  handleScheduleClick: PropTypes.func.isRequired,
  config: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
  ])).isRequired,
};

export default ScheduleView;
