import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
import validate from './editPermissionsValidator';
import renderSelectField from '../../../shared/components/form/Select';

class NewRequest extends PureComponent {
  constructor() {
    super();

    this.state = {
      validationErrors: {
        user: '',
      },
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      validationErrors: { user: '' },
    });
    const { validationErrors } = this.state;
    const { eventFormData, onUserSelect } = this.props;

    const valuesToValidate = {
      user: eventFormData.values && eventFormData.values.usersListDropdown.value,
    };

    const errors = validate(valuesToValidate);
    if (isEmpty(errors)) {
      const userID = eventFormData.values.usersListDropdown.value;

      onUserSelect(userID);
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          user: errors.user,
        },
      });
    }
  }

  render() {
    const { t, users } = this.props;
    const { validationErrors } = this.state;

    return (
      <Col lg={6} xl={4}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{t('permissions.form_title')}</h5>
            </div>
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('permissions.form_subtitle')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="usersListDropdown"
                    component={renderSelectField}
                    options={users}
                  />
                </div>
                { validationErrors.user
                  && <span className="form__form-group-error"> {validationErrors.user} </span>}
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="success" type="submit">
                  {t('permissions.select_button')}
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

NewRequest.propTypes = {
  t: PropTypes.func.isRequired,
  onUserSelect: PropTypes.func.isRequired,
  eventFormData: PropTypes.objectOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

NewRequest.defaultProps = {
  eventFormData: {},
};

const mapStateToProps = state => ({
  rtl: state.rtl,
  eventFormData: state.form.selectUSerForPermissionsForm,
});

const NewRequestFormComponent = reduxForm({
  form: 'selectUSerForPermissionsForm',
})(NewRequest);

export default connect(mapStateToProps)(withTranslation('common')(NewRequestFormComponent));
