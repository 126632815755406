// auth reducer.js

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
} from '../actions/authActions';

import { isTokenValid, authUsername } from '../../shared/helpers/localStorage';

const authenticated = isTokenValid();
const username = authUsername();

const initialState = {
  authenticated,
  errorMessage: '',
  loading: false,
  username,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        loading: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        authenticated: false,
        loading: false,
      };
    default:
      return state;
  }
}

export default authReducer;
