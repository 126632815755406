import {
  createNotificationRequest,
  createNotificationSuccess,
  createNotificationFailure,
} from '../redux/actions/notificationsAction';

import authHeader from './authHeader';

export default function createNofication(params) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      title: params.title,
      msg: params.body,
    }),
  };

  return (dispatch) => {
    dispatch(createNotificationRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/notifications/create-custom`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(createNotificationSuccess(res.success));
      })
      .catch(error => dispatch(createNotificationFailure(error)));
  };
}
