/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import history from '../../../shared/helpers/history';
import validate from './PmSensorEditFormValidator';

import { fetchSingleAirSensorData, updateAirSensor } from '../../../api/airSensorsApi';

class UpdatePMSensorForm extends PureComponent {
  constructor() {
    super();

    this.state = {
      validationErrors: {
        correction_factor: '',
      },
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(fetchSingleAirSensorData(match.params.index));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      eventFormData, dispatch, match,
    } = this.props;
    const { validationErrors } = this.state;

    const valuesToValidate = {
      correction_factor: eventFormData.values && eventFormData.values.correction_factor,
    };

    const errors = validate(valuesToValidate);
    if (isEmpty(errors)) {
      const params = {
        correction_factor: eventFormData.values.correction_factor,
      };
      dispatch(updateAirSensor(eventFormData.values.id, params));
      history.push(`/aerys/${match.params.index}`);
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          correction_factor: errors.correction_factor,
        },
      });
    }
  }

  render() {
    const { initialValues } = this.props;
    const { validationErrors } = this.state;

    return (
      <Col xs={12} md={12} lg={12} xl={6}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Edit Air Sensor type for {initialValues.air_sensor_type.name}</h5>
            </div>
            <form className="form material-form" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Correction factor
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="correction_factor"
                    component="input"
                    type="number"
                    placeholder="e.g. 1.1"
                  />
                </div>
                { validationErrors.correction_factor
                && <span className="form__form-group-error"> {validationErrors.correction_factor} </span>}
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

UpdatePMSensorForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  eventFormData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool,
  ])),
  match: PropTypes.shape({
    params: PropTypes.shape({
      index: PropTypes.node,
    }).isRequired,
  }).isRequired,
  initialValues: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool,
  ])).isRequired,
};

UpdatePMSensorForm.defaultProps = {
  eventFormData: {},
};

const mapStateToProps = state => ({
  rtl: state.rtl,
  airSensorTypes: state.airSensor.airSensorTypes,
  airSensorAlgorithms: state.airSensor.airSensorAlgorithms,
  eventFormData: state.form.updatePMSensorForm,
  initialValues: state.airSensor.dataForUpdateForm,
  updatedAirSensor: state.airSensor.updatedAirSensor,
});

const updatePMSensorFormComponent = reduxForm({
  form: 'updatePMSensorForm',
  enableReinitialize: true,
})(UpdatePMSensorForm);

export default connect(mapStateToProps)(updatePMSensorFormComponent);
