const validate = (values) => {
  const errors = {};
  if (!values.user) {
    errors.user = 'You need to select a user.';
  }

  return errors;
};

export default validate;
