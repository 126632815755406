import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card, CardBody, Col,
} from 'reactstrap';
import ScheduleView from '../Scheduler/ScheduleView';
import { initialWifiSetConfig, updateWifiSchedule } from '../../redux/actions/manageAppActions';
import ApiResponseMessage from '../Modal/ApiResponseMessage';
import { setWifiHomepage, setNewWifiConfig } from '../../api/manageApi';

const WifiContainer = ({
  wifiCurrentConfig, errorMessage, selectedProduct, onNewConfigUpdate,
}) => {
  const startValue = wifiCurrentConfig[0].module.config.find(element => element.var === 'B');
  const endValue = wifiCurrentConfig[0].module.config.find(element => element.var === 'E');
  const wifiSsid = wifiCurrentConfig[0].module.config.find(element => element.name === 'SSID');

  const configEveryDay = {
    start: startValue.pending_value
      ? startValue.pending_value
      : startValue.value,
    end: endValue.pending_value
      ? endValue.pending_value
      : endValue.value,
  };

  const wifiSSIDValue = wifiSsid.pending_value ? wifiSsid.pending_value : wifiSsid.value;


  const ssidRegex = new RegExp(/^[A-Za-z0-9 ~<>@)(*'&_-]+$/);

  const dispatch = useDispatch();

  const [modalOpen, toggleModal] = useState(false);
  const [ssidValueToSend, changeSSID] = useState(wifiSSIDValue || 'Steora bench Wi-Fi');
  const [homepageValueToSend, changeHomepage] = useState(wifiCurrentConfig[0].homepage);
  const [ssidErr, setSSIDError] = useState(false);

  const handleScheduleClick = (e, type, variable) => {
    const { value } = e.target;
    const data = { value, type, variable };
    dispatch(updateWifiSchedule(data));
  };

  const submitWifiScheduleUpdate = () => {
    const params = {
      start: startValue.value,
      end: endValue.value,
    };
    dispatch(setNewWifiConfig([selectedProduct.id], 'schedule', params));
  };

  const closeModal = () => {
    toggleModal(false);
  };

  const handleSSIDChange = ({ target }) => {
    changeSSID(target.value);
  };

  const handleHomepageChange = ({ target }) => {
    changeHomepage(target.value);
  };

  const handleSubmit = () => {
    if (ssidRegex.test(ssidValueToSend)) {
      if (homepageValueToSend) dispatch(setWifiHomepage(selectedProduct.id, homepageValueToSend));
      else dispatch(setWifiHomepage(selectedProduct.device_id, 'https://include.eu'));
      dispatch(setNewWifiConfig([selectedProduct.id], 'ssid', ssidValueToSend));
      submitWifiScheduleUpdate();
      toggleModal(true);
    } else setSSIDError(true);
  };

  useEffect(() => {
    if (!('value' in startValue)) {
      const params = {
        start: '05:00',
        end: '20:00',
        ssid: 'Steora bench Wi-Fi',
      };
      dispatch(initialWifiSetConfig(params));
    }
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Wifi form</h5>
          </div>
          <div className="form form--horizontal">
            <div className="form__form-group">
              <span className="form__form-group-label">
                <span className="news__new-star">*</span>
                Variable SSID
              </span>
              <div className="form__form-group-field">
                <input
                  name="powerValue"
                  type="text"
                  onChange={handleSSIDChange}
                  defaultValue={ssidValueToSend}
                />
              </div>
              {ssidErr
              && <span className="form__form-group-error">{'Valid characters include A-z, 0-9, and (@*&-~<>)'}</span>}
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Landing page
              </span>
              <div className="form__form-group-field">
                <input
                  name="powerValue"
                  type="text"
                  onChange={handleHomepageChange}
                  defaultValue={wifiCurrentConfig[0].homepage}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                <span className="news__new-star">*</span>
                Schedule (every day)
              </span>
              <div className="form__form-group-field">
                <ScheduleView
                  config={configEveryDay}
                  handleScheduleClick={handleScheduleClick}
                />
              </div>
              <div className="save-btn">
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!ssidValueToSend}
                >Send config
                </Button>
              </div>

            </div>
            <ApiResponseMessage
              message={`WIFI config - ${onNewConfigUpdate || errorMessage}`}
              modalOpen={modalOpen}
              closeModal={closeModal}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

WifiContainer.propTypes = {
  wifiCurrentConfig: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  errorMessage: PropTypes.string,
  selectedProduct: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])).isRequired,
  onNewConfigUpdate: PropTypes.string.isRequired,
};

WifiContainer.defaultProps = {
  errorMessage: '',
};

export default WifiContainer;
