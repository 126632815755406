/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Col,
  ButtonToolbar,
  Button,
} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import moment from 'moment';
import TableHeader from './TableHeader';

function createData(id, createdAt, message, commType) {
  return {
    id,
    created_at: createdAt,
    message,
    comm_type: commType,
  };
}

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class LogsTable extends PureComponent {
  constructor(props) {
    super(props);
    const { allLogs } = this.props;
    this.state = {
      order: 'desc',
      orderBy: 'id',
      startDate: '',
      endDate: '',
      page: 0,
      rowsPerPage: 10,
      originalRows: allLogs.map((item, i) => createData(
        i + 1,
        item.created_at,
        item.message,
        item.comm_type,
      )),
      filteredRows: allLogs.map((item, i) => createData(
        i + 1,
        item.created_at,
        item.message,
        item.comm_type,
      )),
      showPeriod: false,
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { allLogs } = this.props;

    if (prevProps.allLogs.length !== allLogs.length) {
      this.updateProductsList();
    }
  }

  updateProductsList = () => {
    const { allLogs } = this.props;
    this.setState({
      originalRows: allLogs.map(item => createData(
        item.key,
        item.created_at,
        item.message,
      )),
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleFilterChange = ({ target }) => {
    const { originalRows } = this.state;
    let filteredRows = [];

    if (target.value.length > 4) {
      const filterRowsByDate = originalRows.filter(
        row => moment(row.created_at).format('DD.MM.YYYY HH:mm').includes(target.value),
      );
      filteredRows = filterRowsByDate;
      if (filterRowsByDate.length === 0) {
        const filterRowsByType = originalRows.filter(
          row => row.comm_type.toLowerCase().includes(target.value.toLowerCase()),
        );
        filteredRows = filterRowsByType;
      }
    }

    this.setState({ filteredRows });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  }

  onStartDateChange = (e) => {
    e.preventDefault();
    this.setState({ startDate: e.target.value });
  }

  onEndDateChange = (e) => {
    e.preventDefault();
    this.setState({ endDate: e.target.value });
  }

  toggle = () => {
    this.setState(prevState => ({ showPeriod: !prevState.showPeriod }));
  };

  render() {
    const {
      order, orderBy, rowsPerPage, page, filteredRows, startDate, endDate, showPeriod,
    } = this.state;

    const { handlePeriodClick } = this.props;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRows.length - (page * rowsPerPage));
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">List of logs</h5>
              <h5 className="subhead">*Created at is in UTC date/time</h5>
            </div>
            <div className="toggle-period-btn">
              <Button
                padding="2px 20px"
                onClick={this.toggle}
              > {showPeriod ? 'Hide date period picker' : 'Show date picker period'}
              </Button>
            </div>
            <div className="period-picker">
              {showPeriod
              && (
              <div className="date-filter">
                <span className="news__new-star">*</span>
                <span className="form__form-group-label">From</span>
                <div>
                  <input
                    name="startDate"
                    type="date"
                    onChange={this.onStartDateChange}
                  />
                </div>
                <span className="news__new-star">*</span>
                <span className="form__form-group-label">To</span>
                <div>
                  <input
                    name="endDate"
                    type="date"
                    onChange={this.onEndDateChange}
                  />
                </div>
                <Button
                  padding="2px 20px"
                  onClick={() => handlePeriodClick(startDate, endDate)}
                > Get logs in selected period
                </Button>
              </div>
              )
              }
            </div>
            <ButtonToolbar className="products-list__btn-toolbar-top filter-logs">
              <form className="form" onSubmit={this.handleSubmit}>
                <div className="form__form-group products-list__search">
                  <input
                    placeholder="Filter by created at or type"
                    onChange={this.handleFilterChange}
                  />
                  <MagnifyIcon />
                </div>
              </form>
            </ButtonToolbar>
            <div className="material-table__wrap">
              <Table className="material-table">
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={filteredRows.length}
                />
                <TableBody>
                  {filteredRows
                    .sort(getSorting(order, orderBy))
                    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                    .map(d => (
                      <TableRow
                        className="material-table__row"
                        role="checkbox"
                        // onClick={event => this.handleClick(event, d.id)}
                        tabIndex={-1}
                        key={d.id}
                      >
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {d.id}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {moment(d.created_at).utc().format('DD.MM.YYYY HH:mm:ss')}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >{(d.message).split('\n').map(str => <p className="code-blocks" key={str}>{str}</p>)}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          width="200px"
                        >{d.comm_type}
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 20, 50, 100]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}


LogsTable.propTypes = {
  allLogs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ]))).isRequired,
  handlePeriodClick: PropTypes.func.isRequired,
};
