/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import {
  Col, Card, CardBody, Container, Row, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import moment from 'moment';
import ProductPartsList from './Parts/Products';
import { getMaintenanceById, closeMaintenanceById, updateNameOrDateMaintenanceById } from '../../../api/maintenanceApi';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { BasicNotification } from '../../../shared/components/Notification';
import DateModal from '../../../components/Modal/ModalWithInputDate';
import TechnicalModal from '../../../components/Modal/ModalWithInputText';

let notification = null;

const showNotification = (title, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={message}
    />,
    duration: 6,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up ltr-support',
  });
};

const MaintenanceActivities = ({ match, maintenanceById }) => {
  const dispatch = useDispatch();

  const [openModal, toggleModal] = useState(false);
  const [openModalTech, toggleModalTech] = useState(false);
  const [confirmModalCancelMaintenanceOpen, toggleModalCancelMaintenance] = useState(false);

  useEffect(() => {
    const { params } = match;
    const { id } = params;

    dispatch(getMaintenanceById(id));
  }, [dispatch]);

  const openModalCancelMaintenance = () => {
    toggleModalCancelMaintenance(true);
  };


  const closeMaintenance = () => {
    toggleModalCancelMaintenance(false);
    dispatch(closeMaintenanceById(maintenanceById.id));
    const title = 'Maintenance closed';
    const message = 'Maintenance closed';
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
    setTimeout(() => showNotification(title, message), 10);
    dispatch(getMaintenanceById(maintenanceById.id));
  };

  const saveAction = (value, type) => {
    dispatch(updateNameOrDateMaintenanceById(maintenanceById.id, value, type));
    dispatch(getMaintenanceById(maintenanceById.id));
  };

  const isClosed = maintenanceById.status === 'closed';

  return (
    <Container>
      <Row>
        <Col xs={10}>
          <h2 className="page-title">
            Maintenance activities
          </h2>
        </Col>
        <Col xs={2}>
          {!isClosed
          && (
          <Button
            type="button"
            color="success"
            className="customer-action"
            id="close"
            style={{ width: '100px', height: '40px' }}
            onClick={() => openModalCancelMaintenance()}
          >Close
          </Button>
          )}
        </Col>
      </Row>
      <Col xs={12}>
        <Card>
          <CardBody>
            <p><strong>Maintenance ID:</strong> {maintenanceById.maintenance_id}</p>
            <p><strong>Maintenance Name:</strong> {maintenanceById.maintenance_name}</p>
            <p><strong>Country</strong> {maintenanceById && maintenanceById.country && maintenanceById.country.name}</p>
            <p><strong>Maintenance Description:</strong> {maintenanceById.description}</p>
            <p><strong>Status:</strong> {maintenanceById.status}
            </p>
            <p><strong>Created On:</strong> {moment(maintenanceById.created_at).format('DD.MM.YYYY HH:mm')}</p>
            <p><strong>Contact person:</strong> {maintenanceById.contact_person}</p>
            <p><strong>Contact Details:</strong> {maintenanceById.contact_details}</p>
            <p><strong>Under warranty:</strong>
              {maintenanceById.under_warranty === 1 ? 'Yes' : 'No'}
            </p>
            <p><strong>Technician name: </strong>{maintenanceById.technician_name}
              {!isClosed && (
              <Button
                padding="2px 20px"
                style={{ fontSize: '12px' }}
                className="calibration-btn"
                onClick={() => toggleModalTech(true)}
              > Enter Technician Name
              </Button>
              )}
            </p>
            <div className="calibration" style={{ marginTop: '10px' }}>
              <p>
                <strong>
                  Maintenance start date:
                </strong>{maintenanceById.maintenance_date}
                {!isClosed && (
                <Button
                  padding="2px 20px"
                  style={{ fontSize: '12px' }}
                  className="maintenance-btn"
                  onClick={() => toggleModal(true)}
                > Enter maintenance date
                </Button>
                )}
              </p>

            </div>

            {maintenanceById && maintenanceById.deployed_products
          && (
          <ProductPartsList
            products={maintenanceById.deployed_products}
            key={maintenanceById.deployed_products}
            status={maintenanceById.status}
          />
          )}
          </CardBody>

        </Card>
      </Col>
      <ConfirmModal
        modalOpen={confirmModalCancelMaintenanceOpen}
        onCloseClick={toggleModalCancelMaintenance}
        message="Are you sure you want to permanently close this Maintenace?"
        handleConfirm={closeMaintenance}
      />
      <DateModal
        modalOpen={openModal}
        onCloseClick={toggleModal}
        message={`Enter maintenance date ${maintenanceById.maintenance_id}`}
        date={maintenanceById.maintenance_date}
        confirmAction={saveAction}
      />
      <TechnicalModal
        modalOpen={openModalTech}
        onCloseClick={toggleModalTech}
        message={`Enter technician name for ${maintenanceById.maintenance_id}`}
        date={maintenanceById.maintenance_date}
        confirmAction={saveAction}
      />
    </Container>
  );
};

MaintenanceActivities.propTypes = {
  maintenanceById: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object,
  ])).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  maintenanceById: state.maintenance.maintenanceById,
});

export default connect(mapStateToProps)(MaintenanceActivities);
