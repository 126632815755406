import {
  fetchAllCustomersDataRequest,
  fetchAllCustomersDataSuccess,
  fetchAllCustomersDataFailure,
  fetchSingleCustomerDataRequest,
  fetchSingleCustomerDataSuccess,
  fetchSingleCustomerDataFailure,
  deactivateCustomerRequest,
  deactivateCustomerSuccess,
  deactivateCustomerFailure,
  deleteCustomerRequest,
  deleteCustomerSuccess,
  deleteCustomerFailure,
  sendActivationLinkRequest,
  sendActivationLinkSuccess,
  sendActivationLinkFailure,
  assignProductToCustomerRequest,
  assignProductToCustomerSuccess,
  assignProductToCustomerFailure,
  removeProductFromCustomerRequest,
  removeProductFromCustomerSuccess,
  removeProductFromCustomerFailure,
  update2FARequest,
  update2FASuccess,
  update2FAFailure,
} from '../redux/actions/customersAction';

import authHeader from './authHeader';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
}

export function fetchAllCustomersData() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchAllCustomersDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchAllCustomersDataSuccess(res));
      })
      .catch(error => dispatch(fetchAllCustomersDataFailure(error)));
  };
}

export function fetchSingleCustomerData(customerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchSingleCustomerDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/get-for-admin/${customerId}`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchSingleCustomerDataSuccess(res.data));
      })
      .catch(error => dispatch(fetchSingleCustomerDataFailure(error)));
  };
}

export function deactivateCustomer(customerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(deactivateCustomerRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/deactivate/${customerId}`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(deactivateCustomerSuccess(res.success));
      })
      .catch(error => dispatch(deactivateCustomerFailure(error)));
  };
}

export function deleteCustomer(customerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(deleteCustomerRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/forget/${customerId}`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(deleteCustomerSuccess(res.success));
      })
      .catch(error => dispatch(deleteCustomerFailure(error)));
  };
}

export function sendActivationLink(customerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(sendActivationLinkRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/resend-activation/${customerId}`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(sendActivationLinkSuccess(res.success));
      })
      .catch(error => dispatch(sendActivationLinkFailure(error)));
  };
}

export function assignProductToCustomer(activationCode, customerId) {
  const assignOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      activation_code: activationCode,
      customer_id: customerId,
    }),
  };

  return (dispatch) => {
    dispatch(assignProductToCustomerRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/assign-product`, assignOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(assignProductToCustomerSuccess(res.success));
      })
      .catch(error => dispatch(assignProductToCustomerFailure(error)));
  };
}

export function removeProductFromCustomer(productId, customerId) {
  const assignOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      product_id: productId,
      customer_id: customerId,
    }),
  };

  return (dispatch) => {
    dispatch(removeProductFromCustomerRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/detach-product`, assignOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(removeProductFromCustomerSuccess(res.success));
      })
      .catch(error => dispatch(removeProductFromCustomerFailure(error)));
  };
}

// 2FA enabled/disabled
export function update2FA(customerId) {
  const updateOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      google2fa: false,
    }),
  };

  return (dispatch) => {
    dispatch(update2FARequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/toggleGoogle2FA/${customerId}`, updateOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(update2FASuccess(res.success));
      })
      .catch(error => dispatch(update2FAFailure(error)));
  };
}
