import {
  fetchSubscribersRequest,
  fetchSubscribersSuccess,
  fetchSubscribersFailure,
  removeSubscriberRequest,
  removeSubscriberSuccess,
  removeSubscriberFailure,
  addSubscriberRequest,
  addSubscriberSuccess,
  addSubscriberFailure,
} from '../redux/actions/subscribersAction';

import authHeader from './authHeader';

const requestOptions = {
  method: 'GET',
  headers: authHeader(),
};

export function fetchSubscribers() {
  return (dispatch) => {
    dispatch(fetchSubscribersRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/newsletter`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchSubscribersSuccess(res));
      })
      .catch(error => dispatch(fetchSubscribersFailure(error)));
  };
}

export function removeSubscriber(subscriberId) {
  return (dispatch) => {
    dispatch(removeSubscriberRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/newsletter/remove/${subscriberId}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(removeSubscriberSuccess(res));
      })
      .catch(error => dispatch(removeSubscriberFailure(error)));
  };
}

export function assignPermissionToRole(email) {
  const postOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      email,
    }),
  };

  return (dispatch) => {
    dispatch(addSubscriberRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/newsletter`, postOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(addSubscriberSuccess(res.data));
      })
      .catch(error => dispatch(addSubscriberFailure(error)));
  };
}
