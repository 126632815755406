const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Title shoud not be empty.';
  }
  if (!values.body) {
    errors.body = 'You have to write a message.';
  }
  return errors;
};

export default validate;
