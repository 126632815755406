import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomerListTable from './components/CustomersList';
import { fetchAllCustomersData } from '../../api/customersApi';

class Customers extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAllCustomersData());
  }

  render() {
    const { t, allCustomersList } = this.props;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">All Solos users</h3>
          </Col>
        </Row>
        <Row>
          {allCustomersList.length > 0
          && (
          <CustomerListTable
            t={t}
            customersList={allCustomersList}
          />
          )}
        </Row>
      </Container>
    );
  }
}

Customers.propTypes = {
  t: PropTypes.func.isRequired,
  allCustomersList: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  allCustomersList: state.customers.allCustomersList,
  themeName: state.theme.className,
});


export default compose(withTranslation('common'), connect(
  (mapStateToProps),
))(Customers);
