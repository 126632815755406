import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
// import TopbarNotification from './TopbarNotification';
// import TopbarSearch from './TopbarSearch';
import { UserProps } from '../../../shared/prop-types/ReducerProps';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    user: UserProps.isRequired,
    // notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    // handleAllRead: PropTypes.func.isRequired,
  };

  render() {
    const {
      changeMobileSidebarVisibility,
      changeSidebarVisibility,
      user,
      // notifications,
      // handleAllRead,
    } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className="topbar__logo" to="/home" />
          </div>
          <div className="topbar__right">
            {/* <TopbarSearch />
            <TopbarNotification notifications={notifications} onAllRead={handleAllRead} /> */}
            <TopbarProfile user={user} />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
