import {
  fetchUserDataRequest,
  fetchUserDataSuccess,
  fetchUserDataFailure,
  fetchAllUsersDataRequest,
  fetchAllUsersDataSuccess,
  fetchAllUsersDataFailure,
  fetchOtherUserInfo,
  assignPermissionsToUserRequest,
  assignPermissionsToUserSuccess,
  assignPermissionsToUserFailure,
} from '../redux/actions/userAction';

import { changeThemeToLight, changeThemeToDark } from '../redux/actions/themeActions';

import authHeader from './authHeader';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
}

const requestOptions = {
  method: 'GET',
  headers: authHeader(),
};

export function fetchUserData(username, fetchOtherUser) {
  return (dispatch) => {
    dispatch(fetchUserDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/users/${username}`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        if (fetchOtherUser) dispatch(fetchOtherUserInfo(res.data));
        else {
          dispatch(fetchUserDataSuccess(res.data));
          if (res.data.dark_theme) dispatch(changeThemeToDark());
          else dispatch(changeThemeToLight());
        }
      })
      .catch(error => dispatch(fetchUserDataFailure(error)));
  };
}

export function toggleUserTheme() {
  return (dispatch) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/dark-theme`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
      })
      .catch(error => dispatch(fetchUserDataFailure(error)));
  };
}

export function fetchAllUsersData() {
  return (dispatch) => {
    dispatch(fetchAllUsersDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/users`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchAllUsersDataSuccess(res.data));
      })
      .catch(error => dispatch(fetchAllUsersDataFailure(error)));
  };
}

export function assignPermissionToUser(id, params, username) {
  const assignOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      permissions: params,
      user: 'ldap',
    }),
  };

  return (dispatch) => {
    dispatch(assignPermissionsToUserRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/permissions/sync-permissions-to-user/${id}`, assignOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(assignPermissionsToUserSuccess(res.success));
        dispatch(fetchUserData(username));
      })
      .catch(error => dispatch(assignPermissionsToUserFailure(error)));
  };
}
