const validate = (values) => {
  const errors = {};
  if (!values.activationCode) {
    errors.activationCode = 'Please fill in activation code first.';
  }

  return errors;
};

export default validate;
