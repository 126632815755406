import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../shared/img/closeIcon.svg';

const SensorAQIIndex = ({ modalOpen, closeModal }) => (
  <Modal isOpen={modalOpen} className="custom__modal-container aqi-modal">
    <div className="custom__modal-header">
      <button
        className="close-btn"
        type="button"
        onClick={closeModal}
      > <CloseIcon alt="close" />
      </button>
      <div className="intro-text">
        <p>Solos Air Quality Index calculation and displaying is based on latest European Environment Agency
          and WHO methodology and instructions.
          The index bands are complemented by health related messages that provide recommendations for both the general
          population and sensitive populations.
          The latter includes both adults and children with respiratory problems and adults with heart conditions.
          Please have in mind that our development team is constantly working on improving the system and
          methodology for calculating AQI.
        </p>
      </div>
    </div>
    <div className="modal__body">
      <div className="index-description">
        <p><span className="circle-color good" /><span className="text-label">Good</span></p>
        <p className="text-description">
          General population – The air quality is good, enjoy your usual outdoor activities
        </p>
        <p className="text-description">
          Sensitive populations – The air quality is good, enjoy your usual outdoor activities
        </p>
      </div>
      <div className="index-description">
        <p><span className="circle-color fair" /><span className="text-label">Fair</span></p>
        <p className="text-description">General population – Enjoy your usual outdoor activities</p>
        <p className="text-description">Sensitive populations – Enjoy your usual outdoor activities</p>
      </div>
      <div className="index-description">
        <p><span className="circle-color moderate" /><span className="text-label">Moderate</span></p>
        <p className="text-description">General population – Enjoy your usual outdoor activities</p>
        <p className="text-description">Sensitive populations – Consider reducing intense outdoor activities,
          if you experience symptoms
        </p>
      </div>
      <div className="index-description">
        <p><span className="circle-color poor" /><span className="text-label">Poor</span></p>
        <p className="text-description">
          General population – Consider reducing intense activities outdoors, if you experience symptoms such
          as sore eyes,a cough or sore throat
        </p>
        <p className="text-description">
          Sensitive populations – Consider reducing physical activities, particularly outdoors,
          especially if you experience symptoms
        </p>
      </div>
      <div className="index-description">
        <p><span className="circle-color very-poor" /><span className="text-label">Very poor</span></p>
        <p className="text-description">General population – Consider reducing intense activities outdoors,
          if you experience symptoms such as sore eyes, a cough or sore throat
        </p>
        <p className="text-description">Sensitive populations – Reduce physical activities, particularly outdoors,
          especially if you experience symptoms
        </p>
      </div>
      <div className="index-description">
        <p><span className="circle-color extremely-poor" /><span className="text-label">Extremely poor</span></p>
        <p className="text-description">General population – Reduce physical activities outdoors</p>
        <p className="text-description">Sensitive populations – Avoid physical activities outdoors</p>
      </div>
    </div>
  </Modal>
);

SensorAQIIndex.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SensorAQIIndex;
