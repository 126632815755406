export const GET_DEPARTMENTS_REQUEST = 'GET_DEPARTMENTS_REQUEST';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAILURE = 'GET_DEPARTMENTS_FAILURE';

export function getDepartmentsRequest() {
  return {
    type: GET_DEPARTMENTS_REQUEST,
  };
}

export function getDepartmentsSuccess(data) {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    payload: data,
  };
}

export function getDepartmentsFailure(error) {
  return {
    type: GET_DEPARTMENTS_FAILURE,
    payload: error,
  };
}
