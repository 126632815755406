import React from 'react';
import PropTypes from 'prop-types';

const MODULE_ID_FOR_HEATING_SURFACE = 1;
const MODULE_ID_FOR_POWER_BUTTON = 24;
const MODULE_ID_FOR_WIFI = 2;
const MODULE_ID_FOR_AMBIENT_LIGHT = 8;
const MODULE_ID_FOR_TERRA = 48;

const ManageMenu = ({
  color, handleChange, selectedProduct, selectedRoute,
}) => {
  let hasWifi = false;
  let hasAmbientLight = false;
  let hasHeatingSurface = false;
  let hasTerraModules = false;

  const product = selectedProduct;
  if (product.deployed_modules.some(module => module.module_id === MODULE_ID_FOR_WIFI)) {
    hasWifi = true;
  } else {
    hasWifi = false;
  }

  if (product.deployed_modules.some(module => module.module_id === MODULE_ID_FOR_AMBIENT_LIGHT)) {
    hasAmbientLight = true;
  } else {
    hasAmbientLight = false;
  }

  if (product.deployed_modules.some(module => module.module_id === MODULE_ID_FOR_HEATING_SURFACE)) {
    hasHeatingSurface = true;
  } else {
    hasHeatingSurface = false;
  }

  if (product.deployed_modules.some(module => module.module_id === MODULE_ID_FOR_TERRA)) {
    hasTerraModules = true;
  } else {
    hasTerraModules = false;
  }

  return (
    <div className="manage-menu">
      <ul className="module-list">
        <li className="list-item">
          <button
            type="button"
            className={selectedRoute === 'location' ? `${color}` : ''}
            onClick={() => handleChange('location')}
          >
            Location
          </button>
        </li>
        {hasWifi
            && (
              <li className="list-item">
                <button
                  type="button"
                  className={selectedRoute === 'wi-fi' ? `${color}` : ''}
                  onClick={() => handleChange('wi-fi', MODULE_ID_FOR_WIFI)}
                >
                  Wi-fi
                </button>
              </li>
            )}
        {hasAmbientLight
            && (
              <li className="list-item">
                <button
                  type="button"
                  className={selectedRoute === 'ambient-light' ? `${color}` : ''}
                  onClick={() => handleChange('ambient-light', MODULE_ID_FOR_AMBIENT_LIGHT)}
                >
                  Ambient light
                </button>
              </li>
            )}
        {hasHeatingSurface
            && (
              <li className="list-item">
                <button
                  type="button"
                  className={selectedRoute === 'heating-surface' ? `${color}` : ''}
                  onClick={() => handleChange('heating-surface', MODULE_ID_FOR_HEATING_SURFACE)}
                >
                  Heating surface
                </button>
              </li>
            )}
        {hasTerraModules
            && (
              <li className="list-item">
                <button
                  type="button"
                  className={selectedRoute === 'terra' ? `${color}` : ''}
                  onClick={() => handleChange('terra', MODULE_ID_FOR_TERRA)}
                >
                  Terra modules
                </button>
              </li>
            )}
        <li className="list-item">
          <button
            type="button"
            className={selectedRoute === 'power-button' ? `${color}` : ''}
            onClick={() => handleChange('power-button', MODULE_ID_FOR_POWER_BUTTON)}
          >
            Power button
          </button>
        </li>
        <li className="list-item">
          <button
            type="button"
            className={selectedRoute === 'subscription' ? `${color}` : ''}
            onClick={() => handleChange('subscription')}
          >Subscription
          </button>
        </li>
      </ul>
    </div>
  );
};

ManageMenu.propTypes = {
  color: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedProduct: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])).isRequired,
  selectedRoute: PropTypes.string.isRequired,
};

export default ManageMenu;
