/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Button, UncontrolledTooltip,
} from 'reactstrap';
import ScheduleView from '../Scheduler/ScheduleView';
import { setNewTerraConfig } from '../../api/manageApi';
import { updateTerraSchedule, initialSetConfig } from '../../redux/actions/manageAppActions';
import ApiResponseMessage from '../Modal/ApiResponseMessage';

const TerraModulesForm = ({
  terraCurrentConfig, errorMessage, selectedProduct, onNewConfigUpdate,
}) => {
  const startValue = terraCurrentConfig[0].module.config.find(element => element.var === 'B');
  const endValue = terraCurrentConfig[0].module.config.find(element => element.var === 'E');
  const cVariable = terraCurrentConfig[0].module.config.find(element => element.var === 'C');
  const pVariable = terraCurrentConfig[0].module.config.find(element => element.var === 'P');

  const configEveryDay = {
    start: startValue.pending_value
      ? startValue.pending_value !== '' ? startValue.pending_value : '01:00'
      : startValue.value !== '' ? startValue.value : '01:00',
    end: endValue.pending_value
      ? endValue.pending_value !== '' ? endValue.pending_value : '04:00'
      : endValue.value !== '' ? endValue.value : '04:00',
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (!('value' in startValue)) {
      const params = {
        start: '01:00',
        end: '04:00',
      };
      dispatch(initialSetConfig(params));
    }
  }, []);

  const [modalOpen, toggleModal] = useState(false);
  const [pVariableValue, setPVariableValue] = useState(cVariable.value);
  const [cVariableValue, setCVariableValue] = useState(pVariable.value);

  const handleScheduleClick = (e, type, variable) => {
    const { value } = e.target;
    const data = { value, type, variable };
    dispatch(updateTerraSchedule(data));
  };

  const submitTerraScheduleUpdate = () => {
    const params = {
      start: startValue.value,
      end: endValue.value,
      pVariable: pVariableValue,
      cVariable: cVariableValue,
    };
    dispatch(setNewTerraConfig([selectedProduct.id], 'schedule', params));

    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
  };

  const onPChange = (e) => {
    setPVariableValue(e.target.value);
  };
  const onCChange = (e) => {
    setCVariableValue(e.target.value);
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Terra schedule form</h5>
          </div>
          <div className="form form--horizontal">
            <div className="form__form-group">
              <span className="form__form-group-label">
                Schedule (every day)
              </span>
              <div className="form__form-group-field">
                <ScheduleView
                  config={configEveryDay}
                  handleScheduleClick={handleScheduleClick}
                />
              </div>
            </div>
            <div>
              <div>
                <UncontrolledTooltip placement="top" target="p_variable">{`(${pVariable.name})`}</UncontrolledTooltip>
                <span className="form__form-group-label-extend" id="p_variable">
                  Set P Variable:
                </span>
                <input
                  type="number"
                  min={0}
                  max={100}
                  onChange={onPChange}
                  value={pVariableValue}
                />
              </div>
              <div style={{ marginTop: 25 }}>
                <UncontrolledTooltip placement="top" target="c_variable">{`(${cVariable.name})`}</UncontrolledTooltip>
                <span className="form__form-group-label-extend" id="c_variable">
                  Set C Variable :
                </span>
                <input type="number" width={30} min={0} max={100} onChange={onCChange} value={cVariableValue} />
              </div>
            </div>
          </div>
          <div className="schedule-view heating-container">
            <div className="save-btn">
              <Button
                color="primary"
                type="submit"
                onClick={submitTerraScheduleUpdate}
              >Send config
              </Button>
            </div>
          </div>
          <ApiResponseMessage
            message={`Terra modules - ${onNewConfigUpdate || errorMessage}`}
            modalOpen={modalOpen}
            closeModal={closeModal}
            error={onNewConfigUpdate.length === 0 ? 'API call error' : ''}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

TerraModulesForm.propTypes = {
  terraCurrentConfig: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  errorMessage: PropTypes.string,
  selectedProduct: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])).isRequired,
  onNewConfigUpdate: PropTypes.string.isRequired,
};

TerraModulesForm.defaultProps = {
  errorMessage: '',
};

export default TerraModulesForm;
