import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import ScheduleView from '../Scheduler/ScheduleView';
import ApiResponseMessage from '../Modal/ApiResponseMessage';

import {
  updateLEDValueForSpring,
  updateLEDValueForSummer,
  updateLEDValueForFall,
  updateLEDValueForWinter,
} from '../../redux/actions/manageAppActions';
import { setNewLEDConfig } from '../../api/manageApi';

const AmbientLight = ({
  ledCurrentConfig,
  selectedProduct,
  onLedConfigUpdate,
  errorMessage,
}) => {
  const configBySeassons = ledCurrentConfig[0].seasons;
  const configSummer = {
    start: configBySeassons[0].config[0].value,
    end: configBySeassons[0].config[1].value,
  };
  const configFall = {
    start: configBySeassons[1].config[0].value,
    end: configBySeassons[1].config[1].value,
  };
  const configWinter = {
    start: configBySeassons[2].config[0].value,
    end: configBySeassons[2].config[1].value,
  };
  const configSpring = {
    start: configBySeassons[3].config[0].value,
    end: configBySeassons[3].config[1].value,
  };

  const dispatch = useDispatch();
  const [modalOpen, toggleModal] = useState(false);

  const closeModal = () => {
    toggleModal(false);
  };

  const handleSpringChange = (e, type, variable) => {
    const { value } = e.target;
    const data = { value, type, variable };
    dispatch(updateLEDValueForSpring(data));
  };

  const handleSummerChange = (e, type, variable) => {
    const { value } = e.target;
    const data = { value, type, variable };
    dispatch(updateLEDValueForSummer(data));
  };

  const handleFallChange = (e, type, variable) => {
    const { value } = e.target;
    const data = { value, type, variable };
    dispatch(updateLEDValueForFall(data));
  };

  const handleWinterChange = (e, type, variable) => {
    const { value } = e.target;
    const data = { value, type, variable };
    dispatch(updateLEDValueForWinter(data));
  };

  const submitLedScheduleUpdate = () => {
    dispatch(setNewLEDConfig([selectedProduct.id], ledCurrentConfig[0].seasons));
    toggleModal(true);
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Ambient light form</h5>
          </div>
          <div className="form form--horizontal">
            <div className="form__form-group">
              <span className="form__form-group-label ambient-light-label">
                <span className="news__new-star">*</span>
                Schedule for Spring
                <br />
                March 21st - June 20th
                <br />
              </span>
              <div className="form__form-group-field ambient-light">
                <ScheduleView
                  handleScheduleClick={handleSpringChange}
                  config={configSpring}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label ambient-light-label">
                <span className="news__new-star">*</span>
                Schedule for Summer
                <br />
                June 21st - September 22nd
                <br />
              </span>
              <div className="form__form-group-field ambient-light">
                <ScheduleView
                  handleScheduleClick={handleSummerChange}
                  config={configSummer}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label ambient-light-label">
                <span className="news__new-star">*</span>
                Schedule for Fall
                <br />
                September 23rd - December 20th
                <br />
              </span>
              <div className="form__form-group-field ambient-light">
                <ScheduleView
                  handleScheduleClick={handleFallChange}
                  config={configFall}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label ambient-light-label">
                <span className="news__new-star">*</span>
                Schedule for Winter
                <br />
                December 21st - March 20th
                <br />
              </span>
              <div className="form__form-group-field ambient-light">
                <ScheduleView
                  handleScheduleClick={handleWinterChange}
                  config={configWinter}
                />
              </div>
            </div>
            <div className="save-btn">
              <Button
                color="primary"
                type="submit"
                onClick={submitLedScheduleUpdate}
              > Send config
              </Button>
            </div>
            <ApiResponseMessage
              message={onLedConfigUpdate || errorMessage}
              modalOpen={modalOpen}
              closeModal={closeModal}
            />
          </div>
        </CardBody>
      </Card>
    </Col>

  );
};

AmbientLight.propTypes = {
  ledCurrentConfig: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  selectedProduct: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])).isRequired,
  onLedConfigUpdate: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

AmbientLight.defaultProps = {
  errorMessage: '',
};

export default AmbientLight;
