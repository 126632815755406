import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import SidebarLink from './SidebarLink';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Početna" icon="home" route="/home" />
          <SidebarLink title="Novosti" route="/news" icon="book" onClick={this.hideSidebar} />
          <SidebarLink title="O nama" route="/about-us" icon="history" onClick={this.hideSidebar} />
          <SidebarLink title="Kalendari" route="/calendars" icon="calendar-full" onClick={this.hideSidebar} />
          <SidebarLink title="Oglasi za posao" route="/job-ads" icon="briefcase" onClick={this.hideSidebar} />
          <SidebarLink title="Marketing i prodaja" icon="store" route="/marketing-sales" />
          <SidebarLink title="Imenik" icon="phone" route="/address-book" onClick={this.hideSidebar} />
          <SidebarLink title="Ljudski resursi" icon="users" route="/human-resources" onClick={this.hideSidebar} />
          <SidebarLink title="Poslovna putovanja" icon="earth" route="/traveling" onClick={this.hideSidebar} />
          <SidebarLink title="Razvoj" icon="code" route="/development" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Dokumenti" icon="file-empty" route="/documents" />
          <SidebarLink title="Slike" icon="picture" route="/gallery" />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Odjava" icon="exit" route="/login" onClick={this.hideSidebar} />
        </ul>
      </div>
    );
  }
}

export default compose(withTranslation('common')(SidebarContent));
