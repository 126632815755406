import React from 'react';
import {
  Col, Card, CardBody,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import history from '../../../shared/helpers/history';
import { selectSensorBoard } from '../../../redux/actions/airSensorActions';

const CalculationData = ({ data, productID }) => {
  const dispatch = useDispatch();
  const openSensor = () => {
    dispatch(selectSensorBoard(data));
    if (data.air_sensor_type.name.includes('PM')) history.push(`/aerys/pm-gas/edit/${productID}`);
    else history.push(`/aerys/air-sensors/edit/${productID}`);
  };
  return (
    <Col lg={4}>
      <Card>
        <CardBody>
          {!data.air_sensor_type.name.includes('PM') ? (
            <>
              <h3>Calculation data for {data.air_sensor_type.name}</h3>
              <p><strong>Sensor Type:</strong>
                {data.air_sensor_type && data.air_sensor_type.name ? `${data.air_sensor_type.name}` : 'No data'}
              </p>
              <p><strong>Board Type:</strong>{data.board_type ? `${data.board_type}` : 'No data'}</p>
              <p><strong>Gain (mV/vA):</strong>{data.gain ? `${data.gain}` : 'No data'}</p>
              <p><strong>Sensor number:</strong>{data.sensor_number ? `${data.sensor_number}` : 'No data'}</p>
              <p><strong>WE Zero(mV):</strong>{data.we_zero ? `${data.we_zero}` : 'No data'}</p>
              <p><strong>Aux Zero(mV):</strong>{data.aux_zero ? `${data.aux_zero}` : 'No data'}</p>
              <p><strong>WE Sensor (nA/ppm):</strong>{data.we_sensor ? `${data.we_sensor}` : 'No data'}</p>
              <p><strong>Sensitivity (mV/ppb):</strong>{data.sensitivity ? `${data.sensitivity}` : 'No data'}</p>
              <p><strong>Electronic Zero (WE) (mV):</strong>
                {data.we_electronic_zero ? `${data.we_electronic_zero}` : 'No data'}
              </p>
              <p><strong>Electronic Zero (AUX) (mV):</strong>
                {data.aux_electronic_zero ? `${data.aux_electronic_zero}` : 'No data'}
              </p>
              <p><strong>NO<sub>2</sub> Sensitivity (nA/ppm):</strong>
                {data.no2_sensitivity ? `${data.no2_sensitivity}` : 'No data'}
              </p>
              <p><strong>Algorithm type:</strong>
                {data.algorithm && data.algorithm === '1' ? 'Suggested' : 'Alternative'} algorithm
              </p>
              <p><strong>Correction factor:</strong>
                {data.correction_factor ? `${data.correction_factor}` : 'No data'}
              </p>
              <hr />
              <p><strong>Last change of data:</strong> {moment(data.updated_at).format('DD.MM.YYYY   HH:mm')}</p>
              <div className="delete-data">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => openSensor()}
                >Edit data
                </button>
              </div>
            </>
          )
            : (
              <>
                <h3>Calculation data for {data.air_sensor_type.name}</h3>
                <p><strong>Sensor Type:</strong>
                  {data.air_sensor_type && data.air_sensor_type.name ? `${data.air_sensor_type.name}` : 'No data'}
                </p>
                <p><strong>Correction factor:</strong>
                  {data.correction_factor ? `${data.correction_factor}` : 'No data'}
                </p>
                <hr />
                <p><strong>Last change of data:</strong> {moment(data.updated_at).format('DD.MM.YYYY   HH:mm')}</p>
                <div className="delete-data">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => openSensor(data.air_sensor_type_id)}
                  >Edit data
                  </button>
                </div>
              </>
            )}

        </CardBody>
      </Card>
    </Col>
  );
};


CalculationData.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object, PropTypes.number,
  ])).isRequired,
  productID: PropTypes.number.isRequired,
};


export default CalculationData;
