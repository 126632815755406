
const latRegex = /^(\+|-)?(?:90(?:(?:\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/;
const longRegex = /^(\+|-)?(?:180(?:(?:\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/;

const validate = (values) => {
  const errors = {};
  if (!values.lattitude) {
    errors.lattitude = 'Please input latitutde coordinates';
  }
  if (!values.longitude) {
    errors.longitude = 'Please input longitude coordinates';
  }
  if (values.lattitude && !latRegex.test(values.lattitude)) {
    errors.lattitude = 'Wrong format for latitude coordinates';
  }
  if (values.longtitude && !longRegex.test(values.longitude)) {
    errors.longitude = 'Wrong format for longitude coordinates';
  }
  return errors;
};

export default validate;
