import {
  fetchDeployedProductsRequest,
  fetchDeployedBenchesSuccess,
  fetchDeployedWasteSuccess,
  fetchDeployedAerysSuccess,
  fetchDeployedProductsFailure,
  fetchModelDataRequest,
  fetchModelDataSuccess,
  fetchModelDataFailure,
  updateModelDataRequest,
  updateModelDataSuccess,
  updateModelDataFailure,
  fetchAllWasteContainersModelsDataRequest,
  fetchAllWasteContainersModelsDataSuccess,
  fetchAllWasteContainersModelsDataFailure,
  fetchAllBenchesModelsRequest,
  fetchAllBenchesModelsSuccess,
  fetchAllBenchesModelsFailure,
  fetchAllAerysModelsRequest,
  fetchAllAerysModelsSuccess,
  fetchAllAerysModelsFailure,
  fetchContainerModelDataRequest,
  fetchContainerModelDataSuccess,
  fetchContainerModelDataFailure,
  fetchAllModulesRequest,
  fetchAllModulesSuccess,
  fetchAllModulesFailure,
  noSearchResults,
  fetchAllLogsRequest,
  fetchAllLogsSuccess,
  fetchAllLogsFailure,
  fetchMaintenanceRequest,
  fetchMaintenanceSuccess,
  fetchMaintenanceFailure,
  fetchProductListRequest,
  fetchProductListSuccess,
  fetchProductListFailure,
} from '../redux/actions/productsActions';

import authHeader from './authHeader';

const requestOptions = {
  method: 'GET',
  headers: authHeader(),
};

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
}

export function fetchBenchModelData(model) {
  return (dispatch) => {
    dispatch(fetchModelDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/products/${model}`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchModelDataSuccess(res));
      })
      .catch(error => dispatch(fetchModelDataFailure(error)));
  };
}

export function updateBenchModel(model) {
  const updateOptions = {
    method: 'PUT',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(updateModelDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/products/${model}`, updateOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(updateModelDataSuccess(res));
      })
      .catch(error => dispatch(updateModelDataFailure(error)));
  };
}

export function fetchAllWasteContainersModelsData() {
  return (dispatch) => {
    dispatch(fetchAllWasteContainersModelsDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/products/type/waste-containers`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchAllWasteContainersModelsDataSuccess(res));
      })
      .catch(error => dispatch(fetchAllWasteContainersModelsDataFailure(error)));
  };
}

export function fetchAllBenchesModelsData() {
  return (dispatch) => {
    dispatch(fetchAllBenchesModelsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/products/type/benches`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchAllBenchesModelsSuccess(res));
      })
      .catch(error => dispatch(fetchAllBenchesModelsFailure(error)));
  };
}

export function fetchWasteModelData(containerType) {
  return (dispatch) => {
    dispatch(fetchContainerModelDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/products/${containerType}`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchContainerModelDataSuccess(res));
      })
      .catch(error => dispatch(fetchContainerModelDataFailure(error)));
  };
}

export function fetchAllAerysModelsData() {
  return (dispatch) => {
    dispatch(fetchAllAerysModelsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/products/type/aerys`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchAllAerysModelsSuccess(res));
      })
      .catch(error => dispatch(fetchAllAerysModelsFailure(error)));
  };
}

export function fetchDeployedProductsByType(type, page) {
  const options = {
    method: 'POST',
    headers: authHeader(),
  };

  const pagination = page ? `?page=${page}` : '';

  return (dispatch) => {
    dispatch(fetchDeployedProductsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/${type}${pagination}`, options)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        if (type === 'benches') dispatch(fetchDeployedBenchesSuccess(res));
        else if (type === 'waste-containers') dispatch(fetchDeployedWasteSuccess(res));
        else if (type === 'aerys') dispatch(fetchDeployedAerysSuccess(res));
      })
      .catch(error => dispatch(fetchDeployedProductsFailure(error)));
  };
}

export function fetchDeployedProductsByTypeMaintenance(page) {
  const options = {
    method: 'GET',
    headers: authHeader(),
  };

  const pagination = page ? `?page=${page}` : '';

  return (dispatch) => {
    dispatch(fetchDeployedProductsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/maintenance${pagination}`, options)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(fetchMaintenanceSuccess(res));
      })
      .catch(error => dispatch(fetchDeployedProductsFailure(error)));
  };
}

export function searchDeployedProducts(type, searchTerm) {
  const options = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ search_term: searchTerm }),
  };

  return (dispatch) => {
    dispatch(fetchDeployedProductsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/${type}`, options)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        if (type === 'benches') {
          if (res.data.length > 0) dispatch(fetchDeployedBenchesSuccess(res));
          else dispatch(noSearchResults(searchTerm));
        } else if (type === 'waste-containers') dispatch(fetchDeployedWasteSuccess(res));
        else if (type === 'aerys') {
          if (res.data.length > 0) dispatch(fetchDeployedAerysSuccess(res));
          else dispatch(noSearchResults(searchTerm));
        }
      })
      .catch(error => dispatch(fetchDeployedProductsFailure(error)));
  };
}

export function searchDeployedProductsMaintenance(searchTerm) {
  const options = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchDeployedProductsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/maintenance?search_term=${searchTerm}`, options)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        if (res.data.length > 0) dispatch(fetchMaintenanceSuccess(res));
        else dispatch(noSearchResults(searchTerm));
      })
      .catch(error => dispatch(fetchDeployedProductsFailure(error)));
  };
}

export function fetchAllModules() {
  return (dispatch) => {
    dispatch(fetchAllModulesRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/modules`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchAllModulesSuccess(res));
      })
      .catch(error => dispatch(fetchAllModulesFailure(error)));
  };
}

export function fetchAllLogs(deployedProductId, startDate, endDate) {
  const options = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      deployedProductId,
      start_date: startDate,
      end_date: endDate,
    }),
  };

  return (dispatch) => {
    dispatch(fetchAllLogsRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/get-log/${deployedProductId}`, options)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchAllLogsSuccess(res.data));
      })
      .catch(error => dispatch(fetchAllLogsFailure(error)));
  };
}

export function fetchMaintenanceData() {
  return (dispatch) => {
    dispatch(fetchMaintenanceRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/maintenance`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchMaintenanceSuccess(res));
      })
      .catch(error => dispatch(fetchMaintenanceFailure(error)));
  };
}

export function getDeployedProductsList() {
  return (dispatch) => {
    dispatch(fetchProductListRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/list`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchProductListSuccess(res));
      })
      .catch(error => dispatch(fetchProductListFailure(error)));
  };
}
