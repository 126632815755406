const validate = (values) => {
  const errors = {};
  if (!values.board_type) {
    errors.board_type = 'Board type can not be empty';
  }
  if (!values.gain) {
    errors.gain = 'Gain can not be empty';
  }
  if (!values.sensor_number) {
    errors.sensor_number = 'Sensor number can not be empty';
  }
  if (!values.we_zero) {
    errors.we_zero = 'WE0 can not be empty';
  }
  if (!values.aux_zero) {
    errors.aux_zero = 'AUX0 can not be empty';
  }
  if (!values.we_sensor) {
    errors.we_sensor = 'WE sensor can not be empty';
  }
  if (!values.sensitivity) {
    errors.sensitivity = 'Sensitivity can not be empty';
  }
  if (!values.we_electronic_zero) {
    errors.we_electronic_zero = 'WE electronic 0 can not be empty';
  }
  if (!values.aux_electronic_zero) {
    errors.aux_electronic_zero = 'AUX electronic 0 can not be empty';
  }
  if (!values.algorithm) {
    errors.algorithm = 'Algorithm can not be empty';
  }
  if (!values.correction_factor) {
    errors.correction_factor = 'Please enter correction factor (1 is default)';
  }
  if (values.correction_factor && values.correction_factor < 0) {
    errors.correction_factor = 'Correction factor can not be less than 0 ';
  }

  return errors;
};

export default validate;
