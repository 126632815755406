import React from 'react';
import { Modal, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../shared/img/closeIcon.svg';

const ConfirmModal = ({
  modalOpen, onCloseClick, handleConfirm, message,
}) => {
  const handleClosing = () => {
    onCloseClick(false);
  };

  return (
    <Modal isOpen={modalOpen} className="custom__modal-container confirm-modal">
      <div className="custom__modal-header">
        <button
          className="close-btn"
          type="button"
          onClick={handleClosing}
        > <CloseIcon alt="close" />
        </button>
      </div>
      <div className="modal__body">
        <h3 className=" modal__title">Confirm action</h3>
        <p>{message}</p>
      </div>
      <div className="modal__btn">
        <Button type="button" text="Cancel" padding="5px 30px" onClick={handleClosing}>
          No
        </Button>
        <Button type="button" text="Confirm" padding="5px 30px" onClick={handleConfirm}> Yes </Button>
      </div>
    </Modal>
  );
};
ConfirmModal.propTypes = {
  message: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  message: 'Are you sure?',
};

export default ConfirmModal;
