import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import ScheduleView from '../Scheduler/ScheduleView';
import { setNewHeatingConfig } from '../../api/manageApi';
import {
  updateHeatingSchedule, updateTemperature, initialHeatingSetConfig,
} from '../../redux/actions/manageAppActions';
import ApiResponseMessage from '../Modal/ApiResponseMessage';
import TemperatureList from '../DropdownList/DropdownList';

const HeatingSurfaceContainer = ({
  heatingCurrentConfig, errorMessage, selectedProduct, onNewConfigUpdate,
}) => {
  const startValue = heatingCurrentConfig[0].module.config.find(element => element.var === 'B');
  const endValue = heatingCurrentConfig[0].module.config.find(element => element.var === 'E');
  const temperatureValue = heatingCurrentConfig[0].module.config.find(element => element.var === 'V');

  const configEveryDay = {
    start: startValue.pending_value
      ? startValue.pending_value
      : startValue.value,
    end: endValue.pending_value
      ? endValue.pending_value
      : endValue.value,
  };

  const temperature = temperatureValue.pending_value ? temperatureValue.pending_value : temperatureValue.value;

  const dispatch = useDispatch();

  const [modalOpen, toggleModal] = useState(false);
  const [temperatureLabel, changeTemperature] = useState(temperature);

  const handleScheduleClick = (e, type, variable) => {
    const { value } = e.target;
    const data = { value, type, variable };
    dispatch(updateHeatingSchedule(data));
  };

  const handleTemperatureClick = ({ target }) => {
    dispatch(updateTemperature(target.value));
    changeTemperature(target.value);
  };

  const submitHeatingScheduleUpdate = () => {
    const params = {
      start: startValue.value,
      end: endValue.value,
      temperature: temperatureValue.value,
    };
    dispatch(setNewHeatingConfig([selectedProduct.id], 'schedule', params));

    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
  };

  useEffect(() => {
    if (!('value' in startValue)) {
      const params = {
        start: '20:00',
        end: '03:00',
        temperature: -5,
      };
      dispatch(initialHeatingSetConfig(params));
    }
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Heating surface form</h5>
          </div>
          <div className="form form--horizontal">
            <div className="form__form-group">
              <span className="form__form-group-label">
                <span className="news__new-star">*</span>
                Schedule (every day)
              </span>
              <div className="form__form-group-field">
                <ScheduleView
                  config={configEveryDay}
                  handleScheduleClick={handleScheduleClick}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                <span className="news__new-star">*</span>
                Temperature
                <br />
                {`Activate below ${temperatureLabel} °C`}
              </span>
              <div className="form__form-group-field">
                <TemperatureList
                  labelText="Variable V (Turn on:)"
                  handleClick={handleTemperatureClick}
                  currentValue={temperature}
                />
              </div>
            </div>
          </div>
          <div className="schedule-view heating-container">
            <div className="save-btn">
              <Button
                color="primary"
                type="submit"
                onClick={submitHeatingScheduleUpdate}
              >Send config
              </Button>
            </div>
          </div>
          <ApiResponseMessage
            message={`Heating surface Schedule - ${onNewConfigUpdate || errorMessage}`}
            modalOpen={modalOpen}
            closeModal={closeModal}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

HeatingSurfaceContainer.propTypes = {
  heatingCurrentConfig: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  errorMessage: PropTypes.string,
  selectedProduct: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])).isRequired,
  onNewConfigUpdate: PropTypes.string.isRequired,
};

HeatingSurfaceContainer.defaultProps = {
  errorMessage: '',
};

export default HeatingSurfaceContainer;
