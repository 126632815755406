import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CompaniesList from '../../../Companies';
import CustomersListContainer from '../../../Companies/CustomersListContainer';
import CompanyProductsContainer from '../../../Companies/CompanyProductsContainer';

export default () => (
  <Switch>
    <Route exact path="/companies" component={CompaniesList} />
    <Route exact path="/companies/:id" component={CustomersListContainer} />
    <Route exact path="/companies/company-products/:id" component={CompanyProductsContainer} />
  </Switch>
);
