import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import renderSelectField from '../../../shared/components/form/Select';
import renderTextField from '../../../shared/components/form/Input';
import validate from './AddAirSensorFormValidator';

import { fetchAirSensorTypes, createAirSensor } from '../../../api/airSensorsApi';


class AddAirSensorForm extends PureComponent {
  constructor() {
    super();

    this.state = {
      validationErrors: {
        air_sensor_type: '',
        board_type: '',
        gain: '',
        sensor_number: '',
        we_zero: '',
        aux_zero: '',
        we_sensor: '',
        sensitivity: '',
        we_electronic_zero: '',
        aux_electronic_zero: '',
        algorithm: '',
      },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAirSensorTypes());
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { eventFormData, dispatch } = this.props;
    const { validationErrors } = this.state;

    const valuesToValidate = {
      air_sensor_type: eventFormData.values && eventFormData.values.air_sensor_type,
      board_type: eventFormData.values && eventFormData.values.board_type,
      gain: eventFormData.values && eventFormData.values.gain,
      sensor_number: eventFormData.values && eventFormData.values.sensor_number,
      we_zero: eventFormData.values && eventFormData.values.we_zero,
      aux_zero: eventFormData.values && eventFormData.values.aux_zero,
      we_sensor: eventFormData.values && eventFormData.values.we_sensor,
      sensitivity: eventFormData.values && eventFormData.values.sensitivity,
      we_electronic_zero: eventFormData.values && eventFormData.values.we_electronic_zero,
      aux_electronic_zero: eventFormData.values && eventFormData.values.aux_electronic_zero,
      algorithm: eventFormData.values && eventFormData.values.algorithm,
    };
    const errors = validate(valuesToValidate);
    if (isEmpty(errors)) {
      const params = {
        air_sensor_type: eventFormData.values.air_sensor_type.value,
        board_type: eventFormData.values.board_type,
        gain: eventFormData.values.gain,
        sensor_number: eventFormData.values.sensor_number,
        we_zero: eventFormData.values.we_zero,
        aux_zero: eventFormData.values.aux_zero,
        we_sensor: eventFormData.values.we_sensor,
        sensitivity: eventFormData.values.sensitivity,
        we_electronic_zero: eventFormData.values.we_electronic_zero,
        aux_electronic_zero: eventFormData.values.aux_electronic_zero,
        no2_sensitivity: eventFormData.values.no2_sensitivity,
        algorithm: (eventFormData.values.algorithm.value).toString(),
      };
      dispatch(createAirSensor(params));
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          air_sensor_type: errors.air_sensor_type,
          board_type: errors.board_type,
          gain: errors.gain,
          sensor_number: errors.sensor_number,
          we_zero: errors.we_zero,
          aux_zero: errors.aux_zero,
          we_sensor: errors.we_sensor,
          sensitivity: errors.sensitivity,
          we_electronic_zero: errors.we_electronic_zero,
          aux_electronic_zero: errors.aux_electronic_zero,
          no2_sensitivity: errors.no2_sensitivity,
          algorithm: errors.algorithm,
        },
      });
    }
  }

  render() {
    const { airSensorTypes, airSensorAlgorithms } = this.props;
    const { validationErrors } = this.state;

    return (
      <Col xs={12} md={12} lg={12} xl={6}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Add new Air Sensor</h5>
            </div>
            <form className="form material-form" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Air Sensor Type
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="air_sensor_type"
                    component={renderSelectField}
                    options={airSensorTypes}
                  />
                </div>
                { validationErrors.air_sensor_type
                && <span className="form__form-group-error"> {validationErrors.air_sensor_type} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Board Type
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="board_type"
                    component={renderTextField}
                    type="text"
                    placeholder="e.g. 1"
                  />
                </div>
                { validationErrors.board_type
                && <span className="form__form-group-error"> {validationErrors.board_type} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Gain
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="gain"
                    component={renderTextField}
                    type="text"
                    placeholder="e.g. 0.8"
                  />
                </div>
                { validationErrors.gain
                && <span className="form__form-group-error"> {validationErrors.gain} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Sensor number
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="sensor_number"
                    component={renderTextField}
                    type="text"
                    placeholder="e.g. 160240360"
                  />
                </div>
                { validationErrors.sensor_number
                && <span className="form__form-group-error"> {validationErrors.sensor_number} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  WE<sub>0</sub>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="we_zero"
                    component="input"
                    type="number"
                    placeholder="e.g. 222"
                  />
                </div>
                { validationErrors.we_zero
                && <span className="form__form-group-error"> {validationErrors.we_zero} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  AUX<sub>0</sub>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="aux_zero"
                    component="input"
                    type="number"
                    placeholder="e.g. 226"
                  />
                </div>
                { validationErrors.aux_zero
                && <span className="form__form-group-error"> {validationErrors.aux_zero} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  WE sensor
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="we_sensor"
                    component="input"
                    type="number"
                    placeholder="e.g. 661"
                  />
                </div>
                { validationErrors.we_sensor
                && <span className="form__form-group-error"> {validationErrors.we_sensor} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Sensitivity
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="sensitivity"
                    component={renderTextField}
                    type="text"
                    placeholder="e.g. 0.326"
                  />
                </div>
                { validationErrors.sensitivity
                && <span className="form__form-group-error"> {validationErrors.sensitivity} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  WE electronic<sub>0</sub>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="we_electronic_zero"
                    component="input"
                    type="number"
                    placeholder="e.g. 240"
                  />
                </div>
                { validationErrors.we_electronic_zero
                && <span className="form__form-group-error"> {validationErrors.we_electronic_zero} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  AUX electronic<sub>0</sub>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="aux_electronic_zero"
                    component="input"
                    type="number"
                    placeholder="e.g. 230"
                  />
                </div>
                { validationErrors.aux_electronic_zero
                && <span className="form__form-group-error"> {validationErrors.aux_electronic_zero} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  NO<sup>2</sup> Sensitivity
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="no2_sensitivity"
                    component={renderTextField}
                    type="text"
                    placeholder="e.g. -356"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Algorithm
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="algorithm"
                    component={renderSelectField}
                    options={airSensorAlgorithms}
                  />
                </div>
                { validationErrors.algorithm
                && <span className="form__form-group-error"> {validationErrors.algorithm} </span>}
              </div>

              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

AddAirSensorForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  airSensorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  airSensorAlgorithms: PropTypes.arrayOf(PropTypes.object).isRequired,
  eventFormData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool,
  ])),
};

AddAirSensorForm.defaultProps = {
  eventFormData: {},
};

const mapStateToProps = state => ({
  rtl: state.rtl,
  airSensorTypes: state.airSensor.airSensorTypes,
  airSensorAlgorithms: state.airSensor.airSensorAlgorithms,
  eventFormData: state.form.newAirSensorForm,
});

const NewAirSensorForm = reduxForm({
  form: 'newAirSensorForm',
})(AddAirSensorForm);

export default connect(mapStateToProps)(NewAirSensorForm);
