import React from 'react';
import LogInForm from './LogInForm';

const LoginCard = () => (
  <div className="account__wrapper">
    <div className="account__card">
      <div className="account__head">
        <h3 className="account__title">Welcome in
          <span className="account__logo-accent"> Admin Solos</span>
        </h3>
        <h4 className="account__subhead subhead">Login into your admin Solos interface</h4>
      </div>
      <LogInForm
        form="log_in_form"
      />
    </div>
  </div>
);

export default LoginCard;
