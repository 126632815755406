import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as reduxFormReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';
import airSensorReducer from '../../redux/reducers/airSensorReducer';

import {
  sidebarReducer,
  themeReducer,
  customizerReducer,
  rtlReducer,
  authReducer,
  userReducer,
  customersReducer,
  searchReducer,
  rolesReducer,
  notificationsReducer,
  settingsReducer,
  configuratorReducer,
  companiesReducer,
  productsReducer,
  productInfoReducer,
  subscribersReducer,
  sensorAppReducer,
  manageAppReducer,
  maintenanceAppReducer,
  countriesReducer,
} from '../../redux/reducers/index';

const reducer = combineReducers({
  airSensor: airSensorReducer,
  authentication: authReducer,
  customers: customersReducer,
  customizer: customizerReducer,
  companies: companiesReducer,
  configurator: configuratorReducer,
  form: reduxFormReducer,
  manageApp: manageAppReducer,
  notifications: notificationsReducer,
  products: productsReducer,
  maintenance: maintenanceAppReducer,
  productInfo: productInfoReducer,
  roles: rolesReducer,
  rtl: rtlReducer,
  search: searchReducer,
  sensors: sensorAppReducer,
  settings: settingsReducer,
  sidebar: sidebarReducer,
  subscribers: subscribersReducer,
  theme: themeReducer,
  user: userReducer,
  countries: countriesReducer,
});

const store = createStore(reducer, composeWithDevTools(
  applyMiddleware(
    thunkMiddleware,
  ),
));

export default store;
