const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Username field must not be blank!';
  }
  if (!values.password) {
    errors.password = 'Password field must not be blank!';
  }

  return errors;
};

export default validate;
