import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import TableHeader from './TableHeader';
import ChangePartsModal from '../../../../components/Modal/ChangePartsModal';

const DropDownMore = ({ id, handleChangedParts }) => (
  <UncontrolledDropdown className="dashboard__table-more delete-option-table">
    <DropdownToggle>
      <p><DotsHorizontalIcon /></p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <DropdownItem id={id} onClick={handleChangedParts}>Add changed parts</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

DropDownMore.propTypes = {
  id: PropTypes.number.isRequired,
  handleChangedParts: PropTypes.func.isRequired,
};
function createData(id, deviceId, parts, details) {
  return {
    id,
    device_id: deviceId,
    changed_parts: parts,
    details,
  };
}

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class ProductPartsList extends PureComponent {
  constructor(props) {
    super(props);
    const { products } = this.props;

    this.state = {
      order: 'desc',
      orderBy: 'id',
      data: products.map(item => createData(
        item.id,
        item.device_id,
        item.changed_parts,
        item.details,
      )),
      modalOpen: false,
      selectedRow: [],
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidUpdate(prevProps) {
    const { products } = this.props;

    if (prevProps.products.length !== products.length) {
      this.updateProductsList();
    }
  }

  handleToggle = (selectedData) => {
    this.setState(prevState => ({ modalOpen: !prevState.modalOpen }));
    this.setState({ selectedRow: selectedData });
  }

  updateProductsList = () => {
    const { products } = this.props;
    this.setState({
      data: products.map(item => createData(
        item.id,
        item.device_id,
        item.changed_parts,
        item.details,
      )),
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  render() {
    const {
      data, order, orderBy, rowsPerPage, page, modalOpen, selectedRow,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));
    const path = window.location.pathname.split('/');
    const { products, status } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <hr />
            <div className="card__title" />
            <div className="material-table__wrap">
              <Table className="material-table">
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                />
                <TableBody>
                  {data
                    .sort(getSorting(order, orderBy))
                    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                    .map(d => (
                      <TableRow
                        className="material-table__row"
                        role="checkbox"
                        // onClick={event => this.handleClick(event, d.id)}
                        tabIndex={-1}
                        key={d.id}
                      >
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {d.id}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {d.device_id}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >{d.changed_parts.map(item => `${item.name},`)}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        > {status !== 'closed' ? (
                          <DropDownMore
                            id={d.id}
                            handleChangedParts={() => {
                              this.handleToggle(d);
                            }}
                          />
                        ) : 'No options'}
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 20, 50, 100]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            />
            <ChangePartsModal
              modalOpen={modalOpen}
              onCloseClick={this.handleToggle}
              maintenanceId={Number(path[3])}
              partsInitialChecked={selectedRow}
              data={products}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

ProductPartsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ]))).isRequired,
  status: PropTypes.string.isRequired,
};
