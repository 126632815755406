/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import {
  ButtonToolbar, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { Link } from 'react-router-dom';
import EditTable from '../../../shared/components/table/EditableTable';

const DropDownMore = ({ id, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more jobs__list-dropdown">
    <DropdownToggle>
      <p><DotsHorizontalIcon /></p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <DropdownItem id={id} onClick={handleDeleteRow}>Delete</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);
DropDownMore.propTypes = {
  id: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};


class UsersListTable extends PureComponent {
  constructor(props) {
    super(props);
    this.heads = [
      {
        key: 'key',
        name: '#',
        width: 50,
        sortable: true,
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
      },
      {
        key: 'email',
        name: 'Email address',
        sortable: true,
      },
      {
        key: 'role',
        name: 'Company role',
        sortable: true,
      },
      {
        key: 'profile',
        name: 'Profile',
      },
    ];

    this.state = {
      originalRows: this.createRows(props.allCustomersList),
      pageOfItems: [],
      filteredRows: [],
    };
    // this.confirmDeletion = this.confirmDeletion.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  /* confirmDeletion = (id) => {
    const { onDeleteClick } = this.props;
    onDeleteClick(id);
  } */

  componentDidUpdate(prevProps) {
    const { allCustomersList } = this.props;

    if (prevProps.allCustomersList.length !== allCustomersList.length) {
      this.updateCustomerList();
    }
  }

  updateCustomerList = () => {
    const { allCustomersList } = this.props;
    this.setState({ originalRows: this.createRows(allCustomersList) });
  }

  handleFilterChange = ({ target }) => {
    const { originalRows } = this.state;
    let filteredRows = [];

    if (target.value.length > 2) {
      const filterRowsByName = originalRows.filter(row => row.name.toLowerCase().includes(target.value.toLowerCase()));

      filteredRows = filterRowsByName;

      if (filterRowsByName.length === 0) {
        const filterRowsByEmail = originalRows.filter(
          row => row.email.toLowerCase().includes(target.value.toLowerCase()),
        );
        filteredRows = filterRowsByEmail;
      }
      if (filterRowsByName.length === 0) {
        const filterRowsByRole = originalRows.filter(
          row => row.role.toLowerCase().includes(target.value.toLowerCase()),
        );
        filteredRows = filterRowsByRole;
      }
    }

    this.setState({ filteredRows });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  }

  createRows = (users) => {
    const rows = [];
    const count = 1;
    users.forEach((user, key) => rows.push({
      id: count + 1,
      key: key + 1,
      name: user.name,
      email: user.email,
      role: user.company_role,
      profile:
  <Link
    to={`/customers/${user.id}`}
    link={`/customers/${user.id}`}
  >details
  </Link>,
    }));
    return rows;
  };

  render() {
    const { originalRows, filteredRows } = this.state;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="products-list">
            <div className="card__title">
              <h5 className="bold-text">All customers for selected company</h5>
              <ButtonToolbar className="products-list__btn-toolbar-top">
                <form className="form" onSubmit={this.handleSubmit}>
                  <div className="form__form-group products-list__search">
                    <input
                      placeholder="Filter by email or name..."
                      onChange={this.handleFilterChange}
                    />
                    <MagnifyIcon />
                  </div>
                </form>
              </ButtonToolbar>
            </div>
            <EditTable
              heads={this.heads}
              rows={originalRows}
              filteredRows={filteredRows}
              styleName="address__book"
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}

UsersListTable.propTypes = {
  allCustomersList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UsersListTable;
