// Fetch list of all countries
import {
  fetchCountriesRequest,
  fetchCountriesSuccess,
  fetchCountriesFailure,
} from '../redux/actions/countriesActions';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
}

const requestOptions = { method: 'GET' };

function fetchCountries() {
  return (dispatch) => {
    dispatch(fetchCountriesRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/countries`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        const data = res.map(item => ({ label: item.name, value: item.id }));
        dispatch(fetchCountriesSuccess(data));
      })
      .catch(error => dispatch(fetchCountriesFailure(error)));
  };
}

export default fetchCountries;
