import {
  FETCH_ALL_ACCESSORIES_REQUEST,
  FETCH_ALL_ACCESSORIES_SUCCESS,
  FETCH_ALL_ACCESSORIES_FAILURE,
  FETCH_ALL_COLORS_REQUEST,
  FETCH_ALL_COLORS_SUCCESS,
  FETCH_ALL_COLORS_FAILURE,
} from '../actions/configuratorActions';

const initialState = {
  error: null,
  loading: false,
  accessoriesList: [],
  colorsList: [],
};

function configuratorReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_ACCESSORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_ACCESSORIES_SUCCESS:
      return {
        ...state,
        accessoriesList: action.payload,
        loading: false,
      };
    case FETCH_ALL_ACCESSORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ALL_COLORS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_COLORS_SUCCESS:
      return {
        ...state,
        colorsList: action.payload,
        loading: false,
      };
    case FETCH_ALL_COLORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default configuratorReducer;
