import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
  Card, CardBody,
} from 'reactstrap';
import { useDispatch, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchDeployedProductInfo } from '../../api/productInfoApi';
import ManageMenu from './ManageMenu';
import { selectRoute, deSelectedRoutes } from '../../redux/actions/manageAppActions';
import LocationForm from './LocationForm';
import { fetchCurrentConfig, fetchLedCurrentConfig } from '../../api/manageApi';
import PowerButtonForm from './PowerButtonForm';
import HeatingSurfaceForm from './HeatingSurfaceForm';
import AmbientLight from './AmbientLight';
import WifiContainer from './WifiContainer';
import Subscription from './Subscription';
import TerraModulesForm from './TerraModulesForm';
import { UserProps } from '../../shared/prop-types/ReducerProps';

const ManageDataContainer = ({
  selectedProduct, match, manageApp, user,
}) => {
  const dispatch = useDispatch();

  const {
    selectedRoute, heatingCurrentConfig, onNewConfigUpdate, errorMessage, ledCurrentConfig,
    onLedConfigUpdate, wifiCurrentConfig, powerButtonCurrentConfig, extendSubscriptionResponse,
    terraCurrentConfig,
  } = manageApp;

  useEffect(() => {
    const { params } = match;
    const { id } = params;
    dispatch(fetchDeployedProductInfo(id));
    dispatch(deSelectedRoutes());
  }, [dispatch]);

  const handleChange = (route, moduleId) => {
    dispatch(selectRoute(route));

    if (moduleId) {
      if (moduleId === 8) dispatch(fetchLedCurrentConfig([selectedProduct.id]));
      else dispatch(fetchCurrentConfig([selectedProduct.id], moduleId));
    }
  };

  const productTypeSlug = selectedProduct && selectedProduct.product_type && selectedProduct.product_type.slug;
  return (
    <Container>
      <Row>
        <Col md={6}>
          <h3 className="page-title">Manage options for {selectedProduct.device_id}</h3>
        </Col>
        <Col md={6}>
          <Link
            className="btn btn-primary account__btn sensor-btn-display"
            to={`/${productTypeSlug}/logs/${selectedProduct.id}`}
          >All logs
          </Link>
          <Link
            className="btn btn-primary account__btn sensor-btn-display"
            to={`/${productTypeSlug}/sensors/${selectedProduct.id}`}
          >Sensor data
          </Link>
          <Link
            className="btn btn-primary account__btn sensor-btn-display"
            to={`/${productTypeSlug}/${selectedProduct.id}`}
          >Details
          </Link>
        </Col>
      </Row>
      {user.permissions && user.permissions.includes('manage.edit')
          && (
          <Row>
            <Col>
              <Card>
                <CardBody>

                  <div className="sensors__info-wrap">
                    <h4>Manage menu</h4>
                  </div>
                  {selectedProduct && selectedProduct.id && (
                  <ManageMenu
                    color="blue"
                    handleChange={handleChange}
                    selectedProduct={selectedProduct}
                    selectedRoute={selectedRoute}
                  />
                  )}
                  <br />
                  <hr />
                  {selectedRoute === 'location' && <LocationForm typeOfProduct={selectedProduct.product_type.slug} />}
                  {selectedRoute === 'power-button' && powerButtonCurrentConfig.length > 0
                  && <PowerButtonForm typeOfProduct={selectedProduct.product_type.slug} />}
                  {selectedRoute === 'heating-surface' && heatingCurrentConfig.length > 0 && (
                  <HeatingSurfaceForm
                    heatingCurrentConfig={heatingCurrentConfig}
                    selectedProduct={selectedProduct}
                    onNewConfigUpdate={onNewConfigUpdate}
                    errorMessage={errorMessage}
                  />
                  )}
                  {selectedRoute === 'terra' && terraCurrentConfig.length > 0 && (
                  <TerraModulesForm
                    terraCurrentConfig={terraCurrentConfig}
                    selectedProduct={selectedProduct}
                    onNewConfigUpdate={onNewConfigUpdate}
                    errorMessage={errorMessage}
                  />
                  )}
                  {selectedRoute === 'ambient-light' && ledCurrentConfig.length > 0
                && (
                <AmbientLight
                  ledCurrentConfig={ledCurrentConfig}
                  selectedProduct={selectedProduct}
                  onLedConfigUpdate={onLedConfigUpdate}
                  errorMessage={errorMessage}
                />
                )}
                  {selectedRoute === 'wi-fi' && wifiCurrentConfig.length > 0 && (
                  <WifiContainer
                    selectedRoute={selectedRoute}
                    wifiCurrentConfig={wifiCurrentConfig}
                    errorMessage={errorMessage}
                    selectedProduct={selectedProduct}
                    onNewConfigUpdate={onNewConfigUpdate}
                  />
                  )}
                  {selectedRoute === 'subscription' && (
                  <Subscription
                    selectedProduct={selectedProduct}
                    extendSubscriptionResponse={extendSubscriptionResponse}
                    errorMessage={errorMessage}
                  />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          )}
    </Container>
  );
};

ManageDataContainer.propTypes = {
  selectedProduct: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  manageApp: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])).isRequired,
  user: UserProps.isRequired,
};


const mapStateToProps = state => ({
  selectedProduct: state.productInfo.productDetails,
  manageApp: state.manageApp,
  user: state.user.userInfo,
});

export default connect(mapStateToProps)(ManageDataContainer);
