import {
  CREATE_NOTIFICATION_REQUEST,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAILURE,
} from '../actions/notificationsAction';

const initialState = {
  error: null,
  loading: false,
  createdNotificationResponse: '',
};

function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        createdNotificationResponse: action.payload,
        loading: false,
      };
    case CREATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default notificationsReducer;
