export const CREATE_NOTIFICATION_REQUEST = 'CREATE_NOTIFICATION_REQUEST';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_FAILURE = 'CREATE_NOTIFICATION_FAILURE';

export function createNotificationRequest() {
  return {
    type: CREATE_NOTIFICATION_REQUEST,
  };
}

export function createNotificationSuccess(data) {
  return {
    type: CREATE_NOTIFICATION_SUCCESS,
    payload: data,
  };
}

export function createNotificationFailure(error) {
  return {
    type: CREATE_NOTIFICATION_FAILURE,
    payload: error,
  };
}
