/* eslint-disable react/no-children-prop */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SteoraBenches from '../../../DeployedProducts/Benches';
import BenchesInfo from '../../../DeployedProducts/Benches/Info';
import LogsInfo from '../../../../components/LogsInfo';
import SensorDataContainer from '../../../../components/Sensors/SensorDataContainer';
import ManageDataContainer from '../../../../components/ManageData/ManageDataContainer';

export default () => (
  <Switch>
    <Route exact path="/benches" component={SteoraBenches} />
    <Route exact path="/benches/:id" component={BenchesInfo} />
    <Route exact path="/benches/logs/:id" render={props => <LogsInfo typeOfProduct="benches" {...props} />} />
    <Route exact path="/benches/sensors/:id" component={SensorDataContainer} />
    <Route exact path="/benches/manage/:id" component={ManageDataContainer} />
  </Switch>
);
