import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import renderTextField from '../../../shared/components/form/Input';
import validate from './AddMaintenanceFormValidator';

import createMaintenance from '../../../api/maintenanceApi';
import { getDeployedProductsList } from '../../../api/productsApi';
import renderSelectField from '../../../shared/components/form/Select';
import renderMultiSelectField from '../../../shared/components/form/MultiSelect';
import fetchCountries from '../../../api/countriesApi';


class MaintenanceAddForm extends PureComponent {
  constructor() {
    super();

    this.state = {
      validationErrors: {
        maintenance_id: '',
        maintenance_name: '',
        country: '',
        products: '',
        contact_person: '',
        contact_details: '',
        under_warranty: '',
        maintenance_description: '',
      },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchCountries());
    dispatch(getDeployedProductsList());
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { eventFormData, dispatch } = this.props;
    const { validationErrors } = this.state;

    const valuesToValidate = {
      maintenance_id: eventFormData.values && eventFormData.values.maintenance_id,
      maintenance_name: eventFormData.values && eventFormData.values.maintenance_name,
      country: eventFormData.values && eventFormData.values.country.value,
      products: eventFormData.values && eventFormData.values.products
      && eventFormData.values.products.map(item => item.value),
      contact_person: eventFormData.values && eventFormData.values.contact_person,
      contact_details: eventFormData.values && eventFormData.values.contact_details,
      under_warranty: eventFormData.values && eventFormData.values.under_warranty,
      maintenance_description: eventFormData.values && eventFormData.values.maintenance_description,
    };
    const errors = validate(valuesToValidate);

    if (isEmpty(errors)) {
      const params = {
        maintenance_id: eventFormData.values.maintenance_id,
        maintenance_name: eventFormData.values.maintenance_name,
        country: eventFormData.values.country.value,
        products: eventFormData.values.products.map(item => item.value),
        contact_person: eventFormData.values.contact_person,
        contact_details: eventFormData.values.contact_details,
        under_warranty: eventFormData.values.under_warranty.value,
        maintenance_description: eventFormData.values.maintenance_description,
      };
      dispatch(createMaintenance(params));
      this.setState({
        validationErrors: {
          maintenance_id: '',
          maintenance_name: '',
          country: '',
          products: '',
          contact_person: '',
          contact_details: '',
          under_warranty: '',
          maintenance_description: '',
        },
      });
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          maintenance_id: errors.maintenance_id,
          maintenance_name: errors.maintenance_name,
          country: errors.country,
          products: errors.products,
          contact_person: errors.contact_person,
          contact_details: errors.contact_details,
          under_warranty: errors.under_warranty,
          maintenance_description: errors.maintenance_description,
        },
      });
    }
  }

  handleCountryChange = () => {
    const { validationErrors } = this.state;
    this.setState({
      validationErrors: {
        ...validationErrors,
        country: '',
      },
    });
  };

  render() {
    const { validationErrors } = this.state;
    const { countriesList, productsList } = this.props;

    return (
      <Col xs={12} md={12} lg={12} xl={6}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Add new maintenance</h5>
            </div>
            <form className="form material-form" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Maintenance ID
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="maintenance_id"
                    component={renderTextField}
                    type="text"
                    placeholder="e.g. 1"
                  />
                </div>
                { validationErrors.maintenance_id
                && <span className="form__form-group-error"> {validationErrors.maintenance_id} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Maintenance name
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="maintenance_name"
                    component={renderTextField}
                    type="text"
                    placeholder="Name"
                  />
                </div>
                { validationErrors.maintenance_name
                && <span className="form__form-group-error"> {validationErrors.maintenance_name} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Country
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="country"
                    component={renderSelectField}
                    options={countriesList}
                    type="text"
                  />
                </div>
                { validationErrors.country
                && <span className="form__form-group-error"> {validationErrors.country} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Products
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="products"
                    component={renderMultiSelectField}
                    options={productsList}
                    type="select"
                    placeholder="e.g. 1"
                  />
                </div>
                { validationErrors.products
                && <span className="form__form-group-error"> {validationErrors.products} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Contact person
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="contact_person"
                    component={renderTextField}
                    type="text"
                  />
                </div>
                { validationErrors.contact_person
                && <span className="form__form-group-error"> {validationErrors.contact_person} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Contact details
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="contact_details"
                    component={renderTextField}
                    type="text"
                  />
                </div>
                { validationErrors.contact_details
                && <span className="form__form-group-error"> {validationErrors.contact_details} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Under Warranty
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="under_warranty"
                    component={renderSelectField}
                    type="text"
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]}
                  />
                </div>
                { validationErrors.under_warranty
                && <span className="form__form-group-error"> {validationErrors.under_warranty} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Maintenance description
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="maintenance_description"
                    component={renderTextField}
                    type="text"
                  />
                </div>
                { validationErrors.maintenance_description
                && <span className="form__form-group-error"> {validationErrors.maintenance_description} </span>}
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

MaintenanceAddForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  eventFormData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool,
  ])),
  countriesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  productsList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MaintenanceAddForm.defaultProps = {
  eventFormData: {},
};

const mapStateToProps = state => ({
  rtl: state.rtl,
  eventFormData: state.form.maintenanceAddForm,
  countriesList: state.countries.list,
  productsList: state.products.productsList,
});

const NewMaintenanceAddForm = reduxForm({
  form: 'maintenanceAddForm',
})(MaintenanceAddForm);

export default connect(mapStateToProps)(NewMaintenanceAddForm);
