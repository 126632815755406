/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-constant-condition */
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
// Load Highcharts modules for export options of each graph
import Exporting from 'highcharts/modules/exporting';

import SensorInfoAggregates from './AirQualityIndexAggregates';

Exporting(Highcharts);

const convertDegreesToWindDirection = degrees => Math.round(degrees / 22.5);
const dir = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW', 'N'];

const SensorInfo = ({ sensorData }) => {
  const categories = [];
  const xAsisTitle = sensorData.showDate ? 'Date and time period' : 'Time period';

  if (sensorData.data.values.length > 0) {
    sensorData.data.values.forEach((item) => {
      if (sensorData.showDate) categories.push(`${item.date} at ${item.time}`);
      else categories.push(item.time);
    });
  }

  const valuesToSend = [];
  let colorToDisplay = '';
  let valueForYaxsis = 0;
  sensorData.data.values.forEach((item) => {
    colorToDisplay = '#FF5901';
    valueForYaxsis = convertDegreesToWindDirection(item.value);
    valuesToSend.push({
      y: valueForYaxsis === 0 ? 16 : valueForYaxsis,
      color: colorToDisplay,
      labelToShow: `${dir[valueForYaxsis]} for ${item.date} at ${item.time}`,
    });
  });

  if (valuesToSend.length > 1000) {
    for (let i = 0; i < valuesToSend.length; i += 1) {
      valuesToSend.splice(i + 1, 2);
      categories.splice(i + 1, 2);
    }
  } else if (valuesToSend.length > 2000) {
    for (let i = 0; i < valuesToSend.length; i += 1) {
      valuesToSend.splice(i + 1, 3);
      categories.splice(i + 1, 3);
    }
  } else if (valuesToSend.length > 3000) {
    for (let i = 0; i < valuesToSend.length; i += 1) {
      valuesToSend.splice(i + 1, 4);
      categories.splice(i + 1, 4);
    }
  } else if (valuesToSend.length > 4000) {
    for (let i = 0; i < valuesToSend.length; i += 1) {
      valuesToSend.splice(i + 1, 30);
      categories.splice(i + 1, 30);
    }
  }

  if (categories.length !== valuesToSend.length) categories.splice(valuesToSend.length, categories.length);

  const chartOptions = {
    chart: {
      type: 'scatter',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories,
      labels: {
        format: '{value}',
      },
      min: 0,
      max: categories.length - 1,
      tickInterval: 5,
      title: {
        text: xAsisTitle,
      },
    },
    yAxis: {
      title: {
        text: 'Wind direction',
      },
      lineWidth: 1,
      labels: {
        formatter() {
          if (this.value === 0) return 'N';
          if (this.value === 1) return 'NNE';
          if (this.value === 2) return 'NE';
          if (this.value === 3) return 'ENE';
          if (this.value === 4) return 'E';
          if (this.value === 5) return 'ESE';
          if (this.value === 6) return 'SE';
          if (this.value === 7) return 'SSE';
          if (this.value === 8) return 'S';
          if (this.value === 9) return 'SSW';
          if (this.value === 10) return 'SW';
          if (this.value === 11) return 'WSW';
          if (this.value === 12) return 'W';
          if (this.value === 13) return 'WNW';
          if (this.value === 14) return 'NW';
          if (this.value === 15) return 'NNW';
          if (this.value === 16) return 'N';
          return 'No direction';
        },
      },
      tickInterval: 1,
      min: 1,
      max: 16,
    },
    tooltip: {
      pointFormat: '<span style="color:{point.color}">{point.labelToShow}</span>',
    },
    series: [
      {
        name: 'Wind direction',
        data: valuesToSend,
        color: '#FF5901',
        maxPointWidth: 30,
      },
    ],

  };

  return (
    <div className="main-chart-wrapper">
      <div className="chart-title">Wind Direction
        {sensorData.multiDeviceSelected && (
          <p>{sensorData.deviceId}</p>
        )}
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ className: 'chart-wrapper' }}
        key={sensorData.length}
      />
      <div className="chart-info">
        {!isEmpty(sensorData.data.aggregates) ? (
          <SensorInfoAggregates sensorData={sensorData} />
        ) : (
          <div>
            <p>Not enough data to display aggregates.</p>
          </div>
        ) }
      </div>
    </div>
  );
};

SensorInfo.propTypes = {
  sensorData: PropTypes.oneOfType([
    PropTypes.object, PropTypes.array,
  ]).isRequired,
};

export default SensorInfo;
