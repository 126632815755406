/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Loading from '../../../../shared/components/Loading';
import ActiveMaintenanceSparePartsList from
  '../../../../components/ProductDisplayData/components/ActiveMaintenanceSparePartsList';
import {
  getAllSpareParts, deleteSparePart,
} from '../../../../api/maintenanceApi';

class AllSpareParts extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllSpareParts());
  }

  /* reRenderPage = () => {
    const { dispatch } = this.props;
    dispatch(fetchDeployedProductsByTypeMaintenance());
  } */

  handleDelete =(id) => {
    const { dispatch } = this.props;

    dispatch(deleteSparePart(id));
    dispatch(getAllSpareParts());
  }

  render() {
    const { loading, spareParts } = this.props;

    return (
      <Container>
        <Row>
          <Col xs={10}>
            <h3 className="page-title">Spare Parts</h3>
          </Col>
          <Col xs={2}>
            <Link
              className="btn btn-primary account__btn sensor-btn-display"
              to="/maintenance/spare-parts/add-new"
            >Add new
            </Link>
          </Col>
        </Row>
        <Row>
          {loading ? (
            <Loading loading={loading} />
          )
            : spareParts.length > 0 ? (
              <ActiveMaintenanceSparePartsList
                key={spareParts.length}
                sparePartsList={spareParts}
                typeOfProduct="waste-containers"
                handleDelete={this.handleDelete}
              />
            )
              : (
                <Col>
                  <Card>
                    <CardBody>
                      <div>No active spare parts found</div>
                    </CardBody>
                  </Card>
                </Col>
              )
          }
        </Row>
      </Container>
    );
  }
}

AllSpareParts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  spareParts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  spareParts: state.maintenance.spareParts,
  loading: state.maintenance.loading,
});

export default connect(mapStateToProps)(AllSpareParts);
