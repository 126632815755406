import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';

class TableHeader extends PureComponent {
  render() {
    return (
      <Container className="table-header">
        <Row>
          <Col md={1}>
            <h5 className="table-item">Id</h5>
          </Col>
          <Col md={2}>
            <h5 className="table-item">Admin</h5>
          </Col>
          <Col md={2}>
            <h5 className="table-item">Naziv kompanije</h5>
          </Col>
          <Col md={2}>
            <h5 className="table-item">Adresa</h5>
          </Col>
          <Col md={2}>
            <h5 className="table-item">OIB</h5>
          </Col>
          <Col md={1}>
            <h5 className="table-item">Grad</h5>
          </Col>
          <Col md={1}>
            <h5 className="table-item">Država</h5>
          </Col>
        </Row>
        <hr />
      </Container>
    );
  }
}

export default TableHeader;
