import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import validate from './eraseValidator';

class EraseForm extends Component {
  constructor() {
    super();

    this.state = {
      validationErrors: { productId: '' },
      productId: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { validationErrors, productId } = this.state;
    const { handleConfirm } = this.props;
    const valuesToValidate = { productId };

    const errors = validate(valuesToValidate);
    if (isEmpty(errors)) {
      handleConfirm(productId);
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          productId: errors.productId,
        },
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { validationErrors } = this.state;

    // clear validation error when we start typing
    this.setState({
      [name]: value.toUpperCase(),
      validationErrors: {
        ...validationErrors,
        [name]: '',
      },
    });
  }

  render() {
    const { validationErrors, productId } = this.state;
    return (
      <form className="erase-form" onSubmit={this.handleSubmit}>
        <div className="input-field">
          <span className="input-label">Product ID: </span>
          <Input type="text" onChange={this.handleChange} name="productId" id="productId" value={productId} />
          { validationErrors.productId
            && <span className="input-error"> {validationErrors.productId} </span>}

        </div>
        <div className="save-btn">
          <Button
            padding="2px 20px"
            onClick={this.handleSubmit}
          > Remove product
          </Button>
        </div>
      </form>
    );
  }
}

EraseForm.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
};

export default EraseForm;
