import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, connect } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../shared/img/closeIcon.svg';
import { getSparePartByProductId, updatePartsMaintenanceById } from '../../api/maintenanceApi';


const ChangePartsModal = ({
  modalOpen, onCloseClick, message, spareParts,
  maintenanceId, partsInitialChecked, data,
}) => {
  const dispatch = useDispatch();

  const selectedInfo = partsInitialChecked && data.filter(item => item.id === partsInitialChecked.id);
  const deployedProductId = selectedInfo && selectedInfo[0] && selectedInfo[0].id;
  const productTypeId = selectedInfo && selectedInfo[0] && selectedInfo[0].product_type
  && selectedInfo[0].product_type.id;

  const initialSelectedParts = selectedInfo && selectedInfo[0] && selectedInfo[0].changed_parts;
  const idsChecked = initialSelectedParts && initialSelectedParts.map(item => item.id);

  const [partsChecked, setPartsChecked] = useState([]);

  const handleClosing = () => {
    onCloseClick(false);
  };

  const handleClick = (value) => {
    if (partsChecked && partsChecked.includes(value)) {
      setPartsChecked(partsChecked.filter(id => id !== value));
    } else {
      setPartsChecked([...new Set([...partsChecked, value])]);
    }
  };

  const handleSave = () => {
    dispatch(updatePartsMaintenanceById(maintenanceId, deployedProductId, partsChecked));
    onCloseClick(false);
  };

  useEffect(() => {
    if (productTypeId) dispatch(getSparePartByProductId(productTypeId));
    setPartsChecked(idsChecked);
  }, [dispatch, productTypeId]);

  return (
    <Modal isOpen={modalOpen} className="custom__modal-container confirm-modal">
      <div className="custom__modal-header">
        <button
          className="close-btn"
          type="button"
          onClick={handleClosing}
        > <CloseIcon alt="close" />
        </button>
      </div>
      <div className="modal__body">
        <h3 className=" modal__title">Add changed parts</h3>
        <p>{message}</p>
      </div>
      <div style={{
        textAlign: 'left',
        paddingLeft: '50px',
        height: '200px',
        overflowX: 'hidden',
        overflowY: 'scroll',
      }}
      >
        {spareParts.length > 0 && spareParts.map(item => (
          <div key={item.id}><span>{item.name}</span><Checkbox
            checked={partsChecked && partsChecked.includes(item.id)}
            onChange={() => handleClick(item.id)}
          />
          </div>
        ))}

      </div>
      <div className="modal__btn">
        <Button type="button" text="Cancel" padding="5px 30px" onClick={handleClosing}>
          No
        </Button>
        <Button type="button" text="Confirm" padding="5px 30px" onClick={handleSave}> Yes </Button>
      </div>
    </Modal>
  );
};
ChangePartsModal.propTypes = {
  message: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  spareParts: PropTypes.arrayOf(PropTypes.object).isRequired,
  maintenanceId: PropTypes.number.isRequired,
  partsInitialChecked: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ChangePartsModal.defaultProps = {
  message: 'Mark all change parts.',
};

const mapStateToProps = state => ({
  spareParts: state.maintenance.sparePartsByProductId,
});

export default connect(mapStateToProps)(ChangePartsModal);
