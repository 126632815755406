export const CREATE_MAINTENANCE_REQUEST = 'CREATE_MAINTENANCE_REQUEST';
export const CREATE_MAINTENANCE_SUCCESS = 'CREATE_MAINTENANCE_SUCCESS';
export const CREATE_MAINTENANCE_FAILURE = 'CREATE_MAINTENANCE_FAILURE';
export const GET_MAINTENANCE_REQUEST = 'GET_MAINTENANCE_REQUEST';
export const GET_MAINTENANCE_SUCCESS = 'GET_MAINTENANCE_SUCCESS';
export const GET_MAINTENANCE_FAILURE = 'GET_MAINTENANCE_FAILURE';

export const GET_MAINTENANCE_BY_ID_REQUEST = 'GET_MAINTENANCE_BY_ID_REQUEST';
export const GET_MAINTENANCE_BY_ID_SUCCESS = 'GET_MAINTENANCE_BY_ID_SUCCESS';
export const GET_MAINTENANCE_BY_ID_FAILURE = 'GET_MAINTENANCE_BY_ID_FAILURE';
export const CLOSE_MAINTENANCE_BY_ID_REQUEST = 'CLOSE_MAINTENANCE_BY_ID_REQUEST';
export const CLOSE_MAINTENANCE_BY_ID_SUCCESS = 'CLOSE_MAINTENANCE_BY_ID_SUCCESS';
export const CLOSE_MAINTENANCE_BY_ID_FAILURE = 'CLOSE_MAINTENANCE_BY_ID_FAILURE';
export const CHANGED_PARTS_MAINTENANCE_BY_ID_REQUEST = 'CHANGED_PARTS_MAINTENANCE_BY_ID_REQUEST';
export const CHANGED_PARTS_MAINTENANCE_BY_ID_SUCCESS = 'CHANGED_PARTS_MAINTENANCE_BY_ID_SUCCESS';
export const CHANGED_PARTS_MAINTENANCE_BY_ID_FAILURE = 'CHANGED_PARTS_MAINTENANCE_BY_ID_FAILURE';


export const UPDATE_NAME_DATE_MAINTENANCE_BY_ID_REQUEST = 'UPDATE_NAME_DATE_MAINTENANCE_BY_ID_REQUEST';
export const UPDATE_NAME_DATE_MAINTENANCE_BY_ID_SUCCESS = 'UPDATE_NAME_DATE_MAINTENANCE_BY_ID_SUCCESS';
export const UPDATE_NAME_DATE_MAINTENANCE_BY_ID_FAILURE = 'UPDATE_NAME_DATE_MAINTENANCE_BY_ID_FAILURE';

export const GET_ALL_SPARE_PARTS_REQUEST = 'GET_ALL_SPARE_PARTS_REQUEST';
export const GET_ALL_SPARE_PARTS_SUCCESS = 'GET_ALL_SPARE_PARTS_SUCCESS';
export const GET_ALL_SPARE_PARTS_FAILURE = 'GET_ALL_SPARE_PARTS_FAILURE';

export const CREATE_NEW_SPARE_PART_REQUEST = 'CREATE_NEW_SPARE_PART_REQUEST';
export const CREATE_NEW_SPARE_PART_SUCCESS = 'CREATE_NEW_SPARE_PART_SUCCESS';
export const CREATE_NEW_SPARE_PART_FAILURE = 'CREATE_NEW_SPARE_PART_FAILURE';

export const DELETE_SPARE_PART_REQUEST = 'DELETE_SPARE_PART_REQUEST';
export const DELETE_SPARE_PART_SUCCESS = 'DELETE_SPARE_PART_SUCCESS';
export const DELETE_SPARE_PART_FAILURE = 'DELETE_SPARE_PART_FAILURE';

export const GET_SPARE_PART_BY_PRODUCT_ID_REQUEST = 'GET_SPARE_PART_BY_PRODUCT_ID_REQUEST';
export const GET_SPARE_PART_BY_PRODUCT_ID_SUCCESS = 'GET_SPARE_PART_BY_PRODUCT_ID_SUCCESS';
export const GET_SPARE_PART_BY_PRODUCT_ID_FAILURE = 'GET_SPARE_PART_BY_PRODUCT_ID_FAILURE';

export function createMaintenanceRequest() {
  return {
    type: CREATE_MAINTENANCE_REQUEST,
  };
}

export function createMaintenanceSuccess(data) {
  return {
    type: CREATE_MAINTENANCE_SUCCESS,
    payload: data,
  };
}

export function createMaintenanceFailure(error) {
  return {
    type: CREATE_MAINTENANCE_FAILURE,
    payload: error,
  };
}

export function getMaintenanceHistoryRequest() {
  return {
    type: GET_MAINTENANCE_REQUEST,
  };
}

export function getMaintenanceHistorySuccess(data) {
  return {
    type: GET_MAINTENANCE_SUCCESS,
    payload: data,
  };
}

export function getMaintenanceHistoryFailure(error) {
  return {
    type: GET_MAINTENANCE_FAILURE,
    payload: error,
  };
}

export function getMaintenanceByIdRequest() {
  return {
    type: GET_MAINTENANCE_BY_ID_REQUEST,
  };
}

export function getMaintenanceByIdSuccess(data) {
  return {
    type: GET_MAINTENANCE_BY_ID_SUCCESS,
    payload: data,
  };
}

export function getMaintenanceByIdFailure(error) {
  return {
    type: GET_MAINTENANCE_BY_ID_FAILURE,
    payload: error,
  };
}

export function closeMaintenanceByIdRequest() {
  return {
    type: CLOSE_MAINTENANCE_BY_ID_REQUEST,
  };
}

export function closeMaintenanceByIdSuccess(data) {
  return {
    type: CLOSE_MAINTENANCE_BY_ID_SUCCESS,
    payload: data,
  };
}

export function closeMaintenanceByIdFailure(error) {
  return {
    type: CLOSE_MAINTENANCE_BY_ID_FAILURE,
    payload: error,
  };
}

export function updatePartsMaintenanceByIdRequest() {
  return {
    type: CHANGED_PARTS_MAINTENANCE_BY_ID_REQUEST,
  };
}

export function updatePartsMaintenanceByIdSuccess(data) {
  return {
    type: CHANGED_PARTS_MAINTENANCE_BY_ID_SUCCESS,
    payload: data,
  };
}

export function updatePartsMaintenanceByIdFailure(error) {
  return {
    type: CHANGED_PARTS_MAINTENANCE_BY_ID_FAILURE,
    payload: error,
  };
}

export function updateNameOrDateMaintenanceByIdRequest() {
  return {
    type: UPDATE_NAME_DATE_MAINTENANCE_BY_ID_REQUEST,
  };
}

export function updateNameOrDateMaintenanceByIdSuccess(data) {
  return {
    type: UPDATE_NAME_DATE_MAINTENANCE_BY_ID_SUCCESS,
    payload: data,
  };
}

export function updateNameOrDateMaintenanceByIdFailure(error) {
  return {
    type: UPDATE_NAME_DATE_MAINTENANCE_BY_ID_FAILURE,
    payload: error,
  };
}

export function getAllSparePartsRequest() {
  return {
    type: GET_ALL_SPARE_PARTS_REQUEST,
  };
}

export function getAllSparePartsSuccess(data) {
  return {
    type: GET_ALL_SPARE_PARTS_SUCCESS,
    payload: data,
  };
}

export function getAllSparePartsFailure(error) {
  return {
    type: GET_ALL_SPARE_PARTS_FAILURE,
    payload: error,
  };
}

export function createNewSparePartRequest() {
  return {
    type: CREATE_NEW_SPARE_PART_REQUEST,
  };
}

export function createNewSparePartSuccess(data) {
  return {
    type: CREATE_NEW_SPARE_PART_SUCCESS,
    payload: data,
  };
}

export function createNewSparePartFailure(error) {
  return {
    type: CREATE_NEW_SPARE_PART_FAILURE,
    payload: error,
  };
}

export function deleteSparePartByIdRequest() {
  return {
    type: DELETE_SPARE_PART_REQUEST,
  };
}

export function deleteSparePartByIdSuccess(data) {
  return {
    type: DELETE_SPARE_PART_SUCCESS,
    payload: data,
  };
}

export function deleteSparePartByIdFailure(error) {
  return {
    type: DELETE_SPARE_PART_FAILURE,
    payload: error,
  };
}

export function getSparePartByProductRequest() {
  return {
    type: GET_SPARE_PART_BY_PRODUCT_ID_REQUEST,
  };
}

export function getSparePartByProductSuccess(data) {
  return {
    type: GET_SPARE_PART_BY_PRODUCT_ID_SUCCESS,
    payload: data,
  };
}

export function getSparePartByProductFailure(error) {
  return {
    type: GET_SPARE_PART_BY_PRODUCT_ID_FAILURE,
    payload: error,
  };
}
