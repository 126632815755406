import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const SensorInfoAggregates = ({ sensorData }) => (
  <div>
    {sensorData.has_total ? (
      <div>
        <span className="label long-label">Total <span>{sensorData.name}</span></span>
        <span className="value">{sensorData.data.aggregates.sum ? sensorData.data.aggregates.sum : 'No data'}
          &nbsp;
          {sensorData.sensor_group_slug === 'energy-produced' && sensorData.data.aggregates.sum ? sensorData.unit : ''}
        </span>
      </div>
    ) : (
      <>
        {sensorData.data.aggregates.min ? (
          <div>
            <span className="label">Minimum <span>{sensorData.name}</span></span>
            <span className="value">{sensorData.data.aggregates.min} {sensorData.unit}</span>
            <span className="date">{moment.unix(sensorData.data.aggregates.dateMinValue.timestamp)
              .format('DD.MM.YYYY HH:mm')}
            </span>
          </div>
        ) : (
          <div>
            <span className="label">Minimum <span>{sensorData.name}</span></span>
            <span className="value">No data</span>
          </div>
        )}
        {sensorData.data.aggregates.max ? (
          <div>
            <span className="label">Maximum <span>{sensorData.name}</span></span>
            <span className="value">{sensorData.data.aggregates.max} {sensorData.unit}</span>
            <span className="date">{moment.unix(sensorData.data.aggregates.dateMaxValue.timestamp)
              .format('DD.MM.YYYY HH:mm')}
            </span>
          </div>
        ) : (
          <div>
            <span className="label">Maximum <span>{sensorData.name}</span></span>
            <span className="value">No data</span>
          </div>
        )}
        {sensorData.data.aggregates.avg ? (
          <div>
            <span className="label">Average <span>{sensorData.name}</span></span>
            <span className="value">{sensorData.data.aggregates.avg} {sensorData.unit}</span>
          </div>
        ) : (
          <div>
            <span className="label">Average <span>{sensorData.name}</span></span>
            <span className="value">No data</span>
          </div>
        )}
      </>
    )}
  </div>
);

SensorInfoAggregates.propTypes = {
  sensorData: PropTypes.oneOfType([
    PropTypes.object, PropTypes.array,
  ]).isRequired,
};

export default SensorInfoAggregates;
