import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import renderMultiSelectField from '../../../shared/components/form/MultiSelect';


import renderTextField from '../../../shared/components/form/Input';
import validate from './AddMaintenanceSparePartFormValidator';

import { createSparePart } from '../../../api/maintenanceApi';

class MaintenanceSparePartAddForm extends PureComponent {
  constructor() {
    super();

    this.state = {
      validationErrors: {
        pantheon_id: '',
        spare_part_name: '',
        product_types: [],
      },
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { eventFormData, dispatch } = this.props;
    const { validationErrors } = this.state;

    const valuesToValidate = {
      pantheon_id: eventFormData.values && eventFormData.values.pantheon_id,
      spare_part_name: eventFormData.values && eventFormData.values.spare_part_name,
      product_types: eventFormData.values && eventFormData.values.product_types
      && eventFormData.values.product_types.map(item => item.value),

    };
    const errors = validate(valuesToValidate);
    if (isEmpty(errors)) {
      const params = {
        pantheon_id: eventFormData.values.pantheon_id,
        spare_part_name: eventFormData.values.spare_part_name,
        product_types: eventFormData.values.product_types.map(item => item.value),
      };
      dispatch(createSparePart(params));
      this.setState({
        validationErrors: {
          pantheon_id: '',
          spare_part_name: '',
          product_types: '',

        },
      });
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          pantheon_id: errors.pantheon_id,
          spare_part_name: errors.spare_part_name,
          product_types: errors.product_types,
        },
      });
    }
  }

  render() {
    const { validationErrors } = this.state;

    return (
      <Col xs={12} md={12} lg={12} xl={6}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Add new spare part</h5>
            </div>
            <form className="form material-form" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Pantheon ID
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="pantheon_id"
                    component={renderTextField}
                    type="text"
                    placeholder="e.g. 1"
                  />
                </div>
                { validationErrors.pantheon_id
                && <span className="form__form-group-error"> {validationErrors.pantheon_id} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Spare part name
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="spare_part_name"
                    component={renderTextField}
                    type="text"
                    placeholder="Name"
                  />
                </div>
                { validationErrors.spare_part_name
                && <span className="form__form-group-error"> {validationErrors.spare_part_name} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Product type
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="product_types"
                    component={renderMultiSelectField}
                    type="select"
                    options={[
                      { value: 1, label: 'Bench' },
                      { value: 2, label: 'Waste' },
                      { value: 3, label: 'Aerys' },
                    ]}
                  />
                </div>
                { validationErrors.product_types
                && <span className="form__form-group-error"> {validationErrors.product_types} </span>}
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

MaintenanceSparePartAddForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  eventFormData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool,
  ])),
};

MaintenanceSparePartAddForm.defaultProps = {
  eventFormData: {},
};

const mapStateToProps = state => ({
  rtl: state.rtl,
  eventFormData: state.form.maintenanceSparePartAddForm,
});

const NewMaintenanceSparePartAddForm = reduxForm({
  form: 'maintenanceSparePartAddForm',
})(MaintenanceSparePartAddForm);

export default connect(mapStateToProps)(NewMaintenanceSparePartAddForm);
