export const FETCH_HR_INFO_SUCCESS = 'FETCH_HR_INFO_SUCCESS';
export const FETCH_HR_SURVEY_SUCCESS = 'FETCH_HR_SURVEY_SUCCESS';
export const FETCH_HR_EMPLOYEES_SUCCESS = 'FETCH_HR_EMPLOYEES_SUCCESS';
export const FETCH_DEVELOPMENT_INFO_SUCCESS = 'FETCH_DEVELOPMENT_INFO_SUCCESS';
export const FETCH_TRAVELING_INFO_SUCCESS = 'FETCH_TRAVELING_INFO_SUCCESS';
export const FETCH_ABOUT_US_INFO_SUCCESS = 'FETCH_ABOUT_US_INFO_SUCCESS';
export const FETCH_JOB_INFO_SUCCESS = 'FETCH_JOB_INFO_SUCCESS';
export const FETCH_CHART_MONTHLY_SALES_SUCCESS = 'FETCH_CHART_MONTHLY_SALES_SUCCESS';
export const FETCH_CHART_ANUALLY_SALES_SUCCESS = 'FETCH_CHART_ANUALLY_SALES_SUCCESS';
export const FETCH_CHART_ANUALLY_MARKETS_SUCCESS = 'FETCH_CHART_ANUALLY_MARKETS_SUCCESS';
export const FETCH_CHART_ANUALLY_EMPLOYEES_SUCCESS = 'FETCH_CHART_ANUALLY_EMPLOYEES_SUCCESS';
export const FETCH_CHART_ANUALLY_PROFIT_SUCCESS = 'FETCH_CHART_ANUALLY_PROFIT_SUCCESS';
export const FETCH_SINGLE_SETTINGS_DATA_REQUEST = 'FETCH_SINGLE_SETTINGS_DATA_REQUEST';
export const FETCH_SINGLE_SETTINGS_DATA_FAILURE = 'FETCH_SINGLE_SETTINGS_DATA_FAILURE';
export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';

export function fetchHRInfoSuccess(data) {
  return {
    type: FETCH_HR_INFO_SUCCESS,
    payload: data,
  };
}

export function fetchHRSurveySuccess(data) {
  return {
    type: FETCH_HR_SURVEY_SUCCESS,
    payload: data,
  };
}
export function fetchHREmployeesSuccess(data) {
  return {
    type: FETCH_HR_EMPLOYEES_SUCCESS,
    payload: data,
  };
}

export function fetchDevelopmentInfoSuccess(data) {
  return {
    type: FETCH_DEVELOPMENT_INFO_SUCCESS,
    payload: data,
  };
}

export function fetchTravelingInfoSuccess(data) {
  return {
    type: FETCH_TRAVELING_INFO_SUCCESS,
    payload: data,
  };
}

export function fetchAboutUsInfoSuccess(data) {
  return {
    type: FETCH_ABOUT_US_INFO_SUCCESS,
    payload: data,
  };
}

export function fetchJobInfoSuccess(data) {
  return {
    type: FETCH_JOB_INFO_SUCCESS,
    payload: data,
  };
}

export function fetchChartMonthSalesSuccess(data) {
  return {
    type: FETCH_CHART_MONTHLY_SALES_SUCCESS,
    payload: data,
  };
}

export function fetchChartYearSalesSuccess(data) {
  return {
    type: FETCH_CHART_ANUALLY_SALES_SUCCESS,
    payload: data,
  };
}

export function fetchChartYearMarketsSuccess(data) {
  return {
    type: FETCH_CHART_ANUALLY_MARKETS_SUCCESS,
    payload: data,
  };
}

export function fetchChartYearEmployeesSuccess(data) {
  return {
    type: FETCH_CHART_ANUALLY_EMPLOYEES_SUCCESS,
    payload: data,
  };
}

export function fetchChartYearProfitSuccess(data) {
  return {
    type: FETCH_CHART_ANUALLY_PROFIT_SUCCESS,
    payload: data,
  };
}

export function updateSettingsRequest() {
  return {
    type: UPDATE_SETTINGS_REQUEST,
  };
}

export function updateSettingsSuccess(data) {
  return {
    type: UPDATE_SETTINGS_SUCCESS,
    payload: data,
  };
}

export function updateSettingsFailure(error) {
  return {
    type: UPDATE_SETTINGS_FAILURE,
    payload: error,
  };
}

export function fetchSingleSettingsDataRequest() {
  return {
    type: FETCH_SINGLE_SETTINGS_DATA_REQUEST,
  };
}

export function fetchSingleSettingsDataFailure(error) {
  return {
    type: FETCH_SINGLE_SETTINGS_DATA_FAILURE,
    payload: error,
  };
}
