import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CompaniesList from './components/CompaniesList';
import { fetchAllCompanies } from '../../api/companiesApi';

class Customers extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAllCompanies());
  }

  render() {
    const { t, allCompaniesList } = this.props;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">All Solos companies</h3>
          </Col>
        </Row>
        <Row>
          {allCompaniesList.length > 0
          && (
          <CompaniesList
            t={t}
            products={allCompaniesList}
          />
          )}
        </Row>
      </Container>
    );
  }
}

Customers.propTypes = {
  t: PropTypes.func.isRequired,
  allCompaniesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  allCompaniesList: state.companies.allCompaniesList,
  themeName: state.theme.className,
});


export default compose(withTranslation('common'), connect(
  (mapStateToProps),
))(Customers);
