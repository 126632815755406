import {
  FETCH_AIR_SENSOR_TYPES_REQUEST,
  FETCH_AIR_SENSOR_TYPES_SUCCESS,
  FETCH_AIR_SENSOR_TYPES_FAILURE,
  CREATE_AIR_SENSOR_REQUEST,
  CREATE_AIR_SENSOR_SUCCESS,
  CREATE_AIR_SENSOR_FAILURE,
  FETCH_SINGLE_SENSOR_REQUEST,
  FETCH_SINGLE_SENSOR_SUCCESS,
  FETCH_SINGLE_SENSOR_FAILURE,
  UPDATE_AIR_SENSOR_REQUEST,
  UPDATE_AIR_SENSOR_SUCCESS,
  UPDATE_AIR_SENSOR_FAILURE,
  SELECTED_AIR_SENSOR_BOARD,
} from '../actions/airSensorActions';

const initialState = {
  error: null,
  loading: false,
  airSensorTypes: [],
  airSensorAlgorithms: [
    {
      value: 1,
      label: 'Suggested',
    },
    {
      value: 0,
      label: 'Alternative',
    },
  ],
  lastAirSensorCreated: {},
  singleAirSensorData: [],
  dataForUpdateForm: {},
  updatedAirSensor: '',
};

function airSensorReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AIR_SENSOR_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AIR_SENSOR_TYPES_SUCCESS:
      return {
        ...state,
        airSensorTypes: action.payload,
        loading: false,
      };
    case FETCH_AIR_SENSOR_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_AIR_SENSOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_AIR_SENSOR_SUCCESS:
      return {
        ...state,
        lastAirSensorCreated: action.payload,
        loading: false,
      };
    case CREATE_AIR_SENSOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECTED_AIR_SENSOR_BOARD:
      return {
        ...state,
        loading: true,
        dataForUpdateForm: action.payload,
      };
    case FETCH_SINGLE_SENSOR_REQUEST:
      return {
        ...state,
        loading: true,
        singleAirSensorData: [],
        lastAirSensorCreated: '',
      };
    case FETCH_SINGLE_SENSOR_SUCCESS:
      return {
        ...state,
        singleAirSensorData: action.payload,
        loading: false,
      };
    case FETCH_SINGLE_SENSOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_AIR_SENSOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_AIR_SENSOR_SUCCESS:
      return {
        ...state,
        updatedAirSensor: action.payload,
        loading: false,
      };
    case UPDATE_AIR_SENSOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default airSensorReducer;
