import {
  updateGeoDataRequest,
  updateGeoDataSuccess,
  updateGeoDataFailure,
  fetchCurrentConfigRequest,
  fetchWifiConfigSuccess,
  fetchLEDConfigSuccess,
  fetchHeatingConfigSuccess,
  fetchTerraConfigSuccess,
  fetchCurrentConfigFailure,
  fetchPowerButtonConfigSuccess,
  setWifiHomepageRequest,
  setWifiHomepageSuccess,
  setWifiHomepageFailure,
  requestGeoLocatingRequest,
  requestGeoLocatingFailure,
  requestGeoLocatingSuccess,
  updatePowerStateRequest,
  updatePowerStateSuccess,
  updatePowerStateFailure,
  setNewConfigRequest,
  setNewWifiConfigSuccess,
  setNewConfigFailure,
  setNewLEDConfigSuccess,
  setNewHeatingConfigSuccess,
  setNewTerraConfigSuccess,
  extendSubscriptionRequest,
  extendSubscriptionSuccess,
  extendSubscriptionFailure,
} from '../redux/actions/manageAppActions';


import authHeader from './authHeader';

export function updateGeoData(deviceID, params) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      device_id: deviceID,
      longitude: params.longitude,
      lattitude: params.lattitude,
      location: params.location,
    }),
  };
  return (dispatch) => {
    dispatch(updateGeoDataRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/update/geodata`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(updateGeoDataSuccess(res.success));
      })
      .catch(error => dispatch(updateGeoDataFailure(error)));
  };
}

export function requestGeoLocating(deviceID) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(requestGeoLocatingRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/request_geo/${deviceID}`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(requestGeoLocatingSuccess(res));
      })
      .catch(error => dispatch(requestGeoLocatingFailure(error)));
  };
}

export function fetchCurrentConfig(selectedProducts, moduleId) {
  const singleOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(fetchCurrentConfigRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/config/${selectedProducts[0]}/${moduleId}`, singleOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        // heating-surface
        if (moduleId === 1) {
          dispatch(fetchHeatingConfigSuccess(res));
        }

        // wifi
        if (moduleId === 2) {
          dispatch(fetchWifiConfigSuccess(res));
        }
        // power-button
        if (moduleId === 24) {
          dispatch(fetchPowerButtonConfigSuccess(res));
        }
        // terra
        if (moduleId === 48) {
          dispatch(fetchTerraConfigSuccess(res));
        }
      })
      .catch(error => dispatch(fetchCurrentConfigFailure(error)));
  };
}

export function fetchLedCurrentConfig(selectedProducts) {
  const singleOptions = {
    method: 'GET',
    headers: authHeader(),
  };


  return (dispatch) => {
    dispatch(fetchCurrentConfigRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/config/periodic/${selectedProducts[0]}/8`, singleOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(fetchLEDConfigSuccess(res));
      })
      .catch(error => dispatch(fetchCurrentConfigFailure(error)));
  };
}

export function setWifiHomepage(deviceID, homepage) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      selected_product: deviceID,
      homepage,
    }),
  };

  return (dispatch) => {
    dispatch(setWifiHomepageRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/wifi/landing-page`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(setWifiHomepageSuccess(res));
      })
      .catch(error => dispatch(setWifiHomepageFailure(error)));
  };
}

export function updatePowerState(params) {
  const selectedProducts = [{
    id: params.id,
    config: [
      {
        var: 'M',
        value: params.value,
      },
    ],
  }];

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      selected_products: selectedProducts,
    }),
  };

  return (dispatch) => {
    dispatch(updatePowerStateRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/config/power`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(updatePowerStateSuccess(res.success));
      })
      .catch(error => dispatch(updatePowerStateFailure(error)));
  };
}

export function setNewWifiConfig(selectedProducts, variableToUpdate, params) {
  let configVariables = [];

  if (variableToUpdate === 'ssid') {
    configVariables = [{
      var: 'S',
      value: params,
    }];
  }

  if (variableToUpdate === 'schedule') {
    configVariables = [{
      var: 'B',
      value: params.start,
    },
    {
      var: 'E',
      value: params.end,
    }];
  }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      products_id: selectedProducts,
      module_id: 2,
      config: configVariables,
    }),
  };

  return (dispatch) => {
    dispatch(setNewConfigRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/config`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(setNewWifiConfigSuccess(res.success));
      })
      .catch(error => dispatch(setNewConfigFailure(error)));
  };
}

export function setNewLEDConfig(selectedProducts, seasons) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      selected_products: selectedProducts,
      module_id: 8,
      seasons,
    }),
  };

  return (dispatch) => {
    dispatch(setNewConfigRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/config/set-periodic`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(setNewLEDConfigSuccess(res.success));
      })
      .catch(error => dispatch(setNewConfigFailure(error)));
  };
}

export function setNewHeatingConfig(selectedProducts, variableToUpdate, params) {
  let configVariables = [];

  if (variableToUpdate === 'schedule') {
    configVariables = [{
      var: 'B',
      value: params.start,
    },
    {
      var: 'E',
      value: params.end,
    },
    {
      var: 'V',
      value: params.temperature,
    }];
  }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      products_id: selectedProducts,
      module_id: 1,
      config: configVariables,
    }),
  };

  return (dispatch) => {
    dispatch(setNewConfigRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/config`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(setNewHeatingConfigSuccess(res.success));
      })
      .catch(error => dispatch(setNewConfigFailure(error)));
  };
}

export function setNewTerraConfig(selectedProducts, variableToUpdate, params) {
  let configVariables = [];

  if (variableToUpdate === 'schedule') {
    configVariables = [{
      var: 'B',
      value: params.start,
    },
    {
      var: 'E',
      value: params.end,
    },
    {
      var: 'P',
      value: params.pVariable,
    },
    {
      var: 'C',
      value: params.cVariable,
    },
    ];
  }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      products_id: selectedProducts,
      module_id: 48,
      config: configVariables,
    }),
  };

  return (dispatch) => {
    dispatch(setNewConfigRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/config`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(setNewTerraConfigSuccess(res.success));
      })
      .catch(error => dispatch(setNewConfigFailure(error)));
  };
}

export function extendSubscription(selectedProduct, years) {
  const postOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      years,
    }),
  };

  return (dispatch) => {
    dispatch(extendSubscriptionRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/deployed/extend-subscription/${selectedProduct}`, postOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(extendSubscriptionSuccess(res.success));
      })
      .catch(error => dispatch(extendSubscriptionFailure(error)));
  };
}
