import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './containers/App/App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'http://34c33e502c394487a15d459cbd025a40@172.16.100.20:9000/6' });
}

render(
  <App />,
  document.getElementById('root'),
);
