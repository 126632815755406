import {
  fetchAirSensorTypesRequest,
  fetchAirSensorTypesSuccess,
  fetchAirSensorTypesFailure,
  createAirSensorRequest,
  createAirSensorSuccess,
  createAirSensorFailure,
  fetchSingleAirSensorRequest,
  fetchSingleAirSensorSuccess,
  fetchSingleAirSensorFailure,
  updateAirSensorRequest,
  updateAirSensorSuccess,
  updateAirSensorFailure,
} from '../redux/actions/airSensorActions';

import authHeader from './authHeader';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response);
  }
  return response;
}

const requestOptions = {
  method: 'GET',
  headers: authHeader(),
};

export function fetchAirSensorTypes() {
  return (dispatch) => {
    dispatch(fetchAirSensorTypesRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/air-sensors/types`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        } else {
          const data = res.data.map(item => ({ label: item.name, value: item.id }));
          dispatch(fetchAirSensorTypesSuccess(data));
        }
      })
      .catch(error => dispatch(fetchAirSensorTypesFailure(error)));
  };
}

export function createAirSensor(params) {
  const createOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      air_sensor_type: params.air_sensor_type,
      board_type: params.board_type,
      gain: params.gain,
      sensor_number: params.sensor_number,
      we_zero: params.we_zero,
      aux_zero: params.aux_zero,
      we_sensor: params.we_sensor,
      sensitivity: params.sensitivity,
      we_electronic_zero: params.we_electronic_zero,
      aux_electronic_zero: params.aux_electronic_zero,
      no2_sensitivity: params.no2_sensitivity,
      algorithm: params.algorithm,
    }),
  };

  return (dispatch) => {
    dispatch(createAirSensorRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/air-sensors/store`, createOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(createAirSensorSuccess(res.data));
      })
      .catch(error => dispatch(createAirSensorFailure(error)));
  };
}

export function fetchSingleAirSensorData(deployedProductId) {
  return (dispatch) => {
    dispatch(fetchSingleAirSensorRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/air-sensors/show/${deployedProductId}`, requestOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(fetchSingleAirSensorSuccess(res.data));
      })
      .catch(error => dispatch(fetchSingleAirSensorFailure(error)));
  };
}

export function updateAirSensor(id, params) {
  const updateOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      air_sensor_type: params.air_sensor_type,
      board_type: params.board_type,
      gain: params.gain,
      sensor_number: params.sensor_number,
      we_zero: params.we_zero,
      aux_zero: params.aux_zero,
      we_sensor: params.we_sensor,
      sensitivity: params.sensitivity,
      we_electronic_zero: params.we_electronic_zero,
      aux_electronic_zero: params.aux_electronic_zero,
      no2_sensitivity: params.no2_sensitivity,
      algorithm: params.algorithm,
      correction_factor: params.correction_factor,
    }),
  };

  return (dispatch) => {
    dispatch(updateAirSensorRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/air-sensors/update/${id}`, updateOptions)
      .then(handleErrors)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(updateAirSensorSuccess(res.success));
      })
      .catch(error => dispatch(updateAirSensorFailure(error)));
  };
}
