import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const SensorInfoAggregates = ({ sensorData }) => {
  const dataForLabel = (label) => {
    if (label && label.length > 0) {
      if (label.includes('no2')) return <>NO<sub>2</sub></>;
      if (label.includes('o3')) return <>O<sub>3</sub></>;
      return <>{label.toUpperCase()}</>;
    } return '';
  };

  const colorCircleCssClass = (colorLabel) => {
    if (colorLabel === 'Extremely Poor') return 'extremely-poor';
    if (colorLabel === 'Very Poor') return 'very-poor';
    return colorLabel.toLowerCase();
  };

  const isWindDirection = sensorData.name === 'wind direction';

  return (
    <div>
      {sensorData.data.aggregates.min
        ? (
          <div className="aqi-aggregates">
            <span className="label">{isWindDirection ? 'Minimum wind direction' : 'Minimum air quality index'}</span>
            <span className="value-aqi">{sensorData.data.aggregates.min} {sensorData.unit}</span>
            {!isWindDirection
            && (
            <>
              <span
                className={`circle-color ${colorCircleCssClass(sensorData.data.aggregates.min_label)}`}
              />
              <span className="text-label">{sensorData.data.aggregates.min_label}
                  &nbsp;(due to {dataForLabel(sensorData.data.aggregates.min_type)})
              </span>
            </>
            )}
            <p className="date-aqi">{moment.unix(sensorData.data.aggregates.dateMinValue.timestamp)
              .format('DD.MM.YYYY HH:mm')}
            </p>
          </div>
        )
        : (
          <div>
            <span className="label">{isWindDirection ? 'Minimum wind direction' : 'Minimum air quality index'}</span>
            <span className="value">No data</span>
          </div>
        )}
      {sensorData.data.aggregates.max
        ? (
          <div className="aqi-aggregates">
            <span className="label">{isWindDirection ? 'Maximum wind direction' : 'Maximum air quality index'}</span>
            <span className="value-aqi">{sensorData.data.aggregates.max} {sensorData.unit}</span>
            {!isWindDirection
            && (
            <>
              <span
                className={`circle-color ${colorCircleCssClass(sensorData.data.aggregates.max_label)}`}
              />
              <span className="text-label">{sensorData.data.aggregates.max_label}
          &nbsp;(due to {dataForLabel(sensorData.data.aggregates.max_type)})
              </span>
            </>
            )}
            <p className="date-aqi">{moment.unix(sensorData.data.aggregates.dateMaxValue.timestamp)
              .format('DD.MM.YYYY HH:mm')}
            </p>
          </div>
        )
        : (
          <div>
            <span className="label">{isWindDirection ? 'Maximum wind direction' : 'Maximum air quality index'}</span>
            <span className="value">No data</span>
          </div>
        )}

      {sensorData.data.latest
        ? (
          <div className="aqi-aggregates">
            <span className="label">{isWindDirection ? 'Average wind direction' : 'Latest air quality index'}</span>
            <span className="value-aqi">
              {isWindDirection ? sensorData.data.aggregates.avg : sensorData.data.latest.value} {sensorData.unit}
            </span>
            {!isWindDirection && (
              <>
                <span className={`circle-color ${colorCircleCssClass(sensorData.data.latest.label)}`} />
                <span className="text-label">
                  {sensorData.data.latest.label} &nbsp;(due to {dataForLabel(sensorData.data.latest.type)})
                </span>
              </>
            )}
            <p className="date-aqi">{moment.unix(sensorData.data.latest.raw_date.timestamp)
              .format('DD.MM.YYYY HH:mm')}
            </p>
          </div>
        )
        : (
          <div>
            <span className="label">{isWindDirection ? 'Average wind direction' : 'Latest air quality index'}</span>
            <span className="value">No data</span>
          </div>
        )}
    </div>
  );
};

SensorInfoAggregates.propTypes = {
  sensorData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default SensorInfoAggregates;
