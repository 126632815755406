import {
  SEARCH_ALL_REQUEST,
  SEARCH_ALL_SUCCESS,
  SEARCH_ALL_FAILURE,
} from '../actions/searchActions';

const initialState = {
  error: null,
  loading: false,
  results: [],
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_ALL_SUCCESS:
      return {
        ...state,
        results: action.payload,
        loading: false,
      };
    case SEARCH_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
