import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import validate from './activationValidator';

class AddForm extends Component {
  constructor() {
    super();

    this.state = {
      validationErrors: { activationCode: '' },
      activationCode: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { validationErrors, activationCode } = this.state;
    const { handleConfirm } = this.props;
    const valuesToValidate = { activationCode };

    const errors = validate(valuesToValidate);
    if (isEmpty(errors)) {
      handleConfirm(activationCode);
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          activationCode: errors.activationCode,
        },
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { validationErrors } = this.state;

    // clear validation error when we start typing
    this.setState({
      [name]: value.toUpperCase(),
      validationErrors: {
        ...validationErrors,
        [name]: '',
      },
    });
  }

  render() {
    const { validationErrors, activationCode } = this.state;
    const allowedChar = /[A-Za-z0-9]/;

    return (
      <form className="activation-form" onSubmit={this.handleSubmit}>
        <span className="input-label">Activation key: </span>
        <div className="input-squared">
          <MaskedInput
            type="text"
            name="activationCode"
            placeholder="XXXX-XXX-XXX-XXX"
            id="activationCode"
            className="form-control"
            onChange={this.handleChange}
            value={activationCode}
            mask={[
              allowedChar, allowedChar, allowedChar, allowedChar, '-',
              allowedChar, allowedChar, allowedChar, '-',
              allowedChar, allowedChar, allowedChar, '-',
              allowedChar, allowedChar, allowedChar,
            ]}
          />
          { validationErrors.activationCode
              && <span className="input-error"> {validationErrors.activationCode} </span>}
        </div>
        <div className="save-btn">
          <Button
            padding="2px 20px"
            onClick={this.handleSubmit}
          > Add product
          </Button>
        </div>
      </form>
    );
  }
}

AddForm.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
};

export default AddForm;
