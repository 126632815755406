import React, { useState } from 'react';
import {
  Col, Card, CardBody, Button,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CalibrationDateModal from '../../Modal/ModalWithInputDate';
import CalculationTypeModal from '../../Modal/ModalWithDropdownList';
import { setLastCalibrationDate, setTypeOfCalculation } from '../../../api/productInfoApi';

const ProductDetails = ({ data, maintenanceListData }) => {
  const dispatch = useDispatch();

  const [openModal, toggleModal] = useState(false);
  const [openCalcModal, toggleCalcModal] = useState(false);

  const saveDate = (dateValue) => {
    dispatch(setLastCalibrationDate(data.id, dateValue));
  };

  const saveCalcValue = (calcValue) => {
    let sendType = 1;
    if (calcValue === 'Backend calculation') sendType = 0;
    dispatch(setTypeOfCalculation(data.id, sendType));
  };

  return (
    <Col lg={6}>
      <Card>
        <CardBody>
          <h3>General info:</h3>
          <p><strong>ID:</strong> {data.id}</p>
          <p><strong>Model:</strong> {data.name}</p>
          <p><strong>Serial number:</strong> {data.device_id}</p>
          <p><strong>Location:</strong> {data.location}</p>
          <p><strong>GPS:</strong> {data.lattitude}, {data.longitude}</p>
          <p><strong>Nickname:</strong> {data.nickname || 'No nickname'}</p>
          <p><strong>Owner:</strong> {data.owner && data.owner.company ? data.owner.company.name : data.owner.name}</p>
          <p><strong>Distributor:</strong> {data.distributor.name}</p>
          <p><strong>Is permanently active:</strong>
            {data.is_permanently_active ? 'Permanently activated' : 'Not permanently activated'}
          </p>
          <p><strong>Solos Subscription expires: </strong>{data.subscription_expires || 'No data'}
            {data.has_valid_subscription ? <CheckIcon color="green" /> : <CloseIcon color="red" /> }
          </p>
          {data.product_type.slug === 'aerys'
        && (
        <div className="calibration">
          <p>
            <strong>
              Last calibration date:
            </strong>{moment(data.calibration_date).format('DD.MM.YYYY') || 'No data'}
          </p>
          <Button
            padding="2px 20px"
            className="calibration-btn"
            onClick={() => toggleModal(true)}
          > Enter calibration date
          </Button>
        </div>
        )
        }
          {data.product_type.slug === 'aerys'
        && (
          <div className="calibration">
            <p />
            <p>
              <strong>Type of calculation:</strong>
              {data.use_firmware_calc ? 'Firmware calculation' : 'Backend calculation'}
            </p>
            <Button
              padding="2px 20px"
              className="calibration-btn"
              onClick={() => toggleCalcModal(true)}
            > Change type of calculation
            </Button>
          </div>
        )
        }

          <div className="calibration">
            <p>
              <strong>Maintenance history:</strong>
              {maintenanceListData.length > 0 ? String(maintenanceListData) : 'N/A'}
            </p>
          </div>

          <hr />
          <p><strong>Accessories installed:</strong> {data.selected_accessories.map(item => `${item.name}, `)}</p>
          <p><strong>Modules installed:</strong> {data.deployed_modules.map(item => `${item.name}, `)}</p>
          <div className="sensor-data">
            <Link
              className="btn btn-primary account__btn"
              to={`/${data.product_type.slug}/sensors/${data.id}`}
            >Sensor data
            </Link>
          </div>

        </CardBody>
        <CalibrationDateModal
          modalOpen={openModal}
          onCloseClick={toggleModal}
          message={`Add last calibration date to ${data.device_id}`}
          date={data.calibration_date}
          confirmAction={saveDate}
        />
        <CalculationTypeModal
          modalOpen={openCalcModal}
          onCloseClick={toggleCalcModal}
          message={`Change calculation type for ${data.device_id}`}
          value={data.use_firmware_calc ? 'Firmware calculation' : 'Backend calculation'}
          confirmAction={saveCalcValue}
        />
      </Card>
    </Col>
  );
};

ProductDetails.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object, PropTypes.number,
  ])).isRequired,
  maintenanceListData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object,
  ])).isRequired,
};


export default ProductDetails;
