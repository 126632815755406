import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import VerifiedCompany from './VerifiedCompany';
import { verifyCompany, fetchCompanies } from '../../../api/companiesApi';

class TableBody extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clickVerification: false,
    };
  }

  verificationHandler = (companyVerificationSuccess) => {
    const { dispatch } = this.props;
    this.setState({ clickVerification: true });
    dispatch(verifyCompany(companyVerificationSuccess));
  }

  returnHandler = () => {
    const { dispatch } = this.props;
    this.setState({ clickVerification: false });
    dispatch(fetchCompanies());
  }

  render() {
    const {
      companiesList, companyVerificationSuccess, error,
    } = this.props;
    const { clickVerification } = this.state;

    return (
      <Container>
        {!clickVerification ? (companiesList.map(item => (
          <Row className="table-body">
            <Col md={1}>
              <p>{item.id}</p>
            </Col>
            <Col md={2}>
              <p>{item.administrator.name}</p>
            </Col>
            <Col md={2}>
              <p>{item.name}</p>
            </Col>
            <Col md={2}>
              <p>{item.address}</p>
            </Col>
            <Col md={2}>
              <p>{item.vat}</p>
            </Col>
            <Col md={1}>
              <p>{item.city}</p>
            </Col>
            <Col md={1}>
              <p>{item.country.name}</p>
            </Col>
            <Col md={1}>
              <button
                className="verification-button"
                type="button"
                onClick={() => this.verificationHandler(item.administrator.verification_code)}
              >Verify
              </button>
            </Col>
          </Row>
        )))
          : (
            <VerifiedCompany
              companyVerificationSuccess={companyVerificationSuccess}
              error={error}
              returnHandler={this.returnHandler}
            />
          )}
      </Container>
    );
  }
}

TableBody.propTypes = {
  companiesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  companyVerificationSuccess: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool,
  ])).isRequired,
  error: PropTypes.string.isRequired,
};

export default TableBody;
