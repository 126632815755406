const validate = (values) => {
  const errors = {};
  if (!values.pantheon_id) {
    errors.pantheon_id = 'Pantheon ID can not be empty';
  }
  if (!values.spare_part_name) {
    errors.spare_part_name = 'Spare part name can not be empty';
  }
  if (!values.product_types) {
    errors.product_types = 'Product type can not be empty';
  }
  return errors;
};

export default validate;
