/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col,
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import TableHeader from './TableHeaderCompanyProducts';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { removeProductFromCompany } from '../../../api/companiesApi';
import { UserProps } from '../../../shared/prop-types/ReducerProps';

const DropDownMore = ({ id, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more delete-option-table">
    <DropdownToggle>
      <p><DotsHorizontalIcon /></p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <DropdownItem id={id} onClick={handleDeleteRow}>Delete</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);
DropDownMore.propTypes = {
  id: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

function createData(id, deviceID, location) {
  return {
    id,
    device_id: deviceID,
    location,
  };
}

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class CompanyProductsList extends PureComponent {
  constructor(props) {
    super(props);
    const { products } = this.props;

    this.state = {
      order: 'desc',
      orderBy: 'id',
      data: products.map(item => createData(
        item.id,
        item.device_id,
        item.location,
      )),
      clickedProductId: null,
      page: 0,
      rowsPerPage: 10,
      modalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { products } = this.props;

    if (prevProps.products.length !== products.length) {
      this.updateProductsList();
    }
  }

  updateProductsList = () => {
    const { products } = this.props;
    this.setState({
      data: products.map(item => createData(
        item.id,
        item.device_id,
        item.location,
      )),
    });
  }

  confirmDeletion = (companyID) => {
    const { clickedProductId } = this.state;
    const { dispatch } = this.props;
    dispatch(removeProductFromCompany(companyID, clickedProductId));
    this.handleToggle();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleToggle = () => {
    this.setState(prevState => ({ modalOpen: !prevState.modalOpen }));
  }

  render() {
    const {
      data, order, orderBy, rowsPerPage, page, modalOpen,
    } = this.state;
    const { products, user } = this.props;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">List of products for company with id {products[0].company_id} </h5>
            </div>
            <div className="material-table__wrap">
              <Table className="material-table">
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                />
                <TableBody>
                  {data
                    .sort(getSorting(order, orderBy))
                    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                    .map(d => (
                      <>
                        <TableRow
                          className="material-table__row"
                          role="checkbox"
                        // onClick={event => this.handleClick(event, d.id)}
                          tabIndex={-1}
                          key={d.id}
                        >
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            {d.id}
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            {d.device_id}
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                          >{d.location}
                          </TableCell>

                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >{user.permissions && user.permissions.includes('customers.edit')
                            ? (
                              <DropDownMore
                                id={d.id}
                                handleDeleteRow={() => {
                                  this.handleToggle();
                                  this.setState({ clickedProductId: d.id });
                                }}
                              />
                            )
                            : 'No permission'
                        }
                          </TableCell>
                        </TableRow>

                      </>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 20, 50, 100]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            />
            <ConfirmModal
              modalOpen={modalOpen}
              onCloseClick={this.handleToggle}
              message="Are you sure you want to remove product?"
              handleConfirm={() => this.confirmDeletion(products[0].company_id)}
            />
          </CardBody>

        </Card>
      </Col>
    );
  }
}

CompanyProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ]))).isRequired,
  dispatch: PropTypes.func.isRequired,
  user: UserProps.isRequired,
};
