/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  UncontrolledTooltip,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import InformationVariantIcon from 'mdi-react/InformationVariantIcon';
import { BasicNotification } from '../../../shared/components/Notification';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

import { assignPermissionToUser } from '../../../api/usersApi';

let notification = null;

const showNotification = (title, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={message.includes('successfully') ? 'success' : 'danger'}
    />,
    duration: 6,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up ltr-support',
  });
};

class UserWithPermissions extends Component {
  componentDidUpdate(prevProps) {
    const { lastUserUpdated } = this.props;

    if (prevProps.lastUserUpdated !== lastUserUpdated) {
      const title = lastUserUpdated.includes('successfully') ? 'Permissions updated' : 'Api error';
      const message = lastUserUpdated.includes('successfully') ? 'Permissions are successfully updated.' : '';
      NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
      setTimeout(() => showNotification(title, message), 10);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      eventFormData, user, dispatch, loggedUser,
    } = this.props;
    const params = [];

    if (eventFormData.values.customers_edit) params.push('customers.edit');
    if (eventFormData.values.notifications_create) params.push('notifications.create');
    if (eventFormData.values.company_verification_edit) params.push('company_verification.edit');
    if (eventFormData.values.permissions_edit) params.push('permissions.edit');
    if (eventFormData.values.manage_edit) params.push('manage.edit');

    dispatch(assignPermissionToUser(user.id, params, loggedUser.username));
  }

  render() {
    const { user } = this.props;

    return (
      <Col lg={6} xl={8}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{`${user.name} permissions`} </h5>
            </div>
            <form className="form material-form" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <span className="form__form-group-label"><InformationVariantIcon id="customers_edit_info" /></span>
                  <span className="form__form-group-label permissions">Edit customers</span>
                  <Field
                    name="customers_edit"
                    component={renderCheckBoxField}
                    defaultChecked={user.permissions.includes('customers.edit')}
                    color="#4ce1b6"
                    className="colored"
                  />
                  <UncontrolledTooltip placement="top" target="customers_edit_info">
                    Permission is used in list of customers/companies to display/hide action buttons.
                  </UncontrolledTooltip>
                </div>
                <div className="form__form-group-field">
                  <span className="form__form-group-label">
                    <InformationVariantIcon id="notifications_create_info" />
                  </span>
                  <span className="form__form-group-label permissions">Create notifications</span>
                  <Field
                    name="notifications_create"
                    component={renderCheckBoxField}
                    defaultChecked={user.permissions.includes('notifications.create')}
                    color="#4ce1b6"
                    className="colored"
                  />
                  <UncontrolledTooltip placement="top" target="notifications_create_info">
                    User can create global notification.
                  </UncontrolledTooltip>
                </div>
                <div className="form__form-group-field">
                  <span className="form__form-group-label">
                    <InformationVariantIcon id="company_verification_edit_info" />
                  </span>
                  <span className="form__form-group-label permissions">Company verification</span>
                  <Field
                    name="company_verification_edit"
                    component={renderCheckBoxField}
                    defaultChecked={user.permissions.includes('company_verification.edit')}
                    color="#4ce1b6"
                    className="colored"
                  />
                  <UncontrolledTooltip placement="top" target="company_verification_edit_info">
                    User can verify company.
                  </UncontrolledTooltip>
                </div>

                <div className="form__form-group-field">
                  <span className="form__form-group-label"><InformationVariantIcon id="permissions_edit_info" /></span>
                  <span className="form__form-group-label permissions">Edit permissions</span>
                  <Field
                    name="permissions_edit"
                    component={renderCheckBoxField}
                    defaultChecked={user.permissions.includes('permissions.edit')}
                    color="#4ce1b6"
                    className="colored"
                  />
                  <UncontrolledTooltip placement="top" target="permissions_edit_info">
                    Who can assign/remove permissions.
                  </UncontrolledTooltip>
                </div>
                <div className="form__form-group-field">
                  <span className="form__form-group-label"><InformationVariantIcon id="manage_edit_info" /></span>
                  <span className="form__form-group-label permissions">Manage data</span>
                  <Field
                    name="manage_edit"
                    component={renderCheckBoxField}
                    defaultChecked={user.permissions.includes('manage.edit')}
                    color="#4ce1b6"
                    className="colored"
                  />
                  <UncontrolledTooltip placement="top" target="manage_edit_info">
                    Who can view manage data.
                  </UncontrolledTooltip>
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="success" type="submit">
                  Update
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

UserWithPermissions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  eventFormData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool,
  ])),
  lastUserUpdated: PropTypes.string.isRequired,
  user: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.array,
  ])).isRequired,
  loggedUser: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.array,
  ])).isRequired,
};

UserWithPermissions.defaultProps = {
  eventFormData: {},
};

const mapStateToProps = state => ({
  rtl: state.rtl,
  eventFormData: state.form.updateUserPermissionForm,
  lastUserUpdated: state.user.lastUserUpdated,
  loggedUser: state.user.userInfo,
});

const UpdateWikiFormComponent = reduxForm({
  form: 'updateUserPermissionForm',
  enableReinitialize: true,
})(UserWithPermissions);

export default connect(mapStateToProps)(UpdateWikiFormComponent);
