/* eslint-disable no-return-assign */
import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmpty from 'lodash/isEmpty';
import { BasicNotification } from '../../shared/components/Notification';
import { updateGeoData, requestGeoLocating } from '../../api/manageApi';
import history from '../../shared/helpers/history';
import validate from './LocationValidator';
import { deSelectedRoutes } from '../../redux/actions/manageAppActions';
import { fetchDeployedProductInfo } from '../../api/productInfoApi';

let notification = null;

const showNotification = (title, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={message.includes('sucessfully') ? 'success' : 'danger'}
    />,
    duration: 6,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up ltr-support',
  });
};

class LocationForm extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onGeoDataUpdate: PropTypes.string.isRequired,
    eventFormData: PropTypes.objectOf((PropTypes.oneOfType([
      PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.array,
    ]))),
    initialValues: PropTypes.objectOf((PropTypes.oneOfType([
      PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.array,
    ]))).isRequired,
    typeOfProduct: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      validationErrors: {
        lattitude: '',
        longitude: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    const {
      onGeoDataUpdate, initialValues, dispatch, typeOfProduct,
    } = this.props;

    if (prevProps.onGeoDataUpdate !== onGeoDataUpdate) {
      const title = onGeoDataUpdate.includes('sucessfully') ? 'Location updated' : 'Api error';
      const message = `${onGeoDataUpdate}`;
      NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
      setTimeout(() => showNotification(title, message), 10);

      history.push(`/${typeOfProduct}/manage/${initialValues.id}`);
      dispatch(fetchDeployedProductInfo(initialValues.id));
      dispatch(deSelectedRoutes());
    }
  }

  handleSubmit = (e) => {
    const { eventFormData, dispatch, initialValues } = this.props;
    const { validationErrors } = this.state;
    e.preventDefault();

    const valuesToValidate = {
      lattitude: eventFormData.values && eventFormData.values.lattitude,
      longitude: eventFormData.values && eventFormData.values.longitude,
    };
    const errors = validate(valuesToValidate);

    if (isEmpty(errors)) {
      const params = {
        lattitude: eventFormData.values.lattitude,
        longitude: eventFormData.values.longitude,
        location: eventFormData.values.location,
      };
      dispatch(updateGeoData(initialValues.device_id, params));
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          lattitude: errors.lattitude,
          longitude: errors.longitude,
        },
      });
    }
  }

  handleLocateMe = (deviceID) => {
    const { dispatch, initialValues, typeOfProduct } = this.props;
    dispatch(requestGeoLocating(deviceID));
    history.push(`/${typeOfProduct}/${initialValues.id}`);
    dispatch(deSelectedRoutes());
  };

  render() {
    const { validationErrors } = this.state;
    const { initialValues } = this.props;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Location form</h5>
            </div>
            <form className="form form--horizontal" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Latitude
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="lattitude"
                    component="input"
                    type="number"
                  />
                </div>
                { validationErrors.lattitude
                  && <span className="form__form-group-error"> {validationErrors.lattitude} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Longitude
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="longitude"
                    component="input"
                    type="number"
                  />
                </div>
                { validationErrors.longitude
                  && <span className="form__form-group-error"> {validationErrors.longitude} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Address
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="location"
                    component="input"
                    type="text"
                  />
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">Save</Button>
              </ButtonToolbar>
            </form>
            {!initialValues.requested_geo
              ? (
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => this.handleLocateMe(initialValues.id)}
                >Update location automatically
                </Button>
              )
              : <span>Geo location requested.</span>
  }
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  onGeoDataUpdate: state.manageApp.onGeoDataUpdate,
  eventFormData: state.form.locationForm,
  initialValues: state.productInfo.productDetails,
});

LocationForm.defaultProps = {
  eventFormData: {},
};

const LocationFormComponent = reduxForm({
  form: 'locationForm',
  enableReinitialize: true,
})(LocationForm);

export default connect(mapStateToProps)(LocationFormComponent);
