import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../../../shared/components/Loading';
import ActiveBenchesList from '../../../components/ProductDisplayData/components/ActiveProductsList';
import { fetchDeployedProductsByType, searchDeployedProducts } from '../../../api/productsApi';

class SteoraBenches extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchDeployedProductsByType('benches'));
  }

  handlePagination = (page) => {
    const { dispatch } = this.props;
    dispatch(fetchDeployedProductsByType('benches', page));
  };

  handleSearchBenches = (e, searchTerm) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(searchDeployedProducts('benches', searchTerm));
  }

  reRenderPage = () => {
    const { dispatch } = this.props;
    dispatch(fetchDeployedProductsByType('benches'));
  }

  render() {
    const {
      benchesList, loading, paginationOptions, searchResultsMessage,
    } = this.props;

    return (
      <Container>
        <Row>
          <Col>
            <h3 className="page-title">Steora Benches</h3>
          </Col>
        </Row>
        <Row>
          {loading ? (
            <Loading loading={loading} />
          )
            : benchesList.length > 0 && (
            <ActiveBenchesList
              key={benchesList.length}
              productsList={benchesList}
              paginationOptions={paginationOptions}
              handlePagination={this.handlePagination}
              handleSearchProducts={this.handleSearchBenches}
              searchResultsMessage={searchResultsMessage}
              handleGoBack={this.reRenderPage}
              typeOfProduct="benches"
            />
            )
          }
        </Row>
      </Container>
    );
  }
}

SteoraBenches.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  benchesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  paginationOptions: PropTypes.objectOf(PropTypes.node).isRequired,
  searchResultsMessage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  benchesList: state.products.deployedBenches,
  searchResultsMessage: state.products.searchResultsMessage,
  paginationOptions: state.products.benchesListPagination,
  loading: state.products.loading,
});

export default connect(mapStateToProps)(SteoraBenches);
