// authentication functions
import moment from 'moment';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutSuccess,
} from '../redux/actions/authActions';

import history from '../shared/helpers/history';

export function logoutAction() {
  // remove user from local storage and dispatch logoutSuccess action
  localStorage.removeItem('auth');
  return (dispatch) => {
    dispatch(logoutSuccess());
  };
}

export function loginAction(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify({ username, password }),
  };

  return (dispatch) => {
    dispatch(loginRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/users/login`, requestOptions)
      .then(res => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        // save token and username to localStorage
        // time to live 10 hours
        const now = moment();
        const ttl = 36000000;
        const auth = {
          token: res.success.token,
          username,
          expiry: now + ttl,
        };
        localStorage.setItem('auth', JSON.stringify(auth));
        dispatch(loginSuccess());
        // update path and refresh
        history.push('/');
        history.go(0);
      })
      .catch(error => dispatch(loginFailure(error)));
  };
}
