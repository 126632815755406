import {
  FETCH_SINGLE_SETTINGS_DATA_REQUEST,
  FETCH_HR_INFO_SUCCESS,
  FETCH_HR_SURVEY_SUCCESS,
  FETCH_DEVELOPMENT_INFO_SUCCESS,
  FETCH_TRAVELING_INFO_SUCCESS,
  FETCH_ABOUT_US_INFO_SUCCESS,
  FETCH_JOB_INFO_SUCCESS,
  FETCH_HR_EMPLOYEES_SUCCESS,
  FETCH_CHART_MONTHLY_SALES_SUCCESS,
  FETCH_CHART_ANUALLY_SALES_SUCCESS,
  FETCH_CHART_ANUALLY_MARKETS_SUCCESS,
  FETCH_SINGLE_SETTINGS_DATA_FAILURE,
  FETCH_CHART_ANUALLY_EMPLOYEES_SUCCESS,
  FETCH_CHART_ANUALLY_PROFIT_SUCCESS,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
} from '../actions/settingsAction';

const initialState = {
  error: null,
  loading: false,
  lastSettingUpdated: {},
  HRInfo: {},
  DevelopmentInfo: {},
  TravelingInfo: {},
  AboutUsInfo: {},
  JobInfo: {},
  HRSurvey: {},
  HREmployees: {},
  chartSalesMonthly: {
    title: '',
    months: [],
    initialValues: {},
  },
  chartSalesAnually: {
    title: '',
    years: [],
    initialValues: {},
  },
  chartMarketsAnually: {
    title: '',
    years: [],
    initialValues: {},
  },
  chartEmployeesAnually: {
    title: '',
    years: [],
    initialValues: {},
  },
  chartProfitAnually: {
    title: '',
    years: [],
    initialValues: {},
  },
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        lastSettingUpdated: action.payload,
        loading: false,
      };
    case UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_SINGLE_SETTINGS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_HR_INFO_SUCCESS:
      return {
        ...state,
        HRInfo: action.payload,
        loading: false,
      };
    case FETCH_HR_SURVEY_SUCCESS:
      return {
        ...state,
        HRSurvey: action.payload,
        loading: false,
      };
    case FETCH_HR_EMPLOYEES_SUCCESS:
      return {
        ...state,
        HREmployees: action.payload,
        loading: false,
      };
    case FETCH_DEVELOPMENT_INFO_SUCCESS:
      return {
        ...state,
        DevelopmentInfo: action.payload,
        loading: false,
      };
    case FETCH_TRAVELING_INFO_SUCCESS:
      return {
        ...state,
        TravelingInfo: action.payload,
        loading: false,
      };
    case FETCH_ABOUT_US_INFO_SUCCESS:
      return {
        ...state,
        AboutUsInfo: action.payload,
        loading: false,
      };
    case FETCH_JOB_INFO_SUCCESS:
      return {
        ...state,
        JobInfo: action.payload,
        loading: false,
      };
    case FETCH_CHART_MONTHLY_SALES_SUCCESS:
      return {
        ...state,
        chartSalesMonthly: action.payload,
        loading: false,
      };
    case FETCH_CHART_ANUALLY_SALES_SUCCESS:
      return {
        ...state,
        chartSalesAnually: action.payload,
        loading: false,
      };
    case FETCH_CHART_ANUALLY_MARKETS_SUCCESS:
      return {
        ...state,
        chartMarketsAnually: action.payload,
        loading: false,
      };
    case FETCH_CHART_ANUALLY_EMPLOYEES_SUCCESS:
      return {
        ...state,
        chartEmployeesAnually: action.payload,
        loading: false,
      };
    case FETCH_CHART_ANUALLY_PROFIT_SUCCESS:
      return {
        ...state,
        chartProfitAnually: action.payload,
        loading: false,
      };
    case FETCH_SINGLE_SETTINGS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
