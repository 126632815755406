import PropTypes from 'prop-types';
import React from 'react';

const Schedule = ({ labelText, handleScheduleClick, currentValue }) => {
  const hourOptions = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '00'];
  const minutesOptions = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09'];
  for (let i = 10; i < 60; i += 1) { minutesOptions.push(i.toString()); }

  const defaultMinutes = currentValue.slice(-2); // uzet prva 2 broja od vrijednosti koje dobijemo
  const defaultHours = currentValue.substring(0, 2); // uzet zadnja 2 broja od vrijednosti koje dobijemo

  /*
  -> custom rijesenje za pretvorbu sa 24 hours to 12 hours i prikazom AM/PM
  -> problem nastaje kod promjene, sto tocno slati nazad prema backendu
    const suffix = defaultHours > 12 ? 'PM' : 'AM';
    defaultHours = suffix === 'PM' ? defaultHours - 12 : defaultHours;

    // za ove koji su jednoznamenkasti PM, moramo dodat jos nulu da bude npr 08 umisto 8
    if (typeof defaultHours === 'number' && defaultHours < 10) defaultHours = `0${defaultHours}`;
  */

  return (
    <div className="schedule-fields">
      <span className="schedule-label">{labelText}</span>
      <select onChange={value => handleScheduleClick(value, 'hours')} defaultValue={defaultHours}>
        {hourOptions.map(hour => <option value={hour} key={hour}>{hour}</option>)}
      </select>
      :
      <select onChange={value => handleScheduleClick(value, 'minutes')} defaultValue={defaultMinutes}>
        {minutesOptions.map(minutes => <option value={minutes} key={minutes}>{minutes}</option>)}
      </select>
    </div>
  );
};

Schedule.propTypes = {
  labelText: PropTypes.string,
  currentValue: PropTypes.string,
  handleScheduleClick: PropTypes.func.isRequired,
};

Schedule.defaultProps = {
  labelText: 'Turn on:',
  currentValue: '12:00',
};

export default Schedule;
