const validate = (values) => {
  const errors = {};
  if (!values.productId) {
    errors.productId = 'Please fill product id field';
  }

  return errors;
};

export default validate;
