import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const DefaultDashboard = () => (
  <Container>
    <Row>
      <Col md={4}>
        <h3 className="page-title">Homepage</h3>
        <p>Welcome to ADMIN.</p>
      </Col>
    </Row>
  </Container>
);


export default DefaultDashboard;
