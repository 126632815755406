import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CompanysListTable from './components/CompanysListTable';
import { fetchCompanies } from '../../api/companiesApi';

class AddressBook extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchCompanies());
  }

  render() {
    const { dispatch, companies } = this.props;
    const { companiesList, companyVerificationSuccess, error } = companies;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">List of companies</h3>
            <h3 className="page-subhead subhead">Newly created companies waiting for verification</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {companiesList.length > 0 ? (
              <CompanysListTable
                companyVerificationSuccess={companyVerificationSuccess}
                dispatch={dispatch}
                companiesList={companiesList}
                error={error}
              />
            ) : (
              <Card>
                <CardBody><h5>Currently there are no new companies to verify. </h5></CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

AddressBook.propTypes = {
  dispatch: PropTypes.func.isRequired,
  companies: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object,
  ])).isRequired,
};

const mapStateToProps = state => ({
  companies: state.companies,
});

export default compose(withTranslation('common'), connect(
  (mapStateToProps),
))(AddressBook);
