import React from 'react';
import { Route, Switch } from 'react-router-dom';

import WasteContainers from '../../../DeployedProducts/Waste';
import WasteInfo from '../../../DeployedProducts/Waste/Info';
import LogsInfo from '../../../../components/LogsInfo';
import SensorDataContainer from '../../../../components/Sensors/SensorDataContainer';
import ManageDataContainer from '../../../../components/ManageData/ManageDataContainer';

export default () => (
  <Switch>
    <Route exact path="/waste-containers" component={WasteContainers} />
    <Route exact path="/waste-containers/:id" component={WasteInfo} />
    <Route
      exact
      path="/waste-containers/logs/:id"
      render={props => <LogsInfo typeOfProduct="waste-containers" {...props} />}
    />
    <Route exact path="/waste-containers/sensors/:id" component={SensorDataContainer} />
    <Route exact path="/waste-containers/manage/:id" component={ManageDataContainer} />
  </Switch>
);
