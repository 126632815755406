import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Col,
} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import history from '../../../shared/helpers/history';
import TableHeaderMaintenanceSpareParts from './TableHeaderMaintenanceSpareParts';

let counter = 0;
function createData(pantheonId, name, id, isDeletable) {
  counter += 1;

  return {
    id: counter,
    pantheon_id: pantheonId,
    name,
    details: id,
    is_deletable: isDeletable,
  };
}

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class ActiveMaintenanceSparePartsList extends PureComponent {
  constructor(props) {
    super(props);
    const { sparePartsList } = this.props;

    this.state = {
      order: 'desc',
      orderBy: 'id',
      data: sparePartsList.map(item => createData(
        item.pantheon_id,
        item.name,
        item.pantheon_id,
        item.is_deletable,
      )),
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleClick = (event) => {
    if (event.target.id === 'thisIsLink') history.push(event.target.link);
  };

  render() {
    const {
      data, order, orderBy,
    } = this.state;

    const { handleDelete } = this.props;


    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">List of spare parts</h5>
            </div>
            <div className="material-table__wrap">
              <Table className="material-table">
                <TableHeaderMaintenanceSpareParts
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                />
                <TableBody>
                  {data
                    .sort(getSorting(order, orderBy))
                    .map(d => (
                      <TableRow
                        className="material-table__row"
                        role="checkbox"
                        onClick={event => this.handleClick(event, d.id)}
                        tabIndex={-1}
                        key={d.id}
                      >
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {d.pantheon_id}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >{d.name}
                        </TableCell>
                        <TableCell className="material-table__cell material-table__cell-right">
                          <button
                            type="button"
                            onClick={() => handleDelete(d.id)}
                            id="thisIsLink"
                            disabled={!d.is_deletable}
                            style={{
                              background: 'transparent', border: 'none', textDecoration: 'underline', color: 'blues',
                            }}
                          >delete
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

ActiveMaintenanceSparePartsList.propTypes = {
  sparePartsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ]))).isRequired,
  handleDelete: PropTypes.func.isRequired,
};
