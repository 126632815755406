import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const CompaniesListTable = ({
  companiesList, dispatch, companyVerificationSuccess, error,
}) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody className="products-list">
        <TableHeader />
        <TableBody
          dispatch={dispatch}
          companiesList={companiesList}
          companyVerificationSuccess={companyVerificationSuccess}
          error={error}
        />
      </CardBody>
    </Card>
  </Col>
);

CompaniesListTable.propTypes = {
  companiesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  companyVerificationSuccess: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool,
  ])).isRequired,
  error: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default CompaniesListTable;
