/* eslint-disable consistent-return */
import moment from 'moment';
import {
  SELECT_SENSOR_FOR_SENSE_APP,
  DESELECT_SENSOR_FOR_SENSE_APP,
  FETCH_SENSORS_REQUEST,
  FETCH_SENSORS_SUCCESS,
  FETCH_SENSORS_FAILURE,
  SELECT_DATE_FOR_SENSE_APP,
  FETCH_SENSORS_MONTH_SUCCESS,
  FETCH_SENSORS_WEEK_SUCCESS,
  FETCH_SENSORS_CUSTOM_SUCCESS,
  FETCH_SENSORS_YESTERDAY_SUCCESS,
  REMOVE_SENSOR_DATA,
  RESET_SELECTED_SENSOR_FOR_SENSE_APP,
} from '../actions/sensorAppActions';

const now = moment().format('DD.MM.YYYY');

const initialState = {
  selectedSensors: [],
  sensorsData: [],
  sensorsDataYesterday: [],
  sensorsDataWeek: [],
  sensorsDataMonth: [],
  sensorsDataCustom: [],
  selectedTimePeriod: {
    startDate: now,
    endDate: now,
    title: 'today',
  },
};

function senseAppReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_SENSOR_FOR_SENSE_APP:
      return {
        ...state,
        selectedSensors: [...state.selectedSensors, action.payload],
        loading: false,
      };
    case DESELECT_SENSOR_FOR_SENSE_APP:
      return {
        ...state,
        selectedSensors: state.selectedSensors.filter(item => item !== action.payload),
      };
    case RESET_SELECTED_SENSOR_FOR_SENSE_APP:
      return {
        ...state,
        selectedSensors: [],
        sensorsData: [],
        sensorsDataMonth: [],
        sensorsDataWeek: [],
        sensorsDataYesterday: [],
        sensorsDataCustom: [],
        selectedTimePeriod: {
          startDate: now,
          endDate: now,
          title: 'today',
        },
        loading: false,
      };
    case FETCH_SENSORS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SENSORS_SUCCESS:
      if (!state.sensorsData.some(item => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          sensorsData: [...state.sensorsData, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
        sensorData: [],
        loading: false,
      };
    case FETCH_SENSORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECT_DATE_FOR_SENSE_APP:
      return {
        ...state,
        selectedTimePeriod: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          title: action.payload.title,
        },
      };
    case FETCH_SENSORS_MONTH_SUCCESS:
      if (!state.sensorsDataMonth.some(item => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          sensorsDataMonth: [...state.sensorsDataMonth, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
      };
    case FETCH_SENSORS_CUSTOM_SUCCESS: {
      const currentState = state.sensorsDataCustom.filter(item => item.module_id !== action.payload.module_id);
      return {
        ...state,
        sensorsDataCustom: [...currentState, action.payload],
        loading: false,
      };
    }
    case FETCH_SENSORS_WEEK_SUCCESS:
      if (!state.sensorsDataWeek.some(item => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          sensorsDataWeek: [...state.sensorsDataWeek, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
      };
    case FETCH_SENSORS_YESTERDAY_SUCCESS:
      if (!state.sensorsDataYesterday.some(item => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          sensorsDataYesterday: [...state.sensorsDataYesterday, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
      };
    case REMOVE_SENSOR_DATA:
      return {
        ...state,
        sensorsData: state.sensorsData.filter(item => item.module_id !== action.payload),
        sensorsDataYesterday: state.sensorsDataYesterday.filter(item => item.module_id !== action.payload),
        sensorsDataWeek: state.sensorsDataWeek.filter(item => item.module_id !== action.payload),
        sensorsDataMonth: state.sensorsDataMonth.filter(item => item.module_id !== action.payload),
        sensorsDataCustom: state.sensorsDataCustom.filter(item => item.module_id !== action.payload),
        loading: false,
      };
    default:
      return state;
  }
}

export default senseAppReducer;
