/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
  Card, CardBody, Input,
} from 'reactstrap';
import { useDispatch, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SensorInfo from './SensorInfo';
import TimePeriodSelector from './TimePeriodSelector';
import fetchSensors from '../../api/sensorsApi';
import { fetchDeployedProductInfo } from '../../api/productInfoApi';
import {
  selectSensor, deSelectSensor, removeSensorData, resetSelectedSensor,
} from '../../redux/actions/sensorAppActions';
import { UserProps } from '../../shared/prop-types/ReducerProps';
import SensorInfoForAQI from './SensorInfoForAQI';
import SensorInfoForWindDirection from './SensorInfoForWindDirection';

const createArrayOfObjects = (keys, values) => {
  if (keys.length !== values.length) {
    return [];
  }

  const result = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const obj = {};
    obj[keys[i]] = values[i];
    result.push(obj);
  }

  return result;
};

const removeDuplicates = (inputArray) => {
  const seenValues = {};
  const resultArray = [];

  for (const obj of inputArray) {
    const value = Object.values(obj)[0]; // Extract the value from the object
    if (!seenValues[value]) {
      seenValues[value] = true;
      resultArray.push(obj);
    }
  }

  return resultArray;
};

const SensorDataContainer = ({
  sensors, selectedProduct, match, user,
}) => {
  const dispatch = useDispatch();
  const {
    selectedSensors, selectedTimePeriod, sensorsData,
    sensorsDataYesterday, sensorsDataWeek, sensorsDataMonth,
    sensorsDataCustom,
  } = sensors;

  const handleChange = (moduleId, sensorName) => {
    // remove sensor

    if (selectedSensors.includes(sensorName)) {
      dispatch(deSelectSensor(sensorName));
      if (sensorName === 'air-quality' && selectedProduct.slug === 'aerys-s-monitor') {
        dispatch(removeSensorData(45));
        dispatch(removeSensorData(44));
        dispatch(removeSensorData(43));
        dispatch(removeSensorData(42));
        dispatch(removeSensorData(51));
      }
      if (moduleId === 45) {
        dispatch(removeSensorData(45));
        dispatch(removeSensorData(44));
        dispatch(removeSensorData(43));
        dispatch(removeSensorData(42));
        dispatch(removeSensorData(51));
        // temperature,humidity and pressure are under one sensor---> PHT
      } else if (sensorName === 'micromobility') {
        dispatch(removeSensorData(35));
        dispatch(removeSensorData(36));
      } else dispatch(removeSensorData(moduleId));
    } else if (sensorName === 'PHT' && selectedSensors.includes('temperature')
    && selectedSensors.includes('humidity') && selectedSensors.includes('pressure')) {
      dispatch(deSelectSensor('temperature'));
      dispatch(deSelectSensor('humidity'));
      dispatch(deSelectSensor('pressure'));
      dispatch(removeSensorData(22));
    } else if (sensorName === 'terra' && selectedSensors.includes('disposal') && selectedSensors.includes('terra')) {
      dispatch(deSelectSensor('terra'));
      dispatch(deSelectSensor('disposal'));
      dispatch(removeSensorData(48));
    } else if (sensorName === 'micromobility') {
      // micromobility -we need to call api 2 times for "cyclo module"->35 and for "bike rack"->36 module
      dispatch(selectSensor(sensorName));
      dispatch(fetchSensors(selectedProduct.id, moduleId, 'today'));
      dispatch(fetchSensors(selectedProduct.id, moduleId, 'yesterday'));
      dispatch(fetchSensors(selectedProduct.id, moduleId, 'week'));
      dispatch(fetchSensors(selectedProduct.id, moduleId, 'month'));
      dispatch(fetchSensors(selectedProduct.id, moduleId, 'custom', selectedTimePeriod));


      dispatch(fetchSensors(selectedProduct.id, 36, 'today'));
      dispatch(fetchSensors(selectedProduct.id, 36, 'yesterday'));
      dispatch(fetchSensors(selectedProduct.id, 36, 'week'));
      dispatch(fetchSensors(selectedProduct.id, 36, 'month'));
      dispatch(fetchSensors(selectedProduct.id, 36, 'custom', selectedTimePeriod));

      // PHT module is 22
    } else if (moduleId === 22) {
      dispatch(selectSensor('temperature'));
      dispatch(selectSensor('humidity'));
      dispatch(selectSensor('pressure'));
      dispatch(fetchSensors(selectedProduct.id, 22, 'today'));
      dispatch(fetchSensors(selectedProduct.id, 22, 'yesterday'));
      dispatch(fetchSensors(selectedProduct.id, 22, 'week'));
      dispatch(fetchSensors(selectedProduct.id, 22, 'month'));
      dispatch(fetchSensors(selectedProduct.id, 22, 'custom', selectedTimePeriod));
    } else if (moduleId === 48) {
      dispatch(selectSensor('terra'));
      dispatch(selectSensor('disposal'));
      dispatch(fetchSensors(selectedProduct.id, 48, 'today'));
      dispatch(fetchSensors(selectedProduct.id, 48, 'yesterday'));
      dispatch(fetchSensors(selectedProduct.id, 48, 'week'));
      dispatch(fetchSensors(selectedProduct.id, 48, 'month'));
      dispatch(fetchSensors(selectedProduct.id, 48, 'custom', selectedTimePeriod));
    } else if (sensorName === 'air-quality') {
      const deployedModules = selectedProduct.deployed_modules
       && selectedProduct.deployed_modules.filter(
         item => item.module_id === 45 || item.module_id === 44 || item.module_id === 43
         || item.module_id === 42 || item.module_id === 51,
       );
      dispatch(selectSensor(sensorName));

      if (deployedModules.length > 0) {
        deployedModules.forEach((item) => {
          dispatch(fetchSensors(selectedProduct.id, item.module_id, 'today'));
          dispatch(fetchSensors(selectedProduct.id, item.module_id, 'yesterday'));
          dispatch(fetchSensors(selectedProduct.id, item.module_id, 'week'));
          dispatch(fetchSensors(selectedProduct.id, item.module_id, 'month'));
          dispatch(fetchSensors(selectedProduct.id, item.module_id, 'custom', selectedTimePeriod));
        });
      }
    } else {
      dispatch(selectSensor(sensorName));
      dispatch(fetchSensors(selectedProduct.id, moduleId, 'today'));
      dispatch(fetchSensors(selectedProduct.id, moduleId, 'yesterday'));
      dispatch(fetchSensors(selectedProduct.id, moduleId, 'week'));
      dispatch(fetchSensors(selectedProduct.id, moduleId, 'month'));
      dispatch(fetchSensors(selectedProduct.id, moduleId, 'custom', selectedTimePeriod));
    }
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;
    dispatch(fetchDeployedProductInfo(id));
    dispatch(resetSelectedSensor());
  }, [dispatch]);

  const singleProductSensorReport = [];

  if (sensorsData.length > 0) {
    let dataToDisplay = sensorsData;
    if (selectedTimePeriod.title === 'yesterday') dataToDisplay = sensorsDataYesterday;
    if (selectedTimePeriod.title === 'week') dataToDisplay = sensorsDataWeek;
    if (selectedTimePeriod.title === 'month') dataToDisplay = sensorsDataMonth;
    if (selectedTimePeriod.title === 'custom') dataToDisplay = sensorsDataCustom;

    dataToDisplay.forEach((data) => {
      if (data.sensor_info && data.sensor_info.length > 0) {
        const filtered = data.sensor_info.filter(sensor => selectedSensors.includes(sensor.sensor_group_slug));
        filtered.forEach((element) => {
          const myElement = element;
          // show dates on graph only on longer date range
          if (
            selectedTimePeriod.title === 'week'
            || selectedTimePeriod.title === 'month'
            || selectedTimePeriod.title === 'custom'
          ) {
            myElement.showDate = true;
          } else myElement.showDate = false;

          myElement.deviceId = data.device_id;
          singleProductSensorReport.push(myElement);
        });
      }
    });
  }
  // all sensors which return data
  const allSensorsIds = [4, 5, 9, 10, 11, 22, 35, 37, 40, 45, 46, 47, 48, 50];
  const allIdsAerysS = [4, 5, 9, 10, 11, 22, 35, 37, 40, 45, 46, 47, 48, 50, 42, 43, 44, 51];
  // all sensors from product
  const deployedSensorsIds = selectedProduct && selectedProduct.deployed_modules
     && selectedProduct.deployed_modules.map(item => item.module_id);
  // sensors from product with data (can be multiplied exp. usb chargers)
  const sensorsWithData = deployedSensorsIds && deployedSensorsIds.filter(element => allSensorsIds.includes(element));
  const sensorsWithDataAerysS = deployedSensorsIds
  && deployedSensorsIds.filter(element => allIdsAerysS.includes(element));
  // unique ids
  const uniqueSensorsIds = sensorsWithData && sensorsWithData.filter((v, i, a) => a.indexOf(v) === i);
  const uniqueSensorsIdsAerysS = sensorsWithDataAerysS && sensorsWithDataAerysS.filter((v, i, a) => a.indexOf(v) === i);

  // type s
  const isAerysS = selectedProduct.slug === 'aerys-s-monitor';

  const uniqueSensorsNames = [];
  if (!isAerysS && uniqueSensorsIds) {
    for (let i = 0; i < uniqueSensorsIds.length; i += 1) {
      if (uniqueSensorsIds[i] === 4) uniqueSensorsNames.push('usb-charging');
      else if (uniqueSensorsIds[i] === 5) uniqueSensorsNames.push('wireless-charging');
      else if (uniqueSensorsIds[i] === 9) uniqueSensorsNames.push('bench-users');
      else if (uniqueSensorsIds[i] === 10) uniqueSensorsNames.push('gas');
      else if (uniqueSensorsIds[i] === 11 || uniqueSensorsIds[i] === 40) uniqueSensorsNames.push('noise');
      else if (uniqueSensorsIds[i] === 22) uniqueSensorsNames.push('PHT');
      else if (uniqueSensorsIds[i] === 35) uniqueSensorsNames.push('micromobility');
      else if (uniqueSensorsIds[i] === 37) uniqueSensorsNames.push('particulate-matter');
      else if (uniqueSensorsIds[i] === 45 && !isAerysS) uniqueSensorsNames.push('air-quality');
      else if (uniqueSensorsIds[i] === 46) uniqueSensorsNames.push('flowerpot-soil-moisture');
      else if (uniqueSensorsIds[i] === 47) uniqueSensorsNames.push('terra-users');
      else if (uniqueSensorsIds[i] === 48) uniqueSensorsNames.push('terra');
      else if (uniqueSensorsIds[i] === 50) uniqueSensorsNames.push('energy-produced');
    }
  } else if (isAerysS && uniqueSensorsIdsAerysS) {
    for (let i = 0; i < uniqueSensorsIdsAerysS.length; i += 1) {
      if (uniqueSensorsIdsAerysS[i] === 4) uniqueSensorsNames.push('usb-charging');
      else if (uniqueSensorsIdsAerysS[i] === 5) uniqueSensorsNames.push('wireless-charging');
      else if (uniqueSensorsIdsAerysS[i] === 9) uniqueSensorsNames.push('bench-users');
      else if (uniqueSensorsIdsAerysS[i] === 10) uniqueSensorsNames.push('gas');
      else if (uniqueSensorsIdsAerysS[i] === 11 || uniqueSensorsIdsAerysS[i] === 40) uniqueSensorsNames.push('noise');
      else if (uniqueSensorsIdsAerysS[i] === 22) uniqueSensorsNames.push('PHT');
      else if (uniqueSensorsIdsAerysS[i] === 35) uniqueSensorsNames.push('micromobility');
      else if (uniqueSensorsIdsAerysS[i] === 37) uniqueSensorsNames.push('particulate-matter');
      else if (uniqueSensorsIdsAerysS[i] === 42 || uniqueSensorsIdsAerysS[i] === 43
        || uniqueSensorsIdsAerysS[i] === 44 || uniqueSensorsIdsAerysS[i] === 45
         || uniqueSensorsIdsAerysS[i] === 51) uniqueSensorsNames.push('air-quality');
      else if (uniqueSensorsIdsAerysS[i] === 46) uniqueSensorsNames.push('flowerpot-soil-moisture');
      else if (uniqueSensorsIdsAerysS[i] === 47) uniqueSensorsNames.push('terra-users');
      else if (uniqueSensorsIdsAerysS[i] === 48) uniqueSensorsNames.push('terra');
      else if (uniqueSensorsIdsAerysS[i] === 50) uniqueSensorsNames.push('energy-produced');
    }
  }

  const objectArray = uniqueSensorsIdsAerysS && uniqueSensorsNames && uniqueSensorsIdsAerysS.length > 0
  && uniqueSensorsNames.length > 0 && createArrayOfObjects(uniqueSensorsIdsAerysS, uniqueSensorsNames);

  const sensorNames = objectArray && removeDuplicates(objectArray);

  const aqiSingleProduct = singleProductSensorReport.filter(item => item.name === 'Air Quality Index');

  const productTypeSlug = selectedProduct && selectedProduct.product_type && selectedProduct.product_type.slug;

  return (
    <Container>
      <Row>
        <Col md={6}>
          <h3 className="page-title">Sensor data</h3>
        </Col>
        <Col md={6}>
          <Link
            className="btn btn-primary account__btn sensor-btn-display"
            to={`/${productTypeSlug}/logs/${selectedProduct.id}`}
          >All logs
          </Link>
          {user.permissions && user.permissions.includes('manage.edit')
          && (
          <Link
            className="btn btn-primary account__btn sensor-btn-display"
            to={`/${productTypeSlug}/manage/${selectedProduct.id}`}
          >Manage data
          </Link>
          )}
          <Link
            className="btn btn-primary account__btn sensor-btn-display"
            to={`/${productTypeSlug}/${selectedProduct.id}`}
          >Details
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="sensors__info-wrap">
                <h4>{selectedProduct.device_id}</h4>
                {selectedProduct && selectedProduct.id
                && (
                <TimePeriodSelector
                  selectedTimePeriod={selectedTimePeriod}
                  selectedProducts={[selectedProduct]}
                  selectedSensors={selectedSensors}
                />
                )}
                <div className="sensors-picker">
                  {!isAerysS && uniqueSensorsIds
                && uniqueSensorsIds.map((item, key) => (
                  <div key={item}>
                    <Input
                      type="checkbox"
                      name="sensorSelector"
                      id={item}
                      value={item}
                      onChange={() => handleChange(item, uniqueSensorsNames[key])}
                    />
                    <label className="label-checkbox" htmlFor={item}>
                      {uniqueSensorsNames[key] === 'terra-users' ? 'NFC' : uniqueSensorsNames[key].split('-').join(' ')}
                    </label>
                  </div>
                ))}
                  {isAerysS && sensorNames
                && sensorNames.map((item) => {
                  const id = Object.keys(item)[0];
                  const title = item[id];
                  return (
                    <div key={id}>
                      <Input
                        type="checkbox"
                        name="sensorSelector"
                        id={id}
                        value={id}
                        onChange={() => handleChange(Number(id), title)}
                      />
                      <label className="label-checkbox" htmlFor={id}>
                        {title === 'terra-users' ? 'NFC' : title.split('-').join(' ')}
                      </label>
                    </div>
                  );
                })}
                </div>
                {aqiSingleProduct.length > 0
          && aqiSingleProduct.map(item => item.name === 'Air Quality Index'
          && (<SensorInfoForAQI sensorData={item} key={item.name} />))}
                {singleProductSensorReport.length > 0
          && singleProductSensorReport.map(item => item.name === 'wind direction'
          && (<SensorInfoForWindDirection sensorData={item} key={item.name} />))}
                {singleProductSensorReport.length > 0
          && singleProductSensorReport.map(item => item.name !== 'Air Quality Index' && item.name !== 'wind direction'
          && (<SensorInfo sensorData={item} key={item.name} />))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

SensorDataContainer.propTypes = {
  sensors: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])).isRequired,
  selectedProduct: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool,
  ])),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  user: UserProps.isRequired,
};

SensorDataContainer.defaultProps = {
  selectedProduct: {},
};

const mapStateToProps = state => ({
  sensors: state.sensors,
  selectedProduct: state.productInfo.productDetails,
  user: state.user.userInfo,
});

export default connect((mapStateToProps))(SensorDataContainer);
