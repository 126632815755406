/* eslint-disable no-return-assign */

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import CompanyProductsList from './components/CompanyProductsList';
import { addProductToCompany, fetchProductsInCompany } from '../../api/companiesApi';
import ProductActionsModal from '../../components/Modal/ProductActionsModal';
import { UserProps } from '../../shared/prop-types/ReducerProps';

const CompanyProductsContainer = ({ companyProducts, user, companyActionsResponse }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [confirmModalAddProduct, toggleModalAddProduct] = useState(false);

  const openModalAddProduct = () => {
    toggleModalAddProduct(true);
  };

  const addProduct = (activationCode) => {
    toggleModalAddProduct(false);
    dispatch(addProductToCompany(id, activationCode));
  };

  useEffect(() => {
    dispatch(fetchProductsInCompany(id));
  }, [dispatch, id]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={10}>
          <h3 className="page-title">List of products</h3>
        </Col>
        <Col md={2}>
          {user.permissions && user.permissions.includes('customers.edit')
              && (
              <button type="button" className="btn-add-new" onClick={() => openModalAddProduct()}>
                <svg className="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                </svg>
                Add new product
              </button>
              )}
        </Col>
      </Row>
      <Row>
        {companyProducts.length > 0
          ? (
            <CompanyProductsList
              companyActionsResponse={companyActionsResponse}
              products={companyProducts}
              dispatch={dispatch}
              user={user}
            />
          )
          : (<p> There are no products on this company.</p>
          )}
      </Row>
      <ProductActionsModal
        modalOpen={confirmModalAddProduct}
        onCloseClick={toggleModalAddProduct}
        message="Are you sure you want to add product to company?"
        handleConfirm={addProduct}
        formType="add-form"
      />
    </Container>
  );
};


CompanyProductsContainer.propTypes = {
  companyProducts: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  user: UserProps.isRequired,
  companyActionsResponse: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  companyProducts: state.companies.companyProducts,
  companyActionsResponse: state.companies.companyActionsResponse,
  user: state.user.userInfo,
});


export default connect((mapStateToProps))(CompanyProductsContainer);
