/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Loading from '../../../shared/components/Loading';
import ActiveMaintenanceList from '../../../components/ProductDisplayData/components/ActiveMaintenanceList';
import {
  fetchDeployedProductsByTypeMaintenance, searchDeployedProductsMaintenance, fetchMaintenanceData,
} from '../../../api/productsApi';

class MaintenanceList extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMaintenanceData());
  }

  handlePagination = (page) => {
    const { dispatch } = this.props;
    dispatch(fetchDeployedProductsByTypeMaintenance(page));
  };

  handleSearchMaintenance = (e, searchTerm) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(searchDeployedProductsMaintenance(searchTerm));
  }

  reRenderPage = () => {
    const { dispatch } = this.props;
    dispatch(fetchDeployedProductsByTypeMaintenance());
  }

  render() {
    const {
      maintenanceList, loading, paginationOptions, searchResultsMessage,
    } = this.props;

    return (
      <Container>
        <Row>
          <Col xs={10}>
            <h3 className="page-title">Maintenance</h3>
          </Col>
          <Col xs={2}>
            <Link
              className="btn btn-primary account__btn sensor-btn-display"
              to="/maintenance/add-new"
            >Add new
            </Link>
          </Col>
        </Row>
        <Row>
          {loading ? (
            <Loading loading={loading} />
          )
            : maintenanceList.length > 0 ? (
              <ActiveMaintenanceList
                key={maintenanceList.length}
                maintenanceList={maintenanceList}
                paginationOptions={paginationOptions}
                handlePagination={this.handlePagination}
                handleSearchProducts={this.handleSearchMaintenance}
                searchResultsMessage={searchResultsMessage}
                handleGoBack={this.reRenderPage}
                typeOfProduct="maintenance"
              />
            )
              : (
                <Col>
                  <Card>
                    <CardBody>
                      <div>No active Maintenance found</div>
                    </CardBody>
                  </Card>
                </Col>
              )
          }
        </Row>
      </Container>
    );
  }
}

MaintenanceList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  maintenanceList: PropTypes.arrayOf(PropTypes.object).isRequired,
  paginationOptions: PropTypes.objectOf(PropTypes.node).isRequired,
  searchResultsMessage: PropTypes.string.isRequired,
  // maintenanceById: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  maintenanceList: state.products.maintenanceList,
  searchResultsMessage: state.products.searchResultsMessage,
  paginationOptions: state.products.maintenanceListPagination,
  loading: state.products.loading,
});

export default connect(mapStateToProps)(MaintenanceList);
