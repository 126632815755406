import React, { useState } from 'react';
import {
  Col, Card, CardBody, Button,
} from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../Modal/ConfirmModal';

const UsageSummary = ({
  discovery, report, errorDiscovery, errorCommunication, productDetails,
  handleForceDiscovery, data,
}) => {
  const [openModal, toggleModal] = useState(false);

  const forceDiscovery = () => {
    toggleModal(false);
    handleForceDiscovery(data.deployed_product_id);
  };
  return (
    <>
      <Col lg={6}>
        <Card>
          <CardBody>
            <h3>Last communication and discovery:
              <Link
                className="btn btn-primary account__btn last-comm-btn"
                to={`logs/${productDetails.id}`}
              >All logs
              </Link>
            </h3>
            {errorCommunication ? (<p><strong>Last communication:</strong> {errorCommunication}</p>) : (
              <div>
                <p><strong>Last communication date:</strong>
                  {moment(report.created_at).format('DD.MM.YYYY   HH:mm')}
                </p>
                <p><strong>Last message received:</strong> <code>{report.msg}</code></p>
              </div>
            )
        }
            <hr />
            {errorDiscovery
              ? (<p><strong>Last discovery:</strong> {errorDiscovery}</p>) : (
                <div>
                  <p><strong>Last discovery date:</strong>
                    {moment(discovery.created_at).format('DD.MM.YYYY   HH:mm')}
                  </p>
                  <p><strong>Last discovery message received:</strong> <code>{discovery.msg}</code></p>
                </div>
              )
        }
            <div className="delete-data">
              <Button color="primary" className="icon profile__btn" onClick={() => toggleModal(true)}>
                Force discovery
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
      <ConfirmModal
        modalOpen={openModal}
        onCloseClick={toggleModal}
        message={`Are you sure you want to do force rediscovery
         for product with serial number ${productDetails.device_id}?`}
        handleConfirm={forceDiscovery}
      />
    </>
  );
};

UsageSummary.propTypes = {
  discovery: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
  ])).isRequired,
  report: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
  ])).isRequired,
  errorDiscovery: PropTypes.string.isRequired,
  errorCommunication: PropTypes.string.isRequired,
  productDetails: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object, PropTypes.number,
  ])).isRequired,
  handleForceDiscovery: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object, PropTypes.number,
  ])).isRequired,
};


export default UsageSummary;
