/* eslint-disable no-return-assign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import isEmpty from 'lodash/isEmpty';
import { BasicNotification } from '../../shared/components/Notification';
import createNotification from '../../api/notificationsApi';
import validate from './addNotificationValidator';
import history from '../../shared/helpers/history';

let notification = null;

const showNotification = (title, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={message.includes('created') ? 'success' : 'danger'}
    />,
    duration: 6,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up ltr-support',
  });
};

class AddNotificationForm extends PureComponent {
  static propTypes = {
    reset: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    eventFormData: PropTypes.objectOf((PropTypes.oneOfType([
      PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.array,
    ]))),
    createdNotificationResponse: PropTypes.string,
  };

  constructor() {
    super();
    this.state = {
      validationErrors: {
        title: '',
        body: '',
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { createdNotificationResponse } = this.props;

    if (prevProps.createdNotificationResponse !== createdNotificationResponse) {
      const title = createdNotificationResponse.includes('created') ? 'New global notification created' : 'Api error';
      const message = { createdNotificationResponse };
      NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
      setTimeout(() => showNotification(title, message), 10);
      history.push('/');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { eventFormData, dispatch } = this.props;
    const { validationErrors } = this.state;


    const valuesToValidate = {
      title: eventFormData.values && eventFormData.values.title,
      body: eventFormData.values && eventFormData.values.body,
    };
    const errors = validate(valuesToValidate);

    if (isEmpty(errors)) {
      const params = {
        title: eventFormData.values.title,
        body: eventFormData.values.body,
      };
      dispatch(createNotification(params));
      history.push('/');
    } else {
      this.setState({
        validationErrors: {
          ...validationErrors,
          title: errors.title,
          body: errors.body,
        },
      });
    }
  }

  render() {
    const { reset } = this.props;
    const { validationErrors } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Add new notification</h5>
            </div>
            <form className="form form--horizontal" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Title
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="title"
                    component="input"
                    type="text"
                    placeholder="Title"
                  />
                </div>
                { validationErrors.title
                  && <span className="form__form-group-error"> {validationErrors.title} </span>}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  <span className="news__new-star">*</span>
                  Message
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="body"
                    component="textarea"
                    placeholder="Message"
                  />
                </div>
                { validationErrors.body
                  && <span className="form__form-group-error"> {validationErrors.body} </span>}
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">Submit</Button>
                <Button type="button" onClick={reset}>
                  <Link to="/job-ads">Cancel</Link>
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  rtl: state.rtl,
  createdNotificationResponse: state.notifications.createdNotificationResponse,
  eventFormData: state.form.newNotificationForm,
});

AddNotificationForm.defaultProps = {
  eventFormData: {},
  createdNotificationResponse: '',
};

const NewNotificationFormComponent = reduxForm({
  form: 'newNotificationForm',
})(AddNotificationForm);

export default connect(mapStateToProps)(withTranslation('common')(NewNotificationFormComponent));
