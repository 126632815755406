/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../../../shared/components/Loading';
import ActiveAerysList from '../../../components/ProductDisplayData/components/ActiveProductsList';
import { fetchDeployedProductsByType, searchDeployedProducts } from '../../../api/productsApi';

class AerysSensors extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchDeployedProductsByType('aerys'));
  }

  handlePagination = (page) => {
    const { dispatch } = this.props;
    dispatch(fetchDeployedProductsByType('aerys', page));
  };

  handleSearchAerys = (e, searchTerm) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(searchDeployedProducts('aerys', searchTerm));
  }

  reRenderPage = () => {
    const { dispatch } = this.props;
    dispatch(fetchDeployedProductsByType('aerys'));
  }

  render() {
    const {
      aerysList, loading, paginationOptions, searchResultsMessage,
    } = this.props;

    return (
      <Container>
        <Row>
          <Col>
            <h3 className="page-title">Aerys Sensors</h3>
          </Col>
        </Row>
        <Row>
          {loading ? (
            <Loading loading={loading} />
          )
            : aerysList.length > 0 ? (
              <ActiveAerysList
                key={aerysList.length}
                productsList={aerysList}
                paginationOptions={paginationOptions}
                handlePagination={this.handlePagination}
                handleSearchProducts={this.handleSearchAerys}
                searchResultsMessage={searchResultsMessage}
                handleGoBack={this.reRenderPage}
                typeOfProduct="aerys"
              />
            )
              : (
                <Col>
                  <Card>
                    <CardBody>
                      <div>No active Aerys product found</div>
                    </CardBody>
                  </Card>
                </Col>
              )
          }
        </Row>
      </Container>
    );
  }
}

AerysSensors.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  aerysList: PropTypes.arrayOf(PropTypes.object).isRequired,
  paginationOptions: PropTypes.objectOf(PropTypes.node).isRequired,
  searchResultsMessage: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  aerysList: state.products.deployedAerys,
  searchResultsMessage: state.products.searchResultsMessage,
  paginationOptions: state.products.aerysListPagination,
  loading: state.products.loading,
});

export default connect(mapStateToProps)(AerysSensors);
