export const FETCH_USER_DATA_REQUEST = 'FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE';
export const FETCH_ALL_USERS_DATA_REQUEST = 'FETCH_ALL_USERS_DATA_REQUEST';
export const FETCH_ALL_USERS_DATA_SUCCESS = 'FETCH_ALL_USERS_DATA_SUCCESS';
export const FETCH_ALL_USERS_DATA_FAILURE = 'FETCH_ALL_USERS_DATA_FAILURE';
export const FETCH_OTHER_USER_INFO = 'FETCH_OTHER_USER_INFO';
export const ASSIGN_PERMISSIONS_TO_USER_REQUEST = 'ASSIGN_PERMISSIONS_TO_USER_REQUEST';
export const ASSIGN_PERMISSIONS_TO_USER_SUCCESS = 'ASSIGN_PERMISSIONS_TO_USER_SUCCESS';
export const ASSIGN_PERMISSIONS_TO_USER_FAILURE = 'ASSIGN_PERMISSIONS_TO_USER_FAILURE';

export function fetchUserDataRequest() {
  return {
    type: FETCH_USER_DATA_REQUEST,
  };
}

export function fetchUserDataSuccess(user) {
  return {
    type: FETCH_USER_DATA_SUCCESS,
    payload: user,
  };
}

export function fetchUserDataFailure(error) {
  return {
    type: FETCH_USER_DATA_FAILURE,
    payload: error,
  };
}

export function fetchAllUsersDataRequest() {
  return {
    type: FETCH_ALL_USERS_DATA_REQUEST,
  };
}

export function fetchAllUsersDataSuccess(data) {
  return {
    type: FETCH_ALL_USERS_DATA_SUCCESS,
    payload: data,
  };
}

export function fetchAllUsersDataFailure(error) {
  return {
    type: FETCH_ALL_USERS_DATA_FAILURE,
    payload: error,
  };
}

export function fetchOtherUserInfo(user) {
  return {
    type: FETCH_OTHER_USER_INFO,
    payload: user,
  };
}

export function assignPermissionsToUserRequest() {
  return {
    type: ASSIGN_PERMISSIONS_TO_USER_REQUEST,
  };
}

export function assignPermissionsToUserSuccess(data) {
  return {
    type: ASSIGN_PERMISSIONS_TO_USER_SUCCESS,
    payload: data,
  };
}

export function assignPermissionsToUserFailure(error) {
  return {
    type: ASSIGN_PERMISSIONS_TO_USER_FAILURE,
    payload: error,
  };
}
