import {
  CREATE_MAINTENANCE_REQUEST,
  CREATE_MAINTENANCE_SUCCESS,
  CREATE_MAINTENANCE_FAILURE,
  GET_MAINTENANCE_SUCCESS,
  GET_MAINTENANCE_BY_ID_REQUEST,
  GET_MAINTENANCE_BY_ID_FAILURE,
  GET_MAINTENANCE_BY_ID_SUCCESS,
  GET_ALL_SPARE_PARTS_FAILURE,
  GET_ALL_SPARE_PARTS_REQUEST,
  GET_ALL_SPARE_PARTS_SUCCESS,
  GET_SPARE_PART_BY_PRODUCT_ID_REQUEST,
  GET_SPARE_PART_BY_PRODUCT_ID_FAILURE,
  GET_SPARE_PART_BY_PRODUCT_ID_SUCCESS,
} from '../actions/maintenanceAction';

const initialState = {
  error: null,
  loading: false,
  createMaintenanceResponse: '',
  maintenanceDeployedProduct: [],
  maintenanceById: {},
  spareParts: [],
  sparePartsByProductId: [],
};

function subscribersReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MAINTENANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MAINTENANCE_SUCCESS:
      return {
        ...state,
        createMaintenanceResponse: action.payload,
        loading: false,
      };
    case CREATE_MAINTENANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        maintenanceDeployedProduct: action.payload,
      };
    case GET_MAINTENANCE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MAINTENANCE_BY_ID_SUCCESS:
      return {
        ...state,
        maintenanceById: action.payload,
        loading: false,
      };
    case GET_MAINTENANCE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ALL_SPARE_PARTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SPARE_PARTS_SUCCESS:
      return {
        ...state,
        spareParts: action.payload,
        loading: false,
      };
    case GET_ALL_SPARE_PARTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SPARE_PART_BY_PRODUCT_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SPARE_PART_BY_PRODUCT_ID_SUCCESS:
      return {
        ...state,
        sparePartsByProductId: action.payload,
        loading: false,
      };
    case GET_SPARE_PART_BY_PRODUCT_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default subscribersReducer;
