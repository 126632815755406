/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import {
  Card, CardBody, Col, Button, UncontrolledTooltip,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from '../../../shared/components/Notification';
import CustomersProducts from './CustomersProducts';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import ProductActionsModal from '../../../components/Modal/ProductActionsModal';
import history from '../../../shared/helpers/history';
import { fetchSingleCustomerData } from '../../../api/customersApi';
import { UserProps } from '../../../shared/prop-types/ReducerProps';

let notification = null;

const showNotification = (title, message) => {
  notification.notice({
    content: <BasicNotification
      title={title}
      message={message}
      color={message}
    />,
    duration: 6,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up ltr-support',
  });
};

const CustomerProfileCard = ({
  customerInfo, showMailButton, handleDeleteCustomer,
  handleDeactivateCustomer,
  handleSendActivationLink,
  handleAddProduct,
  handleDisable2FA,
  handleRemoveProduct, user,
}) => {
  const dispatch = useDispatch();

  const [confirmModalDeactivateUserOpen, toggleModalDeactivateUser] = useState(false);
  const [confirmModalDeleteUserOpen, toggleModalDeleteUser] = useState(false);
  const [confirmModalActivationLinkOpen, toggleModalActivationLink] = useState(false);
  const [confirmModalRemoveProduct, toggleModalRemoveProduct] = useState(false);
  const [confirmModalAddProduct, toggleModalAddProduct] = useState(false);
  const [confirmModalDisable2FA, toggleModalDisable2FA] = useState(false);


  const openModalDeactivateUser = () => {
    toggleModalDeactivateUser(true);
  };

  const deactivateCustomer = () => {
    toggleModalDeactivateUser(false);
    handleDeactivateCustomer(customerInfo.id);
    const title = 'Deactivated customer';
    const message = `Customer ${customerInfo.name} is deactivated.`;
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
    setTimeout(() => showNotification(title, message), 10);
    history.push('/customers');
  };

  const openModalDeleteUser = () => {
    toggleModalDeleteUser(true);
  };

  const deleteCustomer = () => {
    toggleModalDeleteUser(false);
    handleDeleteCustomer(customerInfo.id);
    const title = 'Deleted customer';
    const message = `Customer ${customerInfo.name} is deleted.`;
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
    setTimeout(() => showNotification(title, message), 10);
    history.push('/customers');
  };

  const openModalActivationLink = () => {
    toggleModalActivationLink(true);
  };

  const sendActivationLink = () => {
    toggleModalActivationLink(false);
    handleSendActivationLink(customerInfo.id);
    const title = 'Activation link has been sent';
    const message = `Activation link has been sent to customer ${customerInfo.name}.`;
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
    setTimeout(() => showNotification(title, message), 10);
  };

  const openModalRemoveProduct = () => {
    toggleModalRemoveProduct(true);
  };

  const removeProduct = (productId) => {
    toggleModalRemoveProduct(false);
    handleRemoveProduct(productId, customerInfo.id);
    const title = 'Remove product from  customer';
    const message = `Product has been removed from customer ${customerInfo.name}.`;
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
    setTimeout(() => showNotification(title, message), 10);
    dispatch(fetchSingleCustomerData(customerInfo.id));
  };

  const openModalAddProduct = () => {
    toggleModalAddProduct(true);
  };

  const addProduct = (activationCode) => {
    toggleModalAddProduct(false);
    handleAddProduct(activationCode, customerInfo.id);
    const title = 'Add product to  customer';
    const message = `Product has been added to customer ${customerInfo.name}.`;
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
    setTimeout(() => showNotification(title, message), 10);
    dispatch(fetchSingleCustomerData(customerInfo.id));
  };

  const openModalDisable2FA = () => {
    toggleModalDisable2FA(true);
  };

  const disable2FA = () => {
    toggleModalDisable2FA(false);
    handleDisable2FA(customerInfo.id);
    const title = 'Disable 2FA for customer';
    const message = `2FA has been disabled for customer ${customerInfo.name}.`;
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notification = n);
    setTimeout(() => showNotification(title, message), 10);
    dispatch(fetchSingleCustomerData(customerInfo.id));
  };

  return (
    <>
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="profile__card">
            <div className="profile__information">
              <div className="profile__data">
                <p className="profile__name">{customerInfo.name}</p>
                <p className="profile__contact">Email: {customerInfo.email}</p>
                <p className="profile__contact">Country: {customerInfo.country.name}</p>
                {customerInfo.company.name
                  ? <p className="profile__contact" dir="ltr">Company: {customerInfo.company.name}</p>
                  : <p className="profile__contact" dir="ltr">User: Private user</p>
            }
                {!!customerInfo.is_company_admin
            && (
            <p className="profile__contact" dir="ltr">Company Role:
              {customerInfo.is_company_admin ? ' Admin' : ' User'}
            </p>
            )
            }
                <p className="profile__contact">Last activity:
                  {customerInfo.last_activity ? customerInfo.last_activity : 'No data'}
                </p>
                { showMailButton && (
                <Button color="primary" className="icon profile__btn">
                  <a href={`mailto:${customerInfo.email}`}><MessageTextOutlineIcon /> Email</a>
                </Button>
                )
            }
              </div>
              {user.permissions && user.permissions.includes('customers.edit')
              && (
              <div className="customers-actions">
                <div>
                  <UncontrolledTooltip placement="top" target="deactivateUser">
                    All customer data remains in database but customer can not log in.
                  </UncontrolledTooltip>
                  <Button
                    type="button"
                    color="warning"
                    className="customer-action"
                    id="deactivateUser"
                    onClick={() => openModalDeactivateUser()}
                  >Deactivate user
                  </Button>
                  <UncontrolledTooltip placement="top" target="deleteUser">
                    All customer data is deleted from database.
                  </UncontrolledTooltip>
                  <Button
                    type="button"
                    color="danger"
                    className="customer-action"
                    id="deleteUser"
                    onClick={() => openModalDeleteUser()}
                  >Delete user
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    className="customer-action"
                    color="primary"
                    onClick={() => openModalActivationLink()}
                  >Resend activation link
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    color="primary"
                    className="customer-action"
                    onClick={() => openModalRemoveProduct()}
                  >Remove product
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="customer-action"
                    onClick={() => openModalAddProduct()}
                  >Add product
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="customer-action"
                    id="disable2FA"
                    onClick={() => openModalDisable2FA()}
                  >Disable 2FA
                  </Button>
                </div>
              </div>
              )}
            </div>
            {customerInfo.products.length > 0
          && <CustomersProducts products={customerInfo.products} />
        }
          </CardBody>
        </Card>
      </Col>
      <ConfirmModal
        modalOpen={confirmModalDeactivateUserOpen}
        onCloseClick={toggleModalDeactivateUser}
        message={`Are you sure you want to deactivate ${customerInfo.name}?`}
        handleConfirm={deactivateCustomer}
      />
      <ConfirmModal
        modalOpen={confirmModalDeleteUserOpen}
        onCloseClick={toggleModalDeleteUser}
        message={`Are you sure you want to delete ${customerInfo.name}?`}
        handleConfirm={deleteCustomer}
      />
      <ConfirmModal
        modalOpen={confirmModalActivationLinkOpen}
        onCloseClick={toggleModalActivationLink}
        message={`Are you sure you want to send activation link to ${customerInfo.name}?`}
        handleConfirm={sendActivationLink}
      />
      <ConfirmModal
        modalOpen={confirmModalDisable2FA}
        onCloseClick={toggleModalDisable2FA}
        message={`Are you sure you want to disable 2FA for ${customerInfo.name}?`}
        handleConfirm={disable2FA}
      />
      <ProductActionsModal
        modalOpen={confirmModalRemoveProduct}
        onCloseClick={toggleModalRemoveProduct}
        message={`Are you sure you want to remove product from customer ${customerInfo.name}?`}
        handleConfirm={removeProduct}
        formType="remove-form"
      />
      <ProductActionsModal
        modalOpen={confirmModalAddProduct}
        onCloseClick={toggleModalAddProduct}
        message={`Are you sure you want to add product to customer ${customerInfo.name}?`}
        handleConfirm={addProduct}
        formType="add-form"
      />
    </>
  );
};

CustomerProfileCard.propTypes = {
  customerInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array,
  ])).isRequired,
  showMailButton: PropTypes.bool,
  handleDeleteCustomer: PropTypes.func.isRequired,
  handleDeactivateCustomer: PropTypes.func.isRequired,
  handleSendActivationLink: PropTypes.func.isRequired,
  handleDisable2FA: PropTypes.func.isRequired,
  handleAddProduct: PropTypes.func.isRequired,
  handleRemoveProduct: PropTypes.func.isRequired,
  user: UserProps.isRequired,
};

CustomerProfileCard.defaultProps = {
  showMailButton: false,
};

export default CustomerProfileCard;
