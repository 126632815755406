import PropTypes from 'prop-types';
import React from 'react';

const DropdownList = ({
  title, subtitle, labelText, handleClick, currentValue,
}) => {
  const valueOptions = [];

  if (labelText.includes('Years')) {
    for (let i = 1; i < 6; i += 1) { valueOptions.push(i.toString()); }
  } else { for (let i = -5; i < 11; i += 1) { valueOptions.push(i.toString()); } }

  return (
    <div className="schedule-view">
      <div className="schedule-description">
        <h3>{title}</h3>
        <h4 className="subhead">{subtitle}</h4>
      </div>
      <div className="schedule-row field-center">
        <div className="schedule-fields">
          <span className="schedule-label">{labelText}</span>
          <select onChange={value => handleClick(value)} defaultValue={currentValue}>
            {valueOptions.map(value => <option value={value} key={value}>{value}</option>)}
          </select>
        </div>
      </div>
    </div>
  );
};

DropdownList.propTypes = {
  labelText: PropTypes.string,
  currentValue: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DropdownList.defaultProps = {
  labelText: 'Turn on:',
  currentValue: '0',
  title: '',
  subtitle: '',
};

export default DropdownList;
