import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../shared/img/closeIcon.svg';
import AddProductToCustomerForm from './AddProductToCustomerForm';
import RemoveProductFromCustomerForm from './RemoveProductFromCustomerForm';

const ProductModal = ({
  modalOpen, onCloseClick, handleConfirm, message, formType,
}) => {
  const handleClosing = () => {
    onCloseClick(false);
  };

  return (
    <Modal isOpen={modalOpen} className="custom__modal-container confirm-modal">
      <div className="custom__modal-header">
        <button
          className="close-btn"
          type="button"
          onClick={() => handleClosing()}
        > <CloseIcon alt="close" />
        </button>
      </div>
      <div className="modal__body">
        <h3 className=" modal__title">Confirm action</h3>
        <p>{message}</p>
        {formType === 'add-form'
          ? <AddProductToCustomerForm handleConfirm={handleConfirm} formType={formType} />
          : <RemoveProductFromCustomerForm handleConfirm={handleConfirm} formType={formType} />
          }
      </div>
    </Modal>
  );
};
ProductModal.propTypes = {
  message: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
};

ProductModal.defaultProps = {
  message: 'Are you sure?',
};

export default ProductModal;
